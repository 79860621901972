import { Landing_Video } from 'assets';
import { Event1 } from 'assets';
import { Event2 } from 'assets';
import React from 'react'
import TicketCard from './TicketCard';

const TicketTab = () => {
    const Arr2 = [
        {
            ind: 1,
            asset:
                Event1,
            assetType: "img",
            title: "Ultimate survival game",
            prize: '6',
            desc1: 'Like Phoenixs favorite Hider, Zee, youre an essential part of Nixs success.',
            desc2: 'Your Pledge will grant you several digital rewards in addition to an official backer sticker.',
            items: [
                { title: 'The Nix Series Kickstarter Backer Sticker', qty: '2', img: "https://ksr-ugc.imgix.net/assets/041/518/534/8d24edd070b5a3cff7fb40fe1d052c84_original.png?ixlib=rb-4.1.0&q=80&blur=false&w=600&fit=true&v=1688585761&gif-q=50&s=e8478173b22097efbf3938d2cb64d405" },
                { title: 'The Nix Series: Digital Art (Desktop & Mobile Wallpaper)', qty: '5', img: "https://ksr-ugc.imgix.net/assets/041/518/569/6567b6ad2c533cbfa7ee711bd8d75a4e_original.png?ixlib=rb-4.1.0&q=80&blur=false&w=600&fit=true&v=1688585937&gif-q=50&s=dbe3fddea6490cd62ddf4e702d861583" },
                { title: 'The Nix Series: The Documentary Access', qty: '1', },
                { title: 'The Nix Series Kickstarter: Zoom Afterparty', qty: '3', },
            ],
            addsOn: [
                { title: 'The Nix Series: Collectible Coin', prize: '10', img: 'https://ksr-ugc.imgix.net/assets/041/607/201/826e519f6dfaf64f666a12d69b7d3222_original.jpg?ixlib=rb-4.1.0&q=80&blur=false&w=600&fit=true&v=1689266906&gif-q=50&s=1af8f0d134cffc1c565def6699f443f9', desc: 'This double sided, 3D, Multicolor collectable coin is the perfect way to commemorate your participation in this collaborative event AND your love for the Nix series. One side features a majestic phoenix, and the other showcases Nixs Ladies, Dinah & Eleanor.' },
                { title: 'The Nix Series: Firestorm Tumbler', prize: '13', img: 'https://ksr-ugc.imgix.net/assets/041/592/726/b52b1164df748a90afd5f748ac4661b4_original.png?ixlib=rb-4.1.0&q=80&blur=false&w=600&fit=true&v=1689169995&gif-q=50&s=a5052913c05c3ba464e65e3bfef84550', desc: 'Call your own fire with this glass tumbler that features a firestorm of glitter between the double walls.' },
                { title: 'Mini Box', prize: '5', img: 'https://ksr-ugc.imgix.net/assets/041/696/676/e558f8c4f61226c3fb911cdfab618b56_original.jpg?ixlib=rb-4.1.0&q=80&blur=false&w=600&fit=true&v=1689973848&gif-q=50&s=e0c5de95059ce6d7a4213ab42c416977', desc: 'Want a swag box, but in a pint sized package? This Mini Box includes 6 of the items from the Premium Fan Box (aka swag box). There will be nothing in this box that is not in the full sized box. *IMPORTANT* Box upgrades from stretch goals do NOT apply to this mini box.' },
                { title: 'The Nix Series: Collectible Coin', prize: '10', img: 'https://ksr-ugc.imgix.net/assets/041/607/201/826e519f6dfaf64f666a12d69b7d3222_original.jpg?ixlib=rb-4.1.0&q=80&blur=false&w=600&fit=true&v=1689266906&gif-q=50&s=1af8f0d134cffc1c565def6699f443f9', desc: 'This double sided, 3D, Multicolor collectable coin is the perfect way to commemorate your participation in this collaborative event AND your love for the Nix series. One side features a majestic phoenix, and the other showcases Nixs Ladies, Dinah & Eleanor.' },
                { title: 'The Nix Series: Firestorm Tumbler', prize: '13', img: 'https://ksr-ugc.imgix.net/assets/041/592/726/b52b1164df748a90afd5f748ac4661b4_original.png?ixlib=rb-4.1.0&q=80&blur=false&w=600&fit=true&v=1689169995&gif-q=50&s=a5052913c05c3ba464e65e3bfef84550', desc: 'Call your own fire with this glass tumbler that features a firestorm of glitter between the double walls.' },
                { title: 'Mini Box', prize: '5', img: 'https://ksr-ugc.imgix.net/assets/041/696/676/e558f8c4f61226c3fb911cdfab618b56_original.jpg?ixlib=rb-4.1.0&q=80&blur=false&w=600&fit=true&v=1689973848&gif-q=50&s=e0c5de95059ce6d7a4213ab42c416977', desc: 'Want a swag box, but in a pint sized package? This Mini Box includes 6 of the items from the Premium Fan Box (aka swag box). There will be nothing in this box that is not in the full sized box. *IMPORTANT* Box upgrades from stretch goals do NOT apply to this mini box.' },
                { title: 'The Nix Series: Collectible Coin', prize: '10', img: 'https://ksr-ugc.imgix.net/assets/041/607/201/826e519f6dfaf64f666a12d69b7d3222_original.jpg?ixlib=rb-4.1.0&q=80&blur=false&w=600&fit=true&v=1689266906&gif-q=50&s=1af8f0d134cffc1c565def6699f443f9', desc: 'This double sided, 3D, Multicolor collectable coin is the perfect way to commemorate your participation in this collaborative event AND your love for the Nix series. One side features a majestic phoenix, and the other showcases Nixs Ladies, Dinah & Eleanor.' },
                { title: 'The Nix Series: Firestorm Tumbler', prize: '13', img: 'https://ksr-ugc.imgix.net/assets/041/592/726/b52b1164df748a90afd5f748ac4661b4_original.png?ixlib=rb-4.1.0&q=80&blur=false&w=600&fit=true&v=1689169995&gif-q=50&s=a5052913c05c3ba464e65e3bfef84550', desc: 'Call your own fire with this glass tumbler that features a firestorm of glitter between the double walls.' },
                { title: 'Mini Box', prize: '5', img: 'https://ksr-ugc.imgix.net/assets/041/696/676/e558f8c4f61226c3fb911cdfab618b56_original.jpg?ixlib=rb-4.1.0&q=80&blur=false&w=600&fit=true&v=1689973848&gif-q=50&s=e0c5de95059ce6d7a4213ab42c416977', desc: 'Want a swag box, but in a pint sized package? This Mini Box includes 6 of the items from the Premium Fan Box (aka swag box). There will be nothing in this box that is not in the full sized box. *IMPORTANT* Box upgrades from stretch goals do NOT apply to this mini box.' },

            ]
        },
        {
            ind: 2,
            asset:
                Event1,
            assetType: "img",
            title: "Ultimate survival game",
            prize: '6',
            desc1: 'Like Phoenixs favorite Hider, Zee, youre an essential part of Nixs success.',
            desc2: 'Your Pledge will grant you several digital rewards in addition to an official backer sticker.',
            items: [
                { title: 'The Nix Series Kickstarter Backer Sticker', qty: '2', img: "https://ksr-ugc.imgix.net/assets/041/518/534/8d24edd070b5a3cff7fb40fe1d052c84_original.png?ixlib=rb-4.1.0&q=80&blur=false&w=600&fit=true&v=1688585761&gif-q=50&s=e8478173b22097efbf3938d2cb64d405" },
                { title: 'The Nix Series: Digital Art (Desktop & Mobile Wallpaper)', qty: '5', img: "https://ksr-ugc.imgix.net/assets/041/518/569/6567b6ad2c533cbfa7ee711bd8d75a4e_original.png?ixlib=rb-4.1.0&q=80&blur=false&w=600&fit=true&v=1688585937&gif-q=50&s=dbe3fddea6490cd62ddf4e702d861583" },
                { title: 'The Nix Series: The Documentary Access', qty: '1', },
                { title: 'The Nix Series Kickstarter: Zoom Afterparty', qty: '3', },
            ],
            addsOn: [
                { title: 'The Nix Series: Collectible Coin', prize: '10', img: 'https://ksr-ugc.imgix.net/assets/041/607/201/826e519f6dfaf64f666a12d69b7d3222_original.jpg?ixlib=rb-4.1.0&q=80&blur=false&w=600&fit=true&v=1689266906&gif-q=50&s=1af8f0d134cffc1c565def6699f443f9', desc: 'This double sided, 3D, Multicolor collectable coin is the perfect way to commemorate your participation in this collaborative event AND your love for the Nix series. One side features a majestic phoenix, and the other showcases Nixs Ladies, Dinah & Eleanor.' },
                { title: 'The Nix Series: Firestorm Tumbler', prize: '13', img: 'https://ksr-ugc.imgix.net/assets/041/592/726/b52b1164df748a90afd5f748ac4661b4_original.png?ixlib=rb-4.1.0&q=80&blur=false&w=600&fit=true&v=1689169995&gif-q=50&s=a5052913c05c3ba464e65e3bfef84550', desc: 'Call your own fire with this glass tumbler that features a firestorm of glitter between the double walls.' },
                { title: 'Mini Box', prize: '5', img: 'https://ksr-ugc.imgix.net/assets/041/696/676/e558f8c4f61226c3fb911cdfab618b56_original.jpg?ixlib=rb-4.1.0&q=80&blur=false&w=600&fit=true&v=1689973848&gif-q=50&s=e0c5de95059ce6d7a4213ab42c416977', desc: 'Want a swag box, but in a pint sized package? This Mini Box includes 6 of the items from the Premium Fan Box (aka swag box). There will be nothing in this box that is not in the full sized box. *IMPORTANT* Box upgrades from stretch goals do NOT apply to this mini box.' },
                { title: 'The Nix Series: Collectible Coin', prize: '10', img: 'https://ksr-ugc.imgix.net/assets/041/607/201/826e519f6dfaf64f666a12d69b7d3222_original.jpg?ixlib=rb-4.1.0&q=80&blur=false&w=600&fit=true&v=1689266906&gif-q=50&s=1af8f0d134cffc1c565def6699f443f9', desc: 'This double sided, 3D, Multicolor collectable coin is the perfect way to commemorate your participation in this collaborative event AND your love for the Nix series. One side features a majestic phoenix, and the other showcases Nixs Ladies, Dinah & Eleanor.' },
                { title: 'The Nix Series: Firestorm Tumbler', prize: '13', img: 'https://ksr-ugc.imgix.net/assets/041/592/726/b52b1164df748a90afd5f748ac4661b4_original.png?ixlib=rb-4.1.0&q=80&blur=false&w=600&fit=true&v=1689169995&gif-q=50&s=a5052913c05c3ba464e65e3bfef84550', desc: 'Call your own fire with this glass tumbler that features a firestorm of glitter between the double walls.' },
                { title: 'Mini Box', prize: '5', img: 'https://ksr-ugc.imgix.net/assets/041/696/676/e558f8c4f61226c3fb911cdfab618b56_original.jpg?ixlib=rb-4.1.0&q=80&blur=false&w=600&fit=true&v=1689973848&gif-q=50&s=e0c5de95059ce6d7a4213ab42c416977', desc: 'Want a swag box, but in a pint sized package? This Mini Box includes 6 of the items from the Premium Fan Box (aka swag box). There will be nothing in this box that is not in the full sized box. *IMPORTANT* Box upgrades from stretch goals do NOT apply to this mini box.' },
                { title: 'The Nix Series: Collectible Coin', prize: '10', img: 'https://ksr-ugc.imgix.net/assets/041/607/201/826e519f6dfaf64f666a12d69b7d3222_original.jpg?ixlib=rb-4.1.0&q=80&blur=false&w=600&fit=true&v=1689266906&gif-q=50&s=1af8f0d134cffc1c565def6699f443f9', desc: 'This double sided, 3D, Multicolor collectable coin is the perfect way to commemorate your participation in this collaborative event AND your love for the Nix series. One side features a majestic phoenix, and the other showcases Nixs Ladies, Dinah & Eleanor.' },
                { title: 'The Nix Series: Firestorm Tumbler', prize: '13', img: 'https://ksr-ugc.imgix.net/assets/041/592/726/b52b1164df748a90afd5f748ac4661b4_original.png?ixlib=rb-4.1.0&q=80&blur=false&w=600&fit=true&v=1689169995&gif-q=50&s=a5052913c05c3ba464e65e3bfef84550', desc: 'Call your own fire with this glass tumbler that features a firestorm of glitter between the double walls.' },
                { title: 'Mini Box', prize: '5', img: 'https://ksr-ugc.imgix.net/assets/041/696/676/e558f8c4f61226c3fb911cdfab618b56_original.jpg?ixlib=rb-4.1.0&q=80&blur=false&w=600&fit=true&v=1689973848&gif-q=50&s=e0c5de95059ce6d7a4213ab42c416977', desc: 'Want a swag box, but in a pint sized package? This Mini Box includes 6 of the items from the Premium Fan Box (aka swag box). There will be nothing in this box that is not in the full sized box. *IMPORTANT* Box upgrades from stretch goals do NOT apply to this mini box.' },

            ]
        },
        {
            ind: 3,
            asset:
                Event1,
            assetType: "img",
            title: "Ultimate survival game",
            prize: '6',
            desc1: 'Like Phoenixs favorite Hider, Zee, youre an essential part of Nixs success.',
            desc2: 'Your Pledge will grant you several digital rewards in addition to an official backer sticker.',
            items: [
                { title: 'The Nix Series Kickstarter Backer Sticker', qty: '2', img: "https://ksr-ugc.imgix.net/assets/041/518/534/8d24edd070b5a3cff7fb40fe1d052c84_original.png?ixlib=rb-4.1.0&q=80&blur=false&w=600&fit=true&v=1688585761&gif-q=50&s=e8478173b22097efbf3938d2cb64d405" },
                { title: 'The Nix Series: Digital Art (Desktop & Mobile Wallpaper)', qty: '5', img: "https://ksr-ugc.imgix.net/assets/041/518/569/6567b6ad2c533cbfa7ee711bd8d75a4e_original.png?ixlib=rb-4.1.0&q=80&blur=false&w=600&fit=true&v=1688585937&gif-q=50&s=dbe3fddea6490cd62ddf4e702d861583" },
                { title: 'The Nix Series: The Documentary Access', qty: '1', },
                { title: 'The Nix Series Kickstarter: Zoom Afterparty', qty: '3', },
            ],
            addsOn: [
                { title: 'The Nix Series: Collectible Coin', prize: '10', img: 'https://ksr-ugc.imgix.net/assets/041/607/201/826e519f6dfaf64f666a12d69b7d3222_original.jpg?ixlib=rb-4.1.0&q=80&blur=false&w=600&fit=true&v=1689266906&gif-q=50&s=1af8f0d134cffc1c565def6699f443f9', desc: 'This double sided, 3D, Multicolor collectable coin is the perfect way to commemorate your participation in this collaborative event AND your love for the Nix series. One side features a majestic phoenix, and the other showcases Nixs Ladies, Dinah & Eleanor.' },
                { title: 'The Nix Series: Firestorm Tumbler', prize: '13', img: 'https://ksr-ugc.imgix.net/assets/041/592/726/b52b1164df748a90afd5f748ac4661b4_original.png?ixlib=rb-4.1.0&q=80&blur=false&w=600&fit=true&v=1689169995&gif-q=50&s=a5052913c05c3ba464e65e3bfef84550', desc: 'Call your own fire with this glass tumbler that features a firestorm of glitter between the double walls.' },
                { title: 'Mini Box', prize: '5', img: 'https://ksr-ugc.imgix.net/assets/041/696/676/e558f8c4f61226c3fb911cdfab618b56_original.jpg?ixlib=rb-4.1.0&q=80&blur=false&w=600&fit=true&v=1689973848&gif-q=50&s=e0c5de95059ce6d7a4213ab42c416977', desc: 'Want a swag box, but in a pint sized package? This Mini Box includes 6 of the items from the Premium Fan Box (aka swag box). There will be nothing in this box that is not in the full sized box. *IMPORTANT* Box upgrades from stretch goals do NOT apply to this mini box.' },
                { title: 'The Nix Series: Collectible Coin', prize: '10', img: 'https://ksr-ugc.imgix.net/assets/041/607/201/826e519f6dfaf64f666a12d69b7d3222_original.jpg?ixlib=rb-4.1.0&q=80&blur=false&w=600&fit=true&v=1689266906&gif-q=50&s=1af8f0d134cffc1c565def6699f443f9', desc: 'This double sided, 3D, Multicolor collectable coin is the perfect way to commemorate your participation in this collaborative event AND your love for the Nix series. One side features a majestic phoenix, and the other showcases Nixs Ladies, Dinah & Eleanor.' },
                { title: 'The Nix Series: Firestorm Tumbler', prize: '13', img: 'https://ksr-ugc.imgix.net/assets/041/592/726/b52b1164df748a90afd5f748ac4661b4_original.png?ixlib=rb-4.1.0&q=80&blur=false&w=600&fit=true&v=1689169995&gif-q=50&s=a5052913c05c3ba464e65e3bfef84550', desc: 'Call your own fire with this glass tumbler that features a firestorm of glitter between the double walls.' },
                { title: 'Mini Box', prize: '5', img: 'https://ksr-ugc.imgix.net/assets/041/696/676/e558f8c4f61226c3fb911cdfab618b56_original.jpg?ixlib=rb-4.1.0&q=80&blur=false&w=600&fit=true&v=1689973848&gif-q=50&s=e0c5de95059ce6d7a4213ab42c416977', desc: 'Want a swag box, but in a pint sized package? This Mini Box includes 6 of the items from the Premium Fan Box (aka swag box). There will be nothing in this box that is not in the full sized box. *IMPORTANT* Box upgrades from stretch goals do NOT apply to this mini box.' },
                { title: 'The Nix Series: Collectible Coin', prize: '10', img: 'https://ksr-ugc.imgix.net/assets/041/607/201/826e519f6dfaf64f666a12d69b7d3222_original.jpg?ixlib=rb-4.1.0&q=80&blur=false&w=600&fit=true&v=1689266906&gif-q=50&s=1af8f0d134cffc1c565def6699f443f9', desc: 'This double sided, 3D, Multicolor collectable coin is the perfect way to commemorate your participation in this collaborative event AND your love for the Nix series. One side features a majestic phoenix, and the other showcases Nixs Ladies, Dinah & Eleanor.' },
                { title: 'The Nix Series: Firestorm Tumbler', prize: '13', img: 'https://ksr-ugc.imgix.net/assets/041/592/726/b52b1164df748a90afd5f748ac4661b4_original.png?ixlib=rb-4.1.0&q=80&blur=false&w=600&fit=true&v=1689169995&gif-q=50&s=a5052913c05c3ba464e65e3bfef84550', desc: 'Call your own fire with this glass tumbler that features a firestorm of glitter between the double walls.' },
                { title: 'Mini Box', prize: '5', img: 'https://ksr-ugc.imgix.net/assets/041/696/676/e558f8c4f61226c3fb911cdfab618b56_original.jpg?ixlib=rb-4.1.0&q=80&blur=false&w=600&fit=true&v=1689973848&gif-q=50&s=e0c5de95059ce6d7a4213ab42c416977', desc: 'Want a swag box, but in a pint sized package? This Mini Box includes 6 of the items from the Premium Fan Box (aka swag box). There will be nothing in this box that is not in the full sized box. *IMPORTANT* Box upgrades from stretch goals do NOT apply to this mini box.' },

            ]
        },
        {
            ind: 4,
            asset:
                Event1,
            assetType: "img",
            title: "Ultimate survival game",
            prize: '6',
            desc1: 'Like Phoenixs favorite Hider, Zee, youre an essential part of Nixs success.',
            desc2: 'Your Pledge will grant you several digital rewards in addition to an official backer sticker.',
            items: [
                { title: 'The Nix Series Kickstarter Backer Sticker', qty: '2', img: "https://ksr-ugc.imgix.net/assets/041/518/534/8d24edd070b5a3cff7fb40fe1d052c84_original.png?ixlib=rb-4.1.0&q=80&blur=false&w=600&fit=true&v=1688585761&gif-q=50&s=e8478173b22097efbf3938d2cb64d405" },
                { title: 'The Nix Series: Digital Art (Desktop & Mobile Wallpaper)', qty: '5', img: "https://ksr-ugc.imgix.net/assets/041/518/569/6567b6ad2c533cbfa7ee711bd8d75a4e_original.png?ixlib=rb-4.1.0&q=80&blur=false&w=600&fit=true&v=1688585937&gif-q=50&s=dbe3fddea6490cd62ddf4e702d861583" },
                { title: 'The Nix Series: The Documentary Access', qty: '1', },
                { title: 'The Nix Series Kickstarter: Zoom Afterparty', qty: '3', },
            ],
            addsOn: [
                { title: 'The Nix Series: Collectible Coin', prize: '10', img: 'https://ksr-ugc.imgix.net/assets/041/607/201/826e519f6dfaf64f666a12d69b7d3222_original.jpg?ixlib=rb-4.1.0&q=80&blur=false&w=600&fit=true&v=1689266906&gif-q=50&s=1af8f0d134cffc1c565def6699f443f9', desc: 'This double sided, 3D, Multicolor collectable coin is the perfect way to commemorate your participation in this collaborative event AND your love for the Nix series. One side features a majestic phoenix, and the other showcases Nixs Ladies, Dinah & Eleanor.' },
                { title: 'The Nix Series: Firestorm Tumbler', prize: '13', img: 'https://ksr-ugc.imgix.net/assets/041/592/726/b52b1164df748a90afd5f748ac4661b4_original.png?ixlib=rb-4.1.0&q=80&blur=false&w=600&fit=true&v=1689169995&gif-q=50&s=a5052913c05c3ba464e65e3bfef84550', desc: 'Call your own fire with this glass tumbler that features a firestorm of glitter between the double walls.' },
                { title: 'Mini Box', prize: '5', img: 'https://ksr-ugc.imgix.net/assets/041/696/676/e558f8c4f61226c3fb911cdfab618b56_original.jpg?ixlib=rb-4.1.0&q=80&blur=false&w=600&fit=true&v=1689973848&gif-q=50&s=e0c5de95059ce6d7a4213ab42c416977', desc: 'Want a swag box, but in a pint sized package? This Mini Box includes 6 of the items from the Premium Fan Box (aka swag box). There will be nothing in this box that is not in the full sized box. *IMPORTANT* Box upgrades from stretch goals do NOT apply to this mini box.' },
                { title: 'The Nix Series: Collectible Coin', prize: '10', img: 'https://ksr-ugc.imgix.net/assets/041/607/201/826e519f6dfaf64f666a12d69b7d3222_original.jpg?ixlib=rb-4.1.0&q=80&blur=false&w=600&fit=true&v=1689266906&gif-q=50&s=1af8f0d134cffc1c565def6699f443f9', desc: 'This double sided, 3D, Multicolor collectable coin is the perfect way to commemorate your participation in this collaborative event AND your love for the Nix series. One side features a majestic phoenix, and the other showcases Nixs Ladies, Dinah & Eleanor.' },
                { title: 'The Nix Series: Firestorm Tumbler', prize: '13', img: 'https://ksr-ugc.imgix.net/assets/041/592/726/b52b1164df748a90afd5f748ac4661b4_original.png?ixlib=rb-4.1.0&q=80&blur=false&w=600&fit=true&v=1689169995&gif-q=50&s=a5052913c05c3ba464e65e3bfef84550', desc: 'Call your own fire with this glass tumbler that features a firestorm of glitter between the double walls.' },
                { title: 'Mini Box', prize: '5', img: 'https://ksr-ugc.imgix.net/assets/041/696/676/e558f8c4f61226c3fb911cdfab618b56_original.jpg?ixlib=rb-4.1.0&q=80&blur=false&w=600&fit=true&v=1689973848&gif-q=50&s=e0c5de95059ce6d7a4213ab42c416977', desc: 'Want a swag box, but in a pint sized package? This Mini Box includes 6 of the items from the Premium Fan Box (aka swag box). There will be nothing in this box that is not in the full sized box. *IMPORTANT* Box upgrades from stretch goals do NOT apply to this mini box.' },
                { title: 'The Nix Series: Collectible Coin', prize: '10', img: 'https://ksr-ugc.imgix.net/assets/041/607/201/826e519f6dfaf64f666a12d69b7d3222_original.jpg?ixlib=rb-4.1.0&q=80&blur=false&w=600&fit=true&v=1689266906&gif-q=50&s=1af8f0d134cffc1c565def6699f443f9', desc: 'This double sided, 3D, Multicolor collectable coin is the perfect way to commemorate your participation in this collaborative event AND your love for the Nix series. One side features a majestic phoenix, and the other showcases Nixs Ladies, Dinah & Eleanor.' },
                { title: 'The Nix Series: Firestorm Tumbler', prize: '13', img: 'https://ksr-ugc.imgix.net/assets/041/592/726/b52b1164df748a90afd5f748ac4661b4_original.png?ixlib=rb-4.1.0&q=80&blur=false&w=600&fit=true&v=1689169995&gif-q=50&s=a5052913c05c3ba464e65e3bfef84550', desc: 'Call your own fire with this glass tumbler that features a firestorm of glitter between the double walls.' },
                { title: 'Mini Box', prize: '5', img: 'https://ksr-ugc.imgix.net/assets/041/696/676/e558f8c4f61226c3fb911cdfab618b56_original.jpg?ixlib=rb-4.1.0&q=80&blur=false&w=600&fit=true&v=1689973848&gif-q=50&s=e0c5de95059ce6d7a4213ab42c416977', desc: 'Want a swag box, but in a pint sized package? This Mini Box includes 6 of the items from the Premium Fan Box (aka swag box). There will be nothing in this box that is not in the full sized box. *IMPORTANT* Box upgrades from stretch goals do NOT apply to this mini box.' },

            ]
        },
        {
            ind: 5,
            asset:
                Event1,
            assetType: "img",
            title: "Ultimate survival game",
            prize: '6',
            desc1: 'Like Phoenixs favorite Hider, Zee, youre an essential part of Nixs success.',
            desc2: 'Your Pledge will grant you several digital rewards in addition to an official backer sticker.',
            items: [
                { title: 'The Nix Series Kickstarter Backer Sticker', qty: '2', img: "https://ksr-ugc.imgix.net/assets/041/518/534/8d24edd070b5a3cff7fb40fe1d052c84_original.png?ixlib=rb-4.1.0&q=80&blur=false&w=600&fit=true&v=1688585761&gif-q=50&s=e8478173b22097efbf3938d2cb64d405" },
                { title: 'The Nix Series: Digital Art (Desktop & Mobile Wallpaper)', qty: '5', img: "https://ksr-ugc.imgix.net/assets/041/518/569/6567b6ad2c533cbfa7ee711bd8d75a4e_original.png?ixlib=rb-4.1.0&q=80&blur=false&w=600&fit=true&v=1688585937&gif-q=50&s=dbe3fddea6490cd62ddf4e702d861583" },
                { title: 'The Nix Series: The Documentary Access', qty: '1', },
                { title: 'The Nix Series Kickstarter: Zoom Afterparty', qty: '3', },
            ],
            addsOn: [
                { title: 'The Nix Series: Collectible Coin', prize: '10', img: 'https://ksr-ugc.imgix.net/assets/041/607/201/826e519f6dfaf64f666a12d69b7d3222_original.jpg?ixlib=rb-4.1.0&q=80&blur=false&w=600&fit=true&v=1689266906&gif-q=50&s=1af8f0d134cffc1c565def6699f443f9', desc: 'This double sided, 3D, Multicolor collectable coin is the perfect way to commemorate your participation in this collaborative event AND your love for the Nix series. One side features a majestic phoenix, and the other showcases Nixs Ladies, Dinah & Eleanor.' },
                { title: 'The Nix Series: Firestorm Tumbler', prize: '13', img: 'https://ksr-ugc.imgix.net/assets/041/592/726/b52b1164df748a90afd5f748ac4661b4_original.png?ixlib=rb-4.1.0&q=80&blur=false&w=600&fit=true&v=1689169995&gif-q=50&s=a5052913c05c3ba464e65e3bfef84550', desc: 'Call your own fire with this glass tumbler that features a firestorm of glitter between the double walls.' },
                { title: 'Mini Box', prize: '5', img: 'https://ksr-ugc.imgix.net/assets/041/696/676/e558f8c4f61226c3fb911cdfab618b56_original.jpg?ixlib=rb-4.1.0&q=80&blur=false&w=600&fit=true&v=1689973848&gif-q=50&s=e0c5de95059ce6d7a4213ab42c416977', desc: 'Want a swag box, but in a pint sized package? This Mini Box includes 6 of the items from the Premium Fan Box (aka swag box). There will be nothing in this box that is not in the full sized box. *IMPORTANT* Box upgrades from stretch goals do NOT apply to this mini box.' },
                { title: 'The Nix Series: Collectible Coin', prize: '10', img: 'https://ksr-ugc.imgix.net/assets/041/607/201/826e519f6dfaf64f666a12d69b7d3222_original.jpg?ixlib=rb-4.1.0&q=80&blur=false&w=600&fit=true&v=1689266906&gif-q=50&s=1af8f0d134cffc1c565def6699f443f9', desc: 'This double sided, 3D, Multicolor collectable coin is the perfect way to commemorate your participation in this collaborative event AND your love for the Nix series. One side features a majestic phoenix, and the other showcases Nixs Ladies, Dinah & Eleanor.' },
                { title: 'The Nix Series: Firestorm Tumbler', prize: '13', img: 'https://ksr-ugc.imgix.net/assets/041/592/726/b52b1164df748a90afd5f748ac4661b4_original.png?ixlib=rb-4.1.0&q=80&blur=false&w=600&fit=true&v=1689169995&gif-q=50&s=a5052913c05c3ba464e65e3bfef84550', desc: 'Call your own fire with this glass tumbler that features a firestorm of glitter between the double walls.' },
                { title: 'Mini Box', prize: '5', img: 'https://ksr-ugc.imgix.net/assets/041/696/676/e558f8c4f61226c3fb911cdfab618b56_original.jpg?ixlib=rb-4.1.0&q=80&blur=false&w=600&fit=true&v=1689973848&gif-q=50&s=e0c5de95059ce6d7a4213ab42c416977', desc: 'Want a swag box, but in a pint sized package? This Mini Box includes 6 of the items from the Premium Fan Box (aka swag box). There will be nothing in this box that is not in the full sized box. *IMPORTANT* Box upgrades from stretch goals do NOT apply to this mini box.' },
                { title: 'The Nix Series: Collectible Coin', prize: '10', img: 'https://ksr-ugc.imgix.net/assets/041/607/201/826e519f6dfaf64f666a12d69b7d3222_original.jpg?ixlib=rb-4.1.0&q=80&blur=false&w=600&fit=true&v=1689266906&gif-q=50&s=1af8f0d134cffc1c565def6699f443f9', desc: 'This double sided, 3D, Multicolor collectable coin is the perfect way to commemorate your participation in this collaborative event AND your love for the Nix series. One side features a majestic phoenix, and the other showcases Nixs Ladies, Dinah & Eleanor.' },
                { title: 'The Nix Series: Firestorm Tumbler', prize: '13', img: 'https://ksr-ugc.imgix.net/assets/041/592/726/b52b1164df748a90afd5f748ac4661b4_original.png?ixlib=rb-4.1.0&q=80&blur=false&w=600&fit=true&v=1689169995&gif-q=50&s=a5052913c05c3ba464e65e3bfef84550', desc: 'Call your own fire with this glass tumbler that features a firestorm of glitter between the double walls.' },
                { title: 'Mini Box', prize: '5', img: 'https://ksr-ugc.imgix.net/assets/041/696/676/e558f8c4f61226c3fb911cdfab618b56_original.jpg?ixlib=rb-4.1.0&q=80&blur=false&w=600&fit=true&v=1689973848&gif-q=50&s=e0c5de95059ce6d7a4213ab42c416977', desc: 'Want a swag box, but in a pint sized package? This Mini Box includes 6 of the items from the Premium Fan Box (aka swag box). There will be nothing in this box that is not in the full sized box. *IMPORTANT* Box upgrades from stretch goals do NOT apply to this mini box.' },

            ]
        },
        {
            ind: 6,
            asset:
                Event1,
            assetType: "img",
            title: "Ultimate survival game",
            prize: '6',
            desc1: 'Like Phoenixs favorite Hider, Zee, youre an essential part of Nixs success.',
            desc2: 'Your Pledge will grant you several digital rewards in addition to an official backer sticker.',
            items: [
                { title: 'The Nix Series Kickstarter Backer Sticker', qty: '2', img: "https://ksr-ugc.imgix.net/assets/041/518/534/8d24edd070b5a3cff7fb40fe1d052c84_original.png?ixlib=rb-4.1.0&q=80&blur=false&w=600&fit=true&v=1688585761&gif-q=50&s=e8478173b22097efbf3938d2cb64d405" },
                { title: 'The Nix Series: Digital Art (Desktop & Mobile Wallpaper)', qty: '5', img: "https://ksr-ugc.imgix.net/assets/041/518/569/6567b6ad2c533cbfa7ee711bd8d75a4e_original.png?ixlib=rb-4.1.0&q=80&blur=false&w=600&fit=true&v=1688585937&gif-q=50&s=dbe3fddea6490cd62ddf4e702d861583" },
                { title: 'The Nix Series: The Documentary Access', qty: '1', },
                { title: 'The Nix Series Kickstarter: Zoom Afterparty', qty: '3', },
            ],
            addsOn: [
                { title: 'The Nix Series: Collectible Coin', prize: '10', img: 'https://ksr-ugc.imgix.net/assets/041/607/201/826e519f6dfaf64f666a12d69b7d3222_original.jpg?ixlib=rb-4.1.0&q=80&blur=false&w=600&fit=true&v=1689266906&gif-q=50&s=1af8f0d134cffc1c565def6699f443f9', desc: 'This double sided, 3D, Multicolor collectable coin is the perfect way to commemorate your participation in this collaborative event AND your love for the Nix series. One side features a majestic phoenix, and the other showcases Nixs Ladies, Dinah & Eleanor.' },
                { title: 'The Nix Series: Firestorm Tumbler', prize: '13', img: 'https://ksr-ugc.imgix.net/assets/041/592/726/b52b1164df748a90afd5f748ac4661b4_original.png?ixlib=rb-4.1.0&q=80&blur=false&w=600&fit=true&v=1689169995&gif-q=50&s=a5052913c05c3ba464e65e3bfef84550', desc: 'Call your own fire with this glass tumbler that features a firestorm of glitter between the double walls.' },
                { title: 'Mini Box', prize: '5', img: 'https://ksr-ugc.imgix.net/assets/041/696/676/e558f8c4f61226c3fb911cdfab618b56_original.jpg?ixlib=rb-4.1.0&q=80&blur=false&w=600&fit=true&v=1689973848&gif-q=50&s=e0c5de95059ce6d7a4213ab42c416977', desc: 'Want a swag box, but in a pint sized package? This Mini Box includes 6 of the items from the Premium Fan Box (aka swag box). There will be nothing in this box that is not in the full sized box. *IMPORTANT* Box upgrades from stretch goals do NOT apply to this mini box.' },
                { title: 'The Nix Series: Collectible Coin', prize: '10', img: 'https://ksr-ugc.imgix.net/assets/041/607/201/826e519f6dfaf64f666a12d69b7d3222_original.jpg?ixlib=rb-4.1.0&q=80&blur=false&w=600&fit=true&v=1689266906&gif-q=50&s=1af8f0d134cffc1c565def6699f443f9', desc: 'This double sided, 3D, Multicolor collectable coin is the perfect way to commemorate your participation in this collaborative event AND your love for the Nix series. One side features a majestic phoenix, and the other showcases Nixs Ladies, Dinah & Eleanor.' },
                { title: 'The Nix Series: Firestorm Tumbler', prize: '13', img: 'https://ksr-ugc.imgix.net/assets/041/592/726/b52b1164df748a90afd5f748ac4661b4_original.png?ixlib=rb-4.1.0&q=80&blur=false&w=600&fit=true&v=1689169995&gif-q=50&s=a5052913c05c3ba464e65e3bfef84550', desc: 'Call your own fire with this glass tumbler that features a firestorm of glitter between the double walls.' },
                { title: 'Mini Box', prize: '5', img: 'https://ksr-ugc.imgix.net/assets/041/696/676/e558f8c4f61226c3fb911cdfab618b56_original.jpg?ixlib=rb-4.1.0&q=80&blur=false&w=600&fit=true&v=1689973848&gif-q=50&s=e0c5de95059ce6d7a4213ab42c416977', desc: 'Want a swag box, but in a pint sized package? This Mini Box includes 6 of the items from the Premium Fan Box (aka swag box). There will be nothing in this box that is not in the full sized box. *IMPORTANT* Box upgrades from stretch goals do NOT apply to this mini box.' },
                { title: 'The Nix Series: Collectible Coin', prize: '10', img: 'https://ksr-ugc.imgix.net/assets/041/607/201/826e519f6dfaf64f666a12d69b7d3222_original.jpg?ixlib=rb-4.1.0&q=80&blur=false&w=600&fit=true&v=1689266906&gif-q=50&s=1af8f0d134cffc1c565def6699f443f9', desc: 'This double sided, 3D, Multicolor collectable coin is the perfect way to commemorate your participation in this collaborative event AND your love for the Nix series. One side features a majestic phoenix, and the other showcases Nixs Ladies, Dinah & Eleanor.' },
                { title: 'The Nix Series: Firestorm Tumbler', prize: '13', img: 'https://ksr-ugc.imgix.net/assets/041/592/726/b52b1164df748a90afd5f748ac4661b4_original.png?ixlib=rb-4.1.0&q=80&blur=false&w=600&fit=true&v=1689169995&gif-q=50&s=a5052913c05c3ba464e65e3bfef84550', desc: 'Call your own fire with this glass tumbler that features a firestorm of glitter between the double walls.' },
                { title: 'Mini Box', prize: '5', img: 'https://ksr-ugc.imgix.net/assets/041/696/676/e558f8c4f61226c3fb911cdfab618b56_original.jpg?ixlib=rb-4.1.0&q=80&blur=false&w=600&fit=true&v=1689973848&gif-q=50&s=e0c5de95059ce6d7a4213ab42c416977', desc: 'Want a swag box, but in a pint sized package? This Mini Box includes 6 of the items from the Premium Fan Box (aka swag box). There will be nothing in this box that is not in the full sized box. *IMPORTANT* Box upgrades from stretch goals do NOT apply to this mini box.' },

            ]
        },

    ];
    return (
        <div>

            <div
                className={`flex items-center flex-col`}
                style={{ overflowY: 'scroll', height: '150vh' }}

            >
                {Arr2.map((data, index) => (
                    <>
                        <div
                            className={`!z-5 grid grid-cols-5 dark:text-white dark:shadow-none border-bottom`} style={{ borderColor: 'black' }}
                            key={index}
                        >
                            <div className="col-span-2 h-[fit-content] ">
                                {/* {data.assetType === 'img' ?
                                <img src={data.asset} className="w-[100%] rounded-xl" alt="" />
                                :
                                <video
                                    className='w-[100%] bg-navy-700 rounded-xl'
                                    src={data.asset} autoPlay muted loop>
                                </video>} */}
            <TicketCard />

                                {/* <div className="w-[100%]  p-[1.2rem]">
                                    <div className='flex items-center justify-between'>
                                        <h2 className="w-50 text-[25px]  dark:text-white  text-navy-700 ">
                                            {data.title}
                                        </h2>
                                        <h2 className="w-50 text-[25px]  dark:text-white  text-navy-700 ">
                                            ${data.prize}
                                        </h2>
                                    </div>
                                    <div>
                                        <button
                                            className="linear w-[100%] float-right my-[0.5rem] rounded-[10px] bg-brand-900 px-2 py-2 text-[20px] text-base font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90"
                                        >
                                            Buy in ${data.prize}
                                        </button>
                                    </div>
                                </div> */}
                            </div>
            {/* <TicketCard /> */}

                            <div className='col-span-3 px-[2.5rem] flex flex-col' style={{ overflowY: 'scroll', height: "88vh" }}>
                                <div>
                                    <p className='text-[18px]'>
                                        {data.desc1}
                                    </p>
                                    <br />
                                    <p className='text-[18px]'>
                                        {data.desc2}
                                    </p>
                                </div>


                                <div className='my-3'>
                                    <p className='font-semibold'>
                                        {data.items.length} items included
                                    </p>
                                </div>
                                {data.items ? data.items.map((value, index) => (<>
                                    <div className='border rounded border-2 my-3 px-4 py-2' style={{ borderColor: 'black' }} key={index}>
                                        <div className='flex items-center justify-between'>
                                            <div>
                                                <p>
                                                    {value.title}
                                                </p>
                                                <p>
                                                    Qunatity: {value.qty}
                                                </p>
                                            </div>
                                            {value.img &&
                                                <div>
                                                    <img src={value.img} alt="" className='w-[4rem] h-[4rem]' />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </>)) : null}
                                <div className='my-3'>
                                    <p className='font-semibold'>
                                        Optional add-ons
                                    </p>
                                </div>
                                {data.addsOn ? data.addsOn.map((value, index) => (<>
                                    <div className='border rounded border-2 my-3 px-4 py-2' style={{ borderColor: 'black' }} key={index}>
                                        <div className='flex items-center justify-between'>
                                            <div>
                                                <p>
                                                    {value.title}
                                                </p>
                                                <p>
                                                    +${value.prize}
                                                </p>
                                            </div>
                                            {value.img &&
                                                <div>
                                                    <img src={value.img} alt="" className='w-[6rem] h-[6rem]' />
                                                </div>
                                            }

                                        </div>
                                        <div>
                                            {value.desc}
                                        </div>
                                    </div>
                                </>)) : null}

                            </div>
                        </div>
                        <hr className='w-[95%] bg-navy-700 dark:bg-white my-5 h-[2px]' />
                    </>
                ))}
            </div>
        </div>
    )
}

export default TicketTab
