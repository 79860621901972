import React, { useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import CommunityPage from "views/Landing/SingleEvent/CommunityPage";

import AboutUsPage from "views/Landing/SingleEvent/AboutUsTab";
import { Merchandies } from "./Merchandies";
import { Event01 } from "./event";
import Membership from "./Membership";

const Landingcard1 = (props) => {

  const [tab, setTab] = useState("Events");
  const Tabs = [
    // { event: "Campaign" },
    { event: "Events" },
    { event: "Community" },
    { event: "Merchandies" },
    { event: "Membership" },
    { event: "About" },
  ];
  return (
    <>
      <div className=" dark:bg-navy-700 mx-[5.2rem]">
        <div className="">
          {/* <Tabs /> */}
          {/* <Card style={{marginTop:"2%",marginBottom:"2%", padding:"1%"}}> */}

          <div style={props.styles.top} className={ ` my-[5rem] flex items-center justify-start`} >
            <div style={props.styles.top3}  className="div_1 flex flex-cols-2 items-center justify-start md:flex-row">
              {Tabs.map((data, index) => (
                <h3
                  className={`${
                    data.event === tab
                    ? "border-b-2 text-brand-400 border-brand-400"
                    : "dark:text-white"
                } mx-5 cursor-pointer border-indigo-500 text-[1.5rem] `}
                  key={index}
                  onClick={() => setTab(data.event)}
                >
                  {data.event}
                </h3>
              ))}
             
            </div>
            <div className="mx-7" style={props.styles.top4}>
         
              </div>
          </div>
          {/* </Card> */}
          {tab === "Events" && (
            <div className={`mb-[40px] flex items-center`}>
              <Event01/>
            </div>
          )}
          {tab === "Merchandies" && <Merchandies />}
          {tab === "Community" && (
            <div className="min-h-screen">
              <CommunityPage />
            </div>
          )}
          {tab === "Membership" && (
              <Membership/>
              )}
              {tab === "About" && <AboutUsPage />}
        </div>
      </div>
    </>
  );
};

export default Landingcard1;
