import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import './main.css';
import img2 from "../../assets/img/landing/event1.jpg"
import img3 from "../../assets/img/landing/event2.jpg"
// import required modules
import { EffectCoverflow, Pagination, Autoplay } from 'swiper/modules';

export default function Slider01() {

  const arr1=[
    {
      "id":1,
      "img":img2

    },
    {
      "id":2,
      "img":img3

    },
    {
      "id":3,
      "img":img2

    },
    {
      "id":4,
      "img":img3

    },
    {
      "id":5,
      "img":img2

    },
    {
      "id":6,
      "img":img3

    },
    {
      "id":7,
      "img":img2

    },
  
    {
      "id":9,
      "img":img3

    },
    {
      "id":10,
      "img":img2

    },
    
    {
      "id":12,
      "img":img3

    },
    {
      "id":13,
      "img":img2

    },
  
    {
      "id":9,
      "img":img3

    },
    {
      "id":10,
      "img":img2

    },
    
    {
      "id":12,
      "img":img3

    },
    {
      "id":13,
      "img":img2

    },
    {
      "id":12,
      "img":img3

    },
    {
      "id":13,
      "img":img2

    },
  
    {
      "id":9,
      "img":img3

    },
    {
      "id":10,
      "img":img2

    },
    
    {
      "id":12,
      "img":img3

    },
    {
      "id":13,
      "img":img2

    },
    {
      "id":12,
      "img":img3

    },
    {
      "id":13,
      "img":img2

    },
  
    {
      "id":9,
      "img":img3

    },
    {
      "id":10,
      "img":img2

    },
    
    {
      "id":12,
      "img":img3

    },
    {
      "id":13,
      "img":img2

    },
    {
      "id":12,
      "img":img3

    },
    {
      "id":13,
      "img":img2

    },
  
    {
      "id":9,
      "img":img3

    },
    {
      "id":10,
      "img":img2

    },
    
    {
      "id":12,
      "img":img3

    },
    {
      "id":13,
      "img":img2

    },
    {
      "id":12,
      "img":img3

    },
    {
      "id":13,
      "img":img2

    },
  
    {
      "id":9,
      "img":img3

    },
    {
      "id":10,
      "img":img2

    },
    
    {
      "id":12,
      "img":img3

    },
    {
      "id":13,
      "img":img2

    },
    {
      "id":12,
      "img":img3

    },
    {
      "id":13,
      "img":img2

    },
  
    {
      "id":9,
      "img":img3

    },
    {
      "id":10,
      "img":img2

    },
    
    {
      "id":12,
      "img":img3

    },
    {
      "id":13,
      "img":img2

    },
    {
      "id":12,
      "img":img3

    },
    {
      "id":13,
      "img":img2

    },
  
    {
      "id":9,
      "img":img3

    },
    {
      "id":10,
      "img":img2

    },
    
    {
      "id":12,
      "img":img3

    },
    {
      "id":13,
      "img":img2

    },
    {
      "id":12,
      "img":img3

    },
    {
      "id":13,
      "img":img2

    },
  
    {
      "id":9,
      "img":img3

    },
    {
      "id":10,
      "img":img2

    },
    
    {
      "id":12,
      "img":img3

    },
    {
      "id":13,
      "img":img2

    },
  ]
  
  return (
    <div className='body01'>
      <Swiper 
        effect={'coverflow'}
         autoplay={{
          delay: 1700,
          disableOnInteraction: false,
        }}
        
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={'auto'}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        pagination={true}
        modules={[EffectCoverflow, Pagination, Autoplay]}
        className="mySwiper"
      >
        {arr1.map((data)=>(
        <SwiperSlide className='swiper-slide1'>
          {/* <img src={data.img} /> */}
          <div className='text-white' style={{textAlign:"center",padding:"15%"}}>
            <p className='' style={{borderBottom:"3px solid white",fontSize:"larger"}}><b>Lorem, ipsum dolor.</b></p><br />
<p className=''>Lorem ipsum dolor sit, amet consectetur adipisicing.</p>
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt, neque.</p><br />
          <button className='elementor-button-text'>Know More</button>
          </div>
        </SwiperSlide>
        ))}
      
      </Swiper>
    </div>
  );
}
