import React, { useState } from 'react'
import './main.css'
export const Faq = () => {
    const [toggle, setToggle] = useState(null);
    const arr = [
        {
            question: "What types of events do you organize?",
            ans: " We specialize in organizing a wide range of events, including corporate conferences, seminars, weddings, birthdays, trade shows, and cultural festivals. Our experienced team can tailor each event to suit your specific needs and preferences."
        },
        {
            question: "How do I get started with booking an event through your services?",
            ans: " Getting started is easy! Simply navigate to our Contact Us page and fill out the inquiry form with your event details and requirements. Our dedicated team will promptly get in touch with you to discuss your event goals and provide a personalized quote."
        },
        {
            question: "Can you assist with event planning and coordination?",
            ans: "Absolutely! Our comprehensive services cover every aspect of event planning and coordination. From selecting venues and designing invitations to managing logistics and on-site execution, we've got you covered to ensure a seamless and memorable event."
        },
        {
            "question": " What sets your event organization services apart from others?",
            "ans": " What makes us stand out is our commitment to creativity, attention to detail, and a personalized approach. We focus on understanding your vision and turning it into a remarkable reality. Our team's expertise, combined with a network of trusted vendors, ensures an exceptional event experience every time."
        },
    ]
    
    return (
        
        <div>
        <div className='faq012 dark:text-white' ><br />

        
            <div><p className='h01'>  FREQUENTLY ASKED QUESTIONS {`>>>`} </p></div><br />
            {arr.map((data, index) => (
                
                <div className="faq p-[1.5%] dark:border-white cursor-pointer" key={index} onClick={() => setToggle(index)}>
                    <h3 className='text-[20px]'>Q.{index + 1} {data.question}</h3>
                    {index === toggle &&
                        <p className='py-2 border-top'>Ans. {data.ans}</p>}
                </div>
            ))}
        </div>
            </div>
    )
}