import SignupModal from "components/modal/SignupModal";
import React, { useEffect, useState } from "react";
import "./Event.css";
import routes from "routes";
import LandingNavbar from "components/navbar/LandingNavbar";
import NFt2 from "assets/img/nfts/Nft2.png";
import NFt4 from "assets/img/nfts/Nft4.png";
import NFt3 from "assets/img/nfts/Nft3.png";
import NFt5 from "assets/img/nfts/Nft5.png";
import NFt6 from "assets/img/nfts/Nft6.png";
import Footer from "components/footer/Footer";
import TopHeader from "./TopComponent";
import Tabs from "./Tabs";

const SingleEvent = (props) => {

  const { ...rest } = props;
  const [open, setOpen] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);

  console.log(window.scrollY);

  useEffect(() => {
    function handleScroll() {
      setScrollTop(window.scrollY);
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const getActiveNavbar = (routes) => {

    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].secondary;
      }
    }
    return activeNavbar;
  };

  const tranding = [
    {
      img: NFt4,
      title: "Abstract  Colors",
      author: "Esthera Jackson",
      type: "Arts",
      location: "Jaipur",
      location_address: "Jaipur",
      start_date: "09/12/2023",
    },
    {
      img: NFt2,
      title: "ETH AI Brain",
      author: "Nick Wilson",
      type: "Music",
      location: "Delhi",
      location_address: "Delhi",
      start_date: "09/12/2023",
    },
    {
      img: NFt5,
      title: "Mesh Gradients",
      author: "Will Smith",
      type: "Sports",
      location: "Sikar",
      location_address: "Sikar",
      start_date: "09/12/2023",
    },
  ];

  const Recent = [
    {
      img: NFt4,
      title: "Abstract  Colors",
      author: "Esthera Jackson",
      type: "Arts",
      location: "Jaipur",
      location_address: "Jaipur",
      start_date: "09/12/2023",
    },
    {
      img: NFt5,
      title: "Abstract  Colors",
      author: "Esthera Jackson",
      type: "Arts",
      location: "Jaipur",
      location_address: "Jaipur",
      start_date: "09/12/2023",
    },
    {
      img: NFt2,
      title: "ETH AI Brain",
      author: "Nick Wilson",
      type: "Music",
      location: "Delhi",
      location_address: "Delhi",
      start_date: "09/12/2023",
    },
    {
      img: NFt6,
      title: "Mesh Gradients",
      author: "Will Smith",
      type: "Sports",
      location: "Sikar",
      location_address: "Sikar",
      start_date: "09/12/2023",
    },
    {
      img: NFt3,
      title: "ETH AI Brain",
      author: "Nick Wilson",
      type: "Music",
      location: "Delhi",
      location_address: "Delhi",
      start_date: "09/12/2023",
    },
    {
      img: NFt6,
      title: "Mesh Gradients",
      author: "Will Smith",
      type: "Sports",
      location: "Sikar",
      location_address: "Sikar",
      start_date: "09/12/2023",
    },
  ];
  const styles = {
    top: {
      backgroundColor: scrollTop >= 600 ? "rgb(0,0,0,0.7)" : "",
      position: scrollTop >= 600 ? "fixed" : "",
      top: scrollTop >= 600 ? -30 : "",
      zIndex: 999,
      color: scrollTop >= 600 ? "white" : "",
      backdropFilter: scrollTop >= 600 ? "blur(20px)" : "",
      padding: scrollTop >= 600 ? "1%" : "",
      borderRadius: scrollTop >= 600 ? "17px" : "",
    },

    top2: {
      // backgroundColor: scrollTop >= 890  ? "red": "blue",
      display: scrollTop >= 600 ? "none" : "",
    },
    top3: {
      // borderBottom: scrollTop >= 600 ? "" : "3px solid rgb(117 81 255 )",
      // paddingBottom: scrollTop >= 600 ? "" : "2%",
      // marginLeft: scrollTop >= 600 ? "2.5%" :""
    },
    top4: {
      // borderTop:scrollTop >= 600 ? "": "3px solid rgb(117 81 255 )",
      // width: scrollTop >= 600 ? "":"100%",
      // height:scrollTop >= 600 ? "6vh":"8.7vh",
      // borderLeft:scrollTop >= 600 ? "": "4px solid rgb(117 81 255 )",
      // transform:scrollTop >= 600 ? "": "skew(140deg)",
    },
  };
  return (
    <>
      <div className="flex h-full w-full">
        <div className="h-full w-full bg-lightPrimary dark:!bg-navy-900">
          <main className={` h-full flex-none transition-all `}>
            {/* Routes */}
            <div className="h-full">
              <LandingNavbar top2={styles.top2}
                // onOpenSidenav={() => setOpen(true)}
                // logoText={"Horizon UI Tailwind React"}
                // brandText={currentRoute}
                secondary={getActiveNavbar(routes)}
                {...rest}
                className="xl:mx-[53px]"
              />
              <div className="mx-auto mb-auto h-full ">
                <TopHeader open={open} setOpen={setOpen} />
              </div>
              <Tabs styles={styles} />
              <div className="mt-5">
                <Footer />
              </div>
            </div>
          </main>
        </div>
      </div>
      <SignupModal open={open} setOpen={setOpen} />
    </>
  );
};

export default SingleEvent;
