import { Event1 } from 'assets'
import LandingNavbar from 'components/navbar/LandingNavbar'
import React from 'react'
import { BsChevronDoubleDown, BsStopwatch } from 'react-icons/bs'
import { GrView } from "react-icons/gr"
import { GoGraph } from "react-icons/go"
import Card from '../card'
import { MdOutlineSort } from 'react-icons/md'
import { TbListDetails } from 'react-icons/tb'
import { AiOutlineEdit } from 'react-icons/ai'

export const Transction = () => {
  return (
    <>
      <LandingNavbar />
      <div className='dark:bg-navy-700 dark:text-white pt-[22%] md:pt-[8%]'>
        <hr />
        <div className='grid grid-cols-2 mx-[5%]'>
          <div className='border-2 rounded-xl p-[7%] my-8 w-100 col-span-2 md:col-span-1'>
            <img className='w-100' src={Event1} alt="" />
          </div>
          <div className='col-span-2 md:col-span-1 m-2 md:m-8'>
            <p className='my-3' style={{ fontSize: "30px" }}><b> A Dance Event</b></p>
            <p>Organize by R.K. Club</p> <br />
            <div style={{ display: "flex", }}>
              <GrView className='mt-1 ' />
              <p>&nbsp; 150 views</p>
            </div><br />
            <Card>


              <div className='flex p-5 border-b-2 border-inherit' >
                <BsStopwatch className='mt-1' />
                <p>&nbsp; Last Date Of Booking is 5 sep. 2023 at 2:30 pm</p>
              </div>
              <div className='p-5 '>

                <p>Current Price </p>
                <p style={{ fontSize: "30px" }}><b> 2.005ETH</b></p>
                <p>$ 10.5</p>
              </div>
            </Card>
            <Card extra={"mt-4"}>

              <div className='flex p-5 border-b-2 border-inherit' >
                <GoGraph className='mt-1' />
                <p>&nbsp; Price History</p>
              </div>
              <div className='p-5 '>

                <p>Current Price </p>
                <p style={{ fontSize: "30px" }}><b> 2.005ETH</b></p>
                <p>$ 10.5</p>
              </div>
            </Card>
          </div>
        </div>
        <div className='grid grid-cols-2 m-[5%]'>
          <Card extra="col-span-2 md:col-span-1">
            <div className='flex p-5 border-b-2 border-inherit' >
              <MdOutlineSort className='mt-1' />
              <p>&nbsp; Description</p>
            </div>
            <div className='p-5 border-b-2 border-inherit'>

              <p style={{ fontSize: "25px" }}>By The R.K. Club</p>
              <p>A dance event for kids</p>


            </div>
            <div className='flex p-5 ' >
              <TbListDetails className='mt-1' />
              <p>&nbsp; More details</p>
              <BsChevronDoubleDown style={{ marginLeft: "82%" }} className='mt-1' />
            </div>
          </Card>
          <Card extra="col-span-2 md:col-span-1 my-[5%] md:my-[0%] mx-[0%] md:mx-[4%]">
            <div className='flex p-5 border-b-2 border-inherit' >
              <AiOutlineEdit className='mt-1' />
              <p>&nbsp; Listing</p>
            </div>
            <div className='border-b-2 border-inherit'>

              <div style={{ color: "gray" }} className='grid grid-cols-5 px-2'>
                <p>price</p>
                <p>USD price</p>
                <p>Quantity</p>
                <p>Expiration</p>
                <p>From</p>
              </div>
            </div>
            <div className='grid grid-cols-5 px-2 py-4 '>
              <p><b>2.05ETH</b></p>
              <p>$80</p>
              <p>25</p>
              <p>in 4 months </p>
              <p>R.K. Club</p>
            </div>
            <div className='grid grid-cols-5 px-2 py-3'>
              <p><b>2.05ETH</b></p>
              <p>$80</p>
              <p>25</p>
              <p>in 4 months </p>
              <p>Lython Lyon</p>
            </div>
            <div className='p-5 '>

            </div>
          </Card>
        </div>
        <div>
        </div>
      </div>
    </>
  )
}
