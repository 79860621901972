import React, { useState } from "react";
import { FiAlignJustify } from "react-icons/fi";
import { RiMoonFill, RiSunFill } from "react-icons/ri";
import { Link } from "react-router-dom";

const LandingNavbar = (props) => {
  const { onOpenSidenav, open, setOpen } = props;
  const [darkmode, setDarkmode] = React.useState(false);
  const handleChange = () => setOpen(!open);
  return (
    <nav style={props.top2} className="w-[90%] fixed top-4 left-[5%] z-40 flex flex-row flex-wrap items-center justify-between rounded-xl p-2 backdrop-blur-xl bg-[#757cad52]">
      <div className="ml-[6px]">
        <Link to='/admin/dashboard'>
        <div className="supershow ml-1 font-poppins text-[26px] font-bold uppercase text-white">
          Super <span className="font-medium">Show</span>
        </div>
        </Link>
      </div>
      <div className="flex gap-6">
      <div className=" hidden md:flex items-center justify-center gap-4">
        <div>
        <Link to="/Aboutus">
          <h3 className=" dark:text-white font-semibold text-[1.5rem] text-center hover:text-blue-600 cursor-pointer">
            About us
          </h3>
        </Link>
        </div>
        <div onClick={handleChange}>
          <h3 className="dark:text-white font-semibold text-[1.5rem] text-center hover:text-blue-600  cursor-pointer">
            Contact us
          </h3>
        </div>
      </div>
      <div className="relative mt-[3px] flex h-[41px] flex-grow items-center justify-around gap-2 rounded-full bg-white px-2 py-2 shadow-xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none md:flex-grow-0 md:gap-1 xl:w-[65px] my-2 xl:gap-2">
        <span
          className="flex cursor-pointer text-xl text-gray-600 dark:text-white xl:hidden"
          onClick={onOpenSidenav}
        >
          <FiAlignJustify className="h-5 w-5" />
        </span>
       <div
          className="cursor-pointer text-gray-600"
          onClick={() => {
            if (darkmode) {
              document.body.classList.remove("dark");
              setDarkmode(false);
            } else {
              document.body.classList.add("dark");
              setDarkmode(true);
            }
          }}
        >
          {darkmode ? (
            <RiSunFill className="h-4 w-4 text-gray-600 dark:text-white" />
          ) : (
            <RiMoonFill className="h-4 w-4 text-gray-600 dark:text-white" />
          )}
        </div>
      </div>
      </div>
    </nav>
  );
};

export default LandingNavbar;
