import React from 'react'
import NFt2 from "assets/img/nfts/Nft2.png";
import NFt4 from "assets/img/nfts/Nft4.png";
import NFt3 from "assets/img/nfts/Nft3.png";
import NFt5 from "assets/img/nfts/Nft5.png";
import NFt6 from "assets/img/nfts/Nft6.png";
import EventCard from "components/card/EventCard";
import ProfileBanner from './Banner';
import Banner from 'views/admin/profile/components/Banner';


const SingleEvent = () => {
    const tranding = [
        { img: NFt4, title: "Abstract  Colors", author: 'Esthera Jackson', type: "Arts", location: "Jaipur", location_address: "Jaipur", start_date: "09/12/2023" },
        { img: NFt2, title: "ETH AI Brain", author: 'Nick Wilson', type: "Music", location: "Delhi", location_address: "Delhi", start_date: "09/12/2023" },
        { img: NFt3, title: "Mesh Gradients", author: 'Will Smith', type: "Sports", location: "Sikar", location_address: "Sikar", start_date: "09/12/2023" },
        { img: NFt5, title: "Mesh Gradients", author: 'Will Smith', type: "Sports", location: "Sikar", location_address: "Sikar", start_date: "09/12/2023" },
        { img: NFt6, title: "Mesh Gradients", author: 'Will Smith', type: "Sports", location: "Sikar", location_address: "Sikar", start_date: "09/12/2023" },
        { img: NFt2, title: "ETH AI Brain", author: 'Nick Wilson', type: "Music", location: "Delhi", location_address: "Delhi", start_date: "09/12/2023" },
      ];
    
      return (
        <div className="mt-3 grid h-full grid-cols-1 gap-5 xl:grid-cols-2 2xl:grid-cols-4">
          <div className="col-span-1 h-fit w-full xl:col-span-1 2xl:col-span-4">
            {/* NFt Banner */}
            <ProfileBanner />
            <Banner />

    
            {/* NFt Header */}
            <div className="mb-4 mt-5 flex flex-col justify-between px-4 md:flex-row md:items-center">
              <h4 className="ml-1 text-2xl font-bold text-navy-700 dark:text-white">
                Trending NFTs
              </h4>
              <ul className="mt-4 flex items-center justify-between md:mt-0 md:justify-center md:!gap-5 2xl:!gap-12">
                <li>
                  <a
                    className="text-base font-medium text-brand-500 hover:text-brand-500 dark:text-white"
                    href=" "
                  >
                    Art
                  </a>
                </li>
                <li>
                  <a
                    className="text-base font-medium text-brand-500 hover:text-brand-500 dark:text-white"
                    href=" "
                  >
                    Music
                  </a>
                </li>
                <li>
                  <a
                    className="text-base font-medium text-brand-500 hover:text-brand-500 dark:text-white"
                    href=" "
                  >
                    Collection
                  </a>
                </li>
                <li>
                  <a
                    className="text-base font-medium text-brand-500 hover:text-brand-500 dark:text-white"
                    href=" "
                  >
                    <a href=" ">Sports</a>
                  </a>
                </li>
              </ul>
            </div>
    
            {/* NFTs trending card */}
            <div className="z-20 grid grid-cols-1 gap-5 md:grid-cols-3">
            {tranding.map((data) => (
                <EventCard
    
                  title={data.title}
                  author={data.author}
                  image={data.img}
                  start_date={data.start_date}
                  type={data.type}
    
                  location={data.location}
                  location_address={data.location_address}
                />
              ))}
              {/* <NftCard
                bidders={[avatar1, avatar2, avatar3]}
                title="ETH AI Brain"
                author="Nick Wilson"
                price="0.7"
                image={NFt2}
              />
              <NftCard
                bidders={[avatar1, avatar2, avatar3]}
                title="Mesh Gradients"
                author="Will Smith"
                price="2.91"
                image={NFt4}
              /> */}
            </div>
    
           
          </div>
    
          {/* right side section */}
    
          {/* <div className="col-span-1 h-full w-full rounded-xl 2xl:col-span-1">
            <TopCreatorTable
              extra="mb-5"
              tableData={tableDataTopCreators}
              columnsData={tableColumnsTopCreators}
            />
            <HistoryCard /> */}
          {/* </div> */}
        </div>
      );
}

export default SingleEvent