/* eslint-disable */

import { HiX } from "react-icons/hi";
import Links from "./components/Links";
import Card from "components/card";
import { useNavigate } from "react-router-dom";
import { FiLogOut } from "react-icons/fi";
import { IoLogIn, IoLogInOutline } from "react-icons/io5";
import { useState } from "react";
import { RiMoonFill, RiSunFill } from "react-icons/ri";

const Sidebar = ({ open, onClose, routes }) => {

  const [darkmode, setDarkmode] = useState(false);
  const Navigate = useNavigate();
  const Logout = () => {
    console.log("logout");
    localStorage.removeItem('superShow_token');
    // Navigate('/')
    window.location.href = '/'
  }
  return (
    <div
      className={`sm:none duration-175 linear fixed !z-50 flex min-h-full flex-col bg-lightPrimary pb-10 shadow-2xl shadow-white/5 transition-all dark:bg-navy-900 dark:text-white md:!z-50 lg:!z-50 xl:!z-0 ${open ? "translate-x-0" : "-translate-x-96"
        }`}
    >
      <span
        className="absolute top-4 right-4 cursor-pointer xs:block sm:block lg:block xl:hidden xxl:hidden"
        onClick={onClose}
      >
        <HiX />
      </span>

      <div className={`mx-[20px] mt-[40px] flex items-center`}>
        <Card extra={"h-[60px] w-full"} style={{ padding: "5px 45px" }} >
          <div className="mt-1 ml-1  font-poppins text-[26px] font-bold uppercase text-navy-700 dark:text-white">
            Super <span className="font-medium">Show</span>
          </div>
        </Card>
      </div>
      <div className="mt-[40px] mb-7 h-px bg-gray-300 dark:bg-white/30" />
      <Card extra={"h-[600px]"} style={{ width: "90%", marginLeft: "5%" }} >
        <ul className="mb-auto pt-3">
          <Links routes={routes} />
        </ul>
      </Card>
      <div className="absolute left-4 bottom-[4rem]  cursor-pointer">
        <Card extra={"h-[60px] w-full"} >
          <h1 className="mb-auto px-[5rem] font-poppins  font-semibold  text-red-600 text-[26px] uppercase" onClick={Logout}>
            Logout
            <IoLogInOutline />

          </h1>
        </Card>
      </div>

    </div>
  );
};

export default Sidebar;
