import LandingNavbar from 'components/navbar/LandingNavbar'
import React, { useEffect, useState } from 'react'
import "../../index.css"
import Landingcard2 from './Landingcard2'
import { Allevents } from './allevents'

export const Wishlist = () => {
  const [scrollTop, setScrollTop] = useState(0);
  const [tab, setTab] = useState("");
  const Tabs = [
    { event: "Select Category" },
    { event: "All Events" },
    { event: "Games & Sports Events", id: "1" },
    { event: " Music", id: "2" },
    { event: " Networking events", id: "3" },
    { event: "Workshops", id: "4" },
    { event: "Charity Event", id: "5" },
    { event: " Exhibitions", id: "6" },
    { event: " Virtual Event", id: "7" },
    { event: " Other", id: "7" },

  ];
  const Change = (e) => {
    setTab(e.target.value)
  }



  return (
    <div className=' '>
      <div style={{ position: "sticky", top: "15%", zIndex: 999, width: "200px" }} className="">
        <label for="selected-tab" className="sr-only">Select Category</label>
        <select onChange={Change} id="selected-tab" name="selected-tab" className="dark:bg-navy-800 dark:text-white  mt-1 block w-full pl-3 pr-10 py-2 border-gray-300 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm rounded-md">

          {Tabs.map((data, index) => (
            <option
              className=''
              key={index}
              onClick={() => (setTab(data.event), console.log(data.event))}
            >
              {data.event}
            </option>

          ))}
        </select>
      </div>

      <div className='my-10'>
        <Allevents />
      </div>

    </div>
  )
}
