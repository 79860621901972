import React from 'react';

const SocialMediaIntegration = () => {
  // Replace the 'socialMediaFeeds' with the actual data from your social media APIs or services
  const socialMediaFeeds = [
    {
      id: 1,
      username: 'event_enthusiast',
      content: 'Excited about the upcoming workshop! #workshop #event',
      timestamp: '2023-08-07 12:34:56',
    },
    {
      id: 2,
      username: 'event_lover',
      content: 'Just registered for the conference. Can\'t wait! #conference #event',
      timestamp: '2023-08-06 18:45:23',
    },
    // Add more social media feeds as needed
  ];

  return (
    <div className="bg-white rounded-lg shadow-md p-6 mt-5">
      <h2 className="text-xl font-semibold mb-4">Social Media Integration</h2>

      <div className="space-y-4">
        <h3 className="font-semibold">Share this event:</h3>
        <div className="flex space-x-4">
          <button className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 focus:outline-none">
            Facebook
          </button>
          <button className="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600 focus:outline-none">
            Twitter
          </button>
          <button className="bg-pink-500 text-white py-2 px-4 rounded hover:bg-pink-600 focus:outline-none">
            Instagram
          </button>
        </div>
      </div>

      <div className="mt-8 space-y-4">
        <h3 className="font-semibold">Social Media Feeds:</h3>
        {socialMediaFeeds.map((feed) => (
          <div key={feed.id} className="bg-gray-100 p-4 rounded-lg">
            <p className="text-gray-600 mb-2">@{feed.username}</p>
            <p>{feed.content}</p>
            <p className="text-gray-500 mt-2 text-sm">{feed.timestamp}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SocialMediaIntegration;
