import React, { useState } from "react";import TicketTab from "./TicketTab";
import { Faq } from "components/Aboutus/faq";
import EventRightSideBar from "./EventRightSideBar";
import TermsAndConditions from "./T&C";
import { termsAndConditions } from "servicerepo/LandingPage/Terms&Contions";
import CommunityPage from "./CommunityPage";
import AboutUsPage from "./AboutUsTab";

const Tabs = (props) => {
  const [tab, setTab] = useState("Tickets");

  const Tabs = [
    { event: "Tickets", tabData: <TicketTab /> },
    { event: "About", tabData: <Faq /> },
    { event: "Community", tabData: <AboutUsPage /> },
    {
      event: "FAQ",
      tabData:
        <div className="min-h-screen">
          <CommunityPage />
        </div>
    },
    { event: "Terms and Conditions", tabData: <TermsAndConditions terms={termsAndConditions} /> },
  ];

  return (
    <>
      <div className="grid grid-cols-4 xl:mx-[5.2rem]">
        <div className="col-span-3">
          <div style={props.styles.top} className="my-[2.4rem] flex items-center justify-start">
            <div style={props.styles.top3} className="flex flex-col items-center justify-start md:flex-row">
              {Tabs.map((data, index) => (
                <h3
                  className={`${data.event === tab
                    ? "border-b-2 text-blue-500 dark:text-brand-400"
                    : "dark:text-white"
                    } mx-5 cursor-pointer border-indigo-500 text-[1.5rem] `}
                  key={index}
                  onClick={() => setTab(data.event)}
                >
                  {data.event}
                </h3>
              ))}
            </div>
            <div className="mx-7" style={props.styles.top4} >
            </div>
          </div>
          {Tabs.filter(item => item.event === tab).map((data) => (
            <>
              {data.tabData}
            </>
          ))}
        </div>
        <EventRightSideBar />
      </div>
    </>
  );
};
export default Tabs;
