import { UserUpdate } from 'Hooks/UserHook';
import { UserInfo } from 'Hooks/UserHook';
import Card from 'components/card';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const UserUpdateForm = () => {
    const [form, setForm] = useState();
    const [userData, setUserData] = useState({});
    const { id } = useParams();
    console.log("uid", id);

    const OrganiserDetails = () => {
        UserInfo(id).then((res) => {
            console.log(res.data);
            setUserData(res.data)
        })
    }

    useEffect(() => {
        OrganiserDetails();
    }, [])

    const handleChange = (e) => {
        if (e.target.name === "phone") {
            e.target.value = e.target.value.slice(0, 10);
            setForm({
                ...form,
                [e.target.name]: e.target.value,
            });
        }
        setForm({
            ...form,
            [e.target.name]: e.target.value,
        });
    };

    console.log(form);
    const Submit = () => {
        UserUpdate(id, form).then((res) => {
            console.log(res);
        });
    };
    return (
        <div>
            <div className="mt-5 grid h-full">
                <Card extra={"w-full h-full p-4"}>
                    <div className="relative flex items-center justify-between">
                        <div className="text-xl font-bold text-navy-700 dark:text-white">
                            Update User
                        </div>
                    </div>

                    <div className="flex flex-col items-center	 ">
                        <div className="mb-3 lg:w-[60%]  items-center">
                            <label
                                htmlFor="name"
                                className={`"ml-1.5 font-medium" : 
 "ml-3 font-bold" text-sm text-navy-700 dark:text-white
`}
                            >
                                First Name
                            </label>
                            <input
                                type="text"
                                id="name"
                                name="fname"
                                onChange={handleChange}
                                autoComplete="nope"
                                placeholder={userData.fname ? userData.fname  : "First Name"}
                                className={`mt-2 flex h-12 w-full  items-center dark:text-white justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none `}
                            />
                        </div>

                        <div className="mb-3 lg:w-[60%]  items-center">
                            <label
                                htmlFor="lname"
                                className={`"ml-1.5 font-medium" : 
 "ml-3 font-bold" text-sm text-navy-700 dark:text-white
`}
                            >
                                Last Name
                            </label>
                            <input
                                type="text"
                                id="email"
                                name="lname"
                                onChange={handleChange}
                                autoComplete="nope"
                                placeholder={userData.lname  ? userData.lname : "Last Name"}
                                className={`mt-2 flex h-12 w-full  dark:text-white items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none `}
                            />
                        </div>
                        <div className="mb-3 lg:w-[60%]  items-center">
                            <label
                                htmlFor="email"
                                className={`"ml-1.5 font-medium" : 
 "ml-3 font-bold" text-sm text-navy-700 dark:text-white
`}
                            >
                                Email*
                            </label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                onChange={handleChange}
                                autoComplete="nope"
                                placeholder={userData.email ? userData.email :"mail@simmmple.com"}
                                className={`mt-2 flex h-12 w-full  dark:text-white items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none `}
                            />
                        </div>
                        <div className="mb-3 lg:w-[60%]  items-center">
                            <label
                                htmlFor="email"
                                className={`"ml-1.5 font-medium" : 
 "ml-3 font-bold" text-sm text-navy-700 dark:text-white
`}
                            >
                                Phone
                            </label>
                            <input
                                type="number"
                                id="email"
                                name="phone"
                                onChange={handleChange}
                                autoComplete="nope"
                                placeholder={userData.phone ? userData.phone : "Phone Number"}
                                className={`mt-2 flex h-12 w-full  dark:text-white items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none `}
                            />
                        </div>
                        <button
                            onClick={Submit}
                            className="linear mt-2 w-full lg:w-[30%] rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                        >
                            User Update
                        </button>
                    </div>
                </Card>
            </div>
        </div>
    )
}

export default UserUpdateForm
