import Banner from "./components/Banner";
import NFt2 from "assets/img/nfts/Nft2.png";
import NFt4 from "assets/img/nfts/Nft4.png";
import NFt3 from "assets/img/nfts/Nft3.png";
import NFt5 from "assets/img/nfts/Nft5.png";
import NFt6 from "assets/img/nfts/Nft6.png";

import tableDataTopCreators from "views/admin/marketplace/variables/tableDataTopCreators.json";
import { tableColumnsTopCreators } from "views/admin/marketplace/variables/tableColumnsTopCreators";
import HistoryCard from "./components/HistoryCard";
import TopCreatorTable from "./components/TableTopCreators";
import MarketplaceEventCard from "components/card/MarketplacEventCard";
import UserBanner from "servicerepo/User/Marketplace/banner";
import MarketplaceBanner from "components/Banner";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Modal from "components/modal";
import CategoryBar from "components/Category/CategoryBar";

const Marketplace = () => {
 
  const tranding = [
    {
      img: NFt4,
      title: "Abstract  Colors",
      author: "Esthera Jackson",
      type: "Arts",
      location: "Jaipur",
      location_address: "Jaipur",
      start_date: "09/12/2023",
    },
    {
      img: NFt2,
      title: "ETH AI Brain",
      author: "Nick Wilson",
      type: "Music",
      location: "Delhi",
      location_address: "Delhi",
      start_date: "09/12/2023",
    },
    {
      img: NFt5,
      title: "Mesh Gradients",
      author: "Will Smith",
      type: "Sports",
      location: "Sikar",
      location_address: "Sikar",
      start_date: "09/12/2023",
    },
  ];

  const Recent = [
    {
      img: NFt4,
      title: "Abstract  Colors",
      author: "Esthera Jackson",
      type: "Arts",
      location: "Jaipur",
      location_address: "Jaipur",
      start_date: "09/12/2023",
    },
    {
      img: NFt2,
      title: "ETH AI Brain",
      author: "Nick Wilson",
      type: "Music",
      location: "Delhi",
      location_address: "Delhi",
      start_date: "09/12/2023",
    },
    {
      img: NFt6,
      title: "Mesh Gradients",
      author: "Will Smith",
      type: "Sports",
      location: "Sikar",
      location_address: "Sikar",
      start_date: "09/12/2023",
    },
  ];

  console.log(UserBanner);
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div className="mt-3 grid h-full grid-cols-1 gap-5 xl:grid-cols-2 2xl:grid-cols-4">
      <div className="col-span-1 h-fit w-full xl:col-span-1 2xl:col-span-3">
       
        <Slider {...settings} className="mb-[30px]">
          {UserBanner !== undefined &&
            UserBanner.map((data, index) => (
              <MarketplaceBanner data={data} />
            ))}
        </Slider>
            
        {/* <CategoryBar  /> */}
        <div className="mb-4 mt-5 flex flex-col justify-between px-4 md:flex-row md:items-center">
          <h4 className="ml-1 text-2xl font-bold text-navy-700 dark:text-white">
            Trending NFTs
          </h4>
          <Modal />
          <ul className="mt-4 flex items-center justify-between md:mt-0 md:justify-center md:!gap-5 2xl:!gap-12">
            <li>
              <a
                className="text-base font-medium text-brand-500 hover:text-brand-500 dark:text-white"
                href=""
              >
                Art
              </a>
            </li>
            <li>
              <a
                className="text-base font-medium text-brand-500 hover:text-brand-500 dark:text-white"
                href=" "
              >
                Music
              </a>
            </li>
            <li>
              <a
                className="text-base font-medium text-brand-500 hover:text-brand-500 dark:text-white"
                href=" "
              >
                Collection
              </a>
            </li>
            <li>
              <a
                className="text-base font-medium text-brand-500 hover:text-brand-500 dark:text-white"
                href=" "
              >
                <a href=" ">Sports</a>
              </a>
            </li>
          </ul>
        </div>

        {/* NFTs trending card */}
        <div className="z-20 grid grid-cols-1 gap-5 md:grid-cols-3">
          {tranding.map((data) => (
            <MarketplaceEventCard
              title={data.title}
              author={data.author}
              image={data.img}
              start_date={data.start_date}
              type={data.type}
              location={data.location}
              location_address={data.location_address}
            />
          ))}
        </div>

        {/* Recenlty Added setion */}
        <div className="mb-5 mt-5 flex items-center justify-between px-[26px]">
          <h4 className="text-2xl font-bold text-navy-700 dark:text-white">
            Recently Added
          </h4>
        </div>

        {/* Recently Add NFTs */}
        <div className="grid grid-cols-1 gap-5 md:grid-cols-3">
          {Recent.map((data) => (
            <MarketplaceEventCard
              title={data.title}
              author={data.author}
              image={data.img}
              start_date={data.start_date}
              type={data.type}
              location={data.location}
              location_address={data.location_address}
            />
          ))}
        </div>
      </div>

      {/* right side section */}

      <div className="col-span-1 h-full w-full rounded-xl 2xl:col-span-1">
        <TopCreatorTable
          extra="mb-5"
          tableData={tableDataTopCreators}
          columnsData={tableColumnsTopCreators}
        />
        <HistoryCard />
      </div>
    </div>
  );
};

export default Marketplace;
