import React from "react";
import Slider from "react-slick";
import "../components/landing.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LandingCard from "components/card/LandingCard";
import { Event1 } from "assets";
import { Event2 } from "assets";
import { Landing_Video } from "assets";
import { FaFacebook, FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa";
import { avatar1 } from "assets";
import { sEvent } from "assets";

const TopHeader = (props) => {

  const Arr = [
    {
      ind: 3,
      title1: "Ultimate survival game on the blockchain",
      title2: "Play-to-Earn",
      asset:
      sEvent,
      assetType: "img",
      desc: "Explore the post-apocalyptic metaverse,survive and earn crypto while having fun",
    },
  ];

  return (
    <div>
      <div className={`mb-[40px] flex items-center`}>
        <div
          className={`  flex items-center`}
          style={{ overflow: "hidden" }}
        >
          {Arr.map((data, index) => (
            <LandingCard
              key={index}
              extra={"h-[55vh]  w-[100vw]"}
            >
              <div className="slider-single-bg-relative">
                {data.assetType === 'img' ?
                  <img src={data.asset} alt="" />
                  :
                  <video
                    src={data.asset} autoPlay muted loop className=''>
                  </video>}
              </div>
              <div className="slider-single-bg-absolute">
                <div className="flex flex-grow items-center justify-between pl-[4.5rem]">
                  <div className="flex items-center">
                    <div>
                      <img src={avatar1} className="w-[5vw] rounded-full" alt="" />
                    </div>
                    <div>
                      <h3 className="text-[2rem] font-semibold text-white mx-4">
                        Shannon Mayer
                      </h3>
                    </div>
                  </div>
                  <div className="my-3 flex flex-col">
                    <h3 className="text-[2rem] font-semibold text-white mx-4">
                      Virtual Football Game
                    </h3>
                    <h5 className="text-[1.2rem] text-white mx-4">
                      Tickets Sell : 5080/15000
                    </h5>

                  </div>
                  <div className="flex aligns-center gap-6 justify-center mr-[5rem] cursor-pointer">
                    <div>
                      <FaInstagram size={45} className="text-red-300" />
                    </div>
                    <div>
                      <FaFacebook size={45} className="text-blue-600" />
                    </div>

                    <div>
                      <FaYoutube size={45} className="text-red-500" />
                    </div>

                    <div>
                      <FaTwitter size={45} className="text-blue-400" />
                    </div>
                  </div>
                </div>
              </div>



            </LandingCard>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TopHeader;
