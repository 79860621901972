import Checkbox from 'components/checkbox'
import Modal from 'components/modal'
import React, { useState } from 'react'

const ContactUs = (props) => {
    const { open, setOpen } = props;
    const handleChange = () => setOpen(!open);
    return (
        <>
            {/* <button onClick={handleChange}>
                Button
            </button> */}
            <Modal open={open} setOpen={setOpen}>
                <h3 className='dark:text-white text-navy-900 font-bold text-[30px] text-center'>
                    Contact Us
                </h3>
                <div className="">
                    <div className="mb-3">
                        <label
                            htmlFor="name"
                            className={`"ml-1.5 font-medium" : 
 "ml-3 font-bold" text-sm text-navy-700 dark:text-white
`}
                        >
                            Name
                        </label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            // onChange={handleChange}
                            autoComplete="nope"
                            placeholder="Name"
                            className={`mt-2 flex h-12 w-full items-center dark:text-white justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none `}
                        />
                    </div>

                    <div className="mb-3">
                        <label
                            htmlFor="email"
                            className={`"ml-1.5 font-medium" : 
 "ml-3 font-bold" text-sm text-navy-700 dark:text-white
`}
                        >
                            Email*
                        </label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            // onChange={handleChange}
                            autoComplete="nope"
                            placeholder="mail@simmmple.com"
                            className={`mt-2 flex h-12 w-full dark:text-white items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none `}
                        />
                    </div>
                    <div className="mb-3">
                        <label
                            htmlFor="contact"
                            className={`"ml-1.5 font-medium" : 
 "ml-3 font-bold" text-sm text-navy-700 dark:text-white
`}
                        >
                            Description
                        </label>
                        <textarea name="desc" placeholder='Write something here...' id="contact" className='p-2 w-[100%] border dark:text-white dark:bg-navy-700 rounded' rows="5"></textarea>
                    </div>

                    <button
                        // onClick={LoginSubmit}
                        className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                    >
                        Contact Us
                    </button>

                </div>
            </Modal>
        </>
    )
}

export default ContactUs