import { avatar1 } from 'assets'
import React, { useState } from 'react'
import "./settings.css"
import { General } from './general';
import { Privacy } from './privacy';
export const Settings = () => {
    const [tab, setTab] = useState("General");
    const Tabs = [
      // { event: "Campaign" },
      { event: "General" },
      { event: "Privacy" },
      { event: "Edit Profile" },
     
    ];
    const Change=(e)=>{
      setTab(e.target.value)
    }
  return (
    <div className='m-[2%]'>
        <div className='backimg'>

        <div className=' m-[2%] grid grid-cols-2 w-[20vw]'>
            <div >
                <img className="w-[7vw] h-[7vw]" style={{borderRadius:'50%'}} src={avatar1} alt="" />
            </div>
            <div className='mx-[-8%] my-[14%] text-white'>
                <p  style={{fontSize:"30px"}}>User name</p>
                <p>Update Your Profile</p>
            </div>
        </div>
        </div>
        {/* <div  className={ ` my-[1rem] flex items-center justify-start`} >

        {Tabs.map((data, index) => (
                <h3
                  className={`${
                    data.event === tab
                      ? "border-b-2 text-blue-500 dark:text-brand-400"
                      : "dark:text-white"
                  } mx-5 cursor-pointer border-indigo-500 text-[1.5rem] `}
                  key={index}
                  onClick={() => setTab(data.event)}
                >
                  {data.event}
                </h3>

              ))}
    
        </div> */}
        <div>
  


  <div className="">
    <div className=" flex flex-col ">
    

      <main className="flex-1">
        <div className="relative  ">
          <div className="">
        
            <div className="">
              <div className="">
                <div className="lg:hidden">
                  <label for="selected-tab" className="sr-only">Select a tab</label>
                  <select onChange={Change} id="selected-tab" name="selected-tab" className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm rounded-md">
                    
                    {Tabs.map((data, index) => (
                <option
                 
                  key={index}
                  onClick={() => (setTab(data.event), console.log(data.event))}
                >
                  {data.event}
                </option>

              ))}
               
                    
                  </select>
                </div>
                <div  className="hidden lg:block">
                  <div  className="border-b border-gray-200">
                  <nav className="-mb-px flex space-x-8">
                      {/* <a  onClick={() => setTab("general")} className=" border-blue-600 text-blue-600 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"> General </a> */}

                      {Tabs.map((data, index) => (
                <a 
                 
                  className={`${tab === data.event
                ? "border-navy-400 dark:text-blue-600/[1]  border-b-2"
                : ""
                } tabs1 dark:text-white text-gray-800  whitespace-nowrap py-4 px-1`}
                  key={index}
                  onClick={() => setTab(data.event)}
                >
                  {data.event}
                </a>

              ))}
                    </nav>
                  </div>
                </div>
               {tab === "General" && (
                <General/>
               )}
               {tab === "Privacy" && (
                <Privacy/>
               )}
           
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</div>
    </div>
  )
}
