import { avatar2 } from 'assets';
import { avatar1 } from 'assets';
import React from 'react';

const AboutUsPage = () => {
  return (
    <div className="about-us-container">
      <h1 className="about-us-title text-[#666] dark:text-white">About Our Event</h1>
      <p className="about-us-description text-[#333] dark:text-white">
        Welcome to our exciting event! We are passionate about bringing people together and creating memorable experiences for our participants. Our event is a celebration of creativity, innovation, and community.
      </p>
      <p className="about-us-description text-[#333] dark:text-white">
        Our mission is to provide a platform for talented individuals to showcase their skills and ideas. We believe in fostering a collaborative and inclusive environment, where everyone's voice is heard and valued.
      </p>
      <h2 className="organizers-title  text-[#333] dark:text-white">Meet the Organizers</h2>
      <div className="organizer">
        <img src={avatar1} alt="Organizer 1" className="organizer-image" />
        <div className="organizer-info">
          <h3 className="organizer-name  text-[#333] dark:text-white">John Doe</h3>
          <p className="organizer-description text-[#666] dark:text-white">
            John is a seasoned event organizer with years of experience in planning and executing successful events. He is passionate about creating engaging and unique experiences for attendees.
          </p>
        </div>
      </div>
      <div className="organizer">
        <img src={avatar2} alt="Organizer 2" className="organizer-image" />
        <div className="organizer-info">
          <h3 className="organizer-name  text-[#333] dark:text-white">Jane Smith</h3>
          <p className="organizer-description text-[#666] dark:text-white">
            Jane is an enthusiastic event planner with a keen eye for detail. She loves curating exciting activities and workshops to make our event truly special for everyone.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUsPage;
