import React from "react";
import { cap1, cap2, tshirt1, tshirt2, tshirt3 } from "assets";

export const Merchandies = () => {
  const arr = [
    {
      id: 1,
      img: cap1,
      heading:"Cap For Event",
      Subtitle:"💲 200"
    },
    {
      id: 1,
      img: cap2,
      heading:"Cap For Event",
      Subtitle:"💲 200"
    },
    {
      id: 1,
      img:tshirt1,
      heading:"Tshirt For Event",
      Subtitle:"💲 200"
    },
    {
      id: 1,
      img: tshirt2,
      heading:"Tshirt For Event",
      Subtitle:"💲 200"
    },
    {
      id: 1,
      img: tshirt3,
      heading:"Tshirt For Event",
      Subtitle:"💲 200"
    },
  ];
  return <div>
    <div className="dark:text-white">
        <p style={{ fontSize: "25px", fontWeight: 600 }}></p>
        <br />
        <div className="grid w-[91vw] grid-cols-4">
          {arr.map((data) => (
            <div className="card012 h-[55vh] w-[21vw]">
              <img
                // style={{ objectFit: "cover" }}
                className="h-[42vh] w-[20vw] rounded-xl"
                src={data.img}
                alt=""
              />
              <p className="my-2" style={{ fontSize: "22px", fontWeight: 500 }}>
                {data.heading}
              </p>
              <p style={{ color: "gray" }}>{data.Subtitle}</p>
            </div>
          ))}
        </div>
      </div>
  </div>;
};
