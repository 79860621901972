import React from "react";
import "./modal.css";
const Modal = (props) => {

  const { open, setOpen, children, hanleInputChange } = props;

  const handleModal = () => {
    setOpen(false)
  };

  console.log(open);

  return (
    <div className={`${open ? "block" : "none"} custom-modal`}>
      <div className="custom-modal-content dark:!bg-navy-900">
        <div>
          <span
            className="custom-modal-content-close hover:text-red dark:text-white"
            onClick={() => handleModal()}
          >
            &times;
          </span>
        </div>
        <div className="custom-modal-content-children rounded dark:!bg-navy-700">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
