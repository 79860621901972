import React from "react";

// Admin Imports
import MainDashboard from "views/organiser/default";
import NFTMarketplace from "views/admin/marketplace";

// Icon Imports
import {
  MdHome,
  MdOutlineShoppingCart,
  MdBarChart,
  MdPerson,
  MdLock,
} from "react-icons/md";
import MyEvent from "views/organiser/My-Event";
import MyTicket from "views/organiser/My-Ticket";
import SingleEvent from "views/organiser/My-Event/SingleEvent/singleEvent";
import CreateEvent from "views/organiser/My-Event/components/CreateEvent";

const OrganizerRoutes = [
  {
    name: "Organizer Dashboard",
    layout: "/organiser",
    path: "dashboard",
    icon: <MdHome className="h-6 w-6" />,
    component: <MainDashboard />,
  },
  {
    name: "Marketplace",
    layout: "/organiser",
    path: "marketplace",
    icon: <MdOutlineShoppingCart className="h-6 w-6" />,
    component: <NFTMarketplace />,
    secondary: true,
  },
//   {
//     name: "User",
//     layout: "/admin",
//     path: "userlist",
//     icon: <MdPerson className="h-6 w-6" />,
//     component: <UserList />,
//   },
//   {
//     name: "Organizer",
//     layout: "/admin",
//     path: "organizerlist",
//     icon: <MdPerson className="h-6 w-6" />,
//     component: <OrganizerList />,
//   },
  {
    name: "My Events",
    layout: "/organiser",
    icon: <MdBarChart className="h-6 w-6" />,
    path: "event",
    component: <MyEvent />,
  },
  {
    name: "My Tickets",
    layout: "/organiser",
    path: "ticket",
    icon: <MdPerson className="h-6 w-6" />,
    component: <MyTicket />,
  },
  {
    name: "Sign In /  Sign Out",
    layout: "/organiser",
    path: "sign-in",
    icon: <MdLock className="h-6 w-6" />,
    component: <CreateEvent />,
  },
  {
    name: "event",
    layout: "/organiser",
    path: "event/event/:id",
    icon: <MdHome className="h-6 w-6" />,
    component: <SingleEvent />,
  },
];
export default OrganizerRoutes;
