import React from 'react';

const TermsAndConditions = ({ terms }) => {
  return (
    <div className="terms-container">
      {terms.map((section, index) => (
        <div key={index} className="term-section">
          <h2 className="term-title">{section.section}</h2>
          <p className="term-content">{section.content}</p>
        </div>
      ))}
    </div>
  );
};

export default TermsAndConditions;
