import axios from "axios";
import { API } from "servicerepo/BackedUrl";


// /* All User */
export const AllUser = async (form) => {
    const requestOptions = {
        url: `${API}/user/listUser`,
        method: "get",
        headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${typeof window !== "undefined" &&
            //     JSON.parse(localStorage.getItem("crm_token"))
            //     }`,
        },
    };

    try {
        const res = await axios(requestOptions);
        console.log(res);
        if (res.status === 200) {
            return res.data;
        }
    } catch (error) {
            return error
    }
}
/* User deleting */
export const deleteUser = async (id) => {
    const config = {
        method: "delete",
        url: `${API}/user/deleteUser/${id}`,
        headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${typeof window !== "undefined" &&
            //     JSON.parse(localStorage.getItem("crm_token"))
            //     }`,
        },
    };

    try {
        const res = await axios(config);
        if (res.status === 200) {
            // toast.success("Successfully Deleted Product");
            return res.data;
        }
    } catch (error) {
            return error
    }
}

// /* Fetch Product by id */
export const UserInfo = async (id) => {
    const config = {
        method: "get",
        url: `${API}/user/getUserById/${id}`,
        headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${typeof window !== "undefined" &&
            //     JSON.parse(localStorage.getItem("crm_token"))
            //     }`,
        },
    };

    try {
        const res = await axios(config);
        if (res.status === 200) {
            return res.data;
        }
    } catch (error) {
        return error
        
    }
}

// /* Product Updating */
export const UserUpdate = async (id, form) => {
    const config = {
        method: "patch",
        url: `${API}/user/updateUser/${id}`,
        headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${typeof window !== "undefined" &&
            //     JSON.parse(localStorage.getItem("crm_token"))
            //     }`,
        },
        data: JSON.stringify(form),
    };
    try {
        const res = await axios(config);
        // console.log("response", res)
        if (res.status === 200) {
            return res.data;
        }
    } catch (error) {
            return error
    }
}

