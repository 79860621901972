import Card from 'components/card';
import React from 'react';

const EventListings = ({ events }) => {
  return (
    <div className="grid grid-cols-1  gap-4 md:grid-cols-2 lg:grid-cols-3">
      {events.map((event) => (
        <Card>
        <div key={event.id} className="p-6 relative">
          <h2 className="text-xl font-semibold mb-4">{event.name}</h2>
          <p className="text-sm text-gray-500 mb-4">
            <span className="font-semibold">Date:</span> {event.date}
          </p>
          <p className="text-sm text-gray-500 mb-4">
            <span className="font-semibold">Time:</span> {event.time}
          </p>
          <p className="text-sm text-gray-500 mb-4">
            <span className="font-semibold">Location:</span> {event.location}
          </p>
          <p className="text-sm text-gray-600">{event.description}</p>
          <button className="mt-4 bg-blue-500  text-white py-2 px-4 rounded hover:bg-blue-600 focus:outline-none">
            RSVP
          </button>
        </div>
        </Card>
      ))}
    </div>
  );
};

export default EventListings;
