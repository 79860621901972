export const termsAndConditions = [
    {
      section: "Intellectual Property Rights",
      content: "The content, logos, trademarks, and other materials on this website are the property of the website owner and are protected by applicable copyright and intellectual property laws."
    },
    {
      section: "Use of the Website",
      content: "You may use our website for your personal, non-commercial purposes only. You are not allowed to copy, distribute, or modify any content from the website without explicit written permission from the website owner."
    },
    {
      section: "Registration",
      content: "Some features of our website may require registration. You must provide accurate and complete information during the registration process. You are responsible for maintaining the confidentiality of your account and password, and you agree to accept responsibility for all activities that occur under your account."
    },
    {
      section: "User-Generated Content",
      content: "Users may have the opportunity to post, submit, or otherwise provide content on the website. By doing so, you grant the website owner a non-exclusive, royalty-free, perpetual, and worldwide license to use, reproduce, modify, adapt, publish, translate, and distribute the content for any purpose."
    },
    {
      section: "Prohibited Activities",
      content: "You agree not to engage in any activities that could harm the website, its users, or violate any laws or regulations. This includes but is not limited to uploading or transmitting any viruses, malware, or other harmful code, attempting to gain unauthorized access to the website or its user accounts, and engaging in any fraudulent or deceptive activities."
    },
    {
      section: "Third-Party Links",
      content: "Our website may contain links to third-party websites. These links are provided for your convenience, and we do not endorse or have any control over the content of these external websites. We are not responsible for any damages or losses that may arise from your use of third-party websites."
    },
    {
      section: "Limitation of Liability",
      content: "The website owner and its affiliates shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising out of your use of the website or any content on it."
    },
    {
      section: "Indemnification",
      content: "You agree to indemnify and hold harmless the website owner, its employees, and affiliates from any claims, damages, or liabilities arising from your use of the website or your violation of these terms and conditions."
    },
    {
      section: "Modification of Terms",
      content: "The website owner reserves the right to modify these terms and conditions at any time without prior notice. Your continued use of the website after any changes will constitute your acceptance of the modified terms."
    },
    {
      section: "Governing Law and Jurisdiction",
      content: "These terms and conditions shall be governed by and construed in accordance with the laws of [Your country/state], and any disputes relating to these terms shall be subject to the exclusive jurisdiction of the courts of [Your country/state]."
    },
    {
      section: "Contact",
      content: "If you have any questions or concerns about these terms and conditions, please contact us at [contact email address]."
    },
    {
      section: "Last Updated",
      content: "[Date]"
    }
  ];
  