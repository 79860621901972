import React, { useEffect, useRef, useState } from "react";
import "./main.css";
import "./slider03.css";
import LandingNavbar from "../navbar/LandingNavbar";
import img1 from "./tabs-1-removebg-preview.png";

import { FiPlus } from "react-icons/fi";
import Slider01 from "./slider01";
import { MdPadding } from "react-icons/md";
import { FaArrowRight } from "react-icons/fa";
import Card from "../card";
import { Slider03 } from "./slider03";
import { Faq } from "./faq";
export const Aboutus = () => {
  const [active, setActive] = useState(false);

  const contentRef = useRef(null);


  const toggleAccordion = () => {
    setActive(!active);
  };

  const arr = [
    {
      id: 1,
      question: "What are the services do you offer",
      answer:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates corporis vitae tempora quod provident tenetur culpa dolore facere? Earum, dolor?",
    },
    {
      id: 2,
      question: "what are our preferred method of payment",
      answer:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Architecto iusto veniam eveniet labore impedit nam",
    },
    {
      id: 3,
      question: "Are your services beginners friendly",
      answer:
        "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Praesentium, sed. Dolores,",
    },
    {
      id: 4,
      question: "what how does it take to upgrade a package",
      answer:
        "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Praesentium, sed. Dolores, sequi.",
    },
    {
      id: 5,
      question: "Where are your offices located around the world",
      answer:
        "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Praesentium, sed. Dolores, sequi.",
    },
  ];

  return (
    <div className="aboutus dark:bg-navy-700">
      <LandingNavbar />
    
      <div className="about03 py-5 dark:bg-navy-700 dark:text-white">
        <div className="div01">
          <h1 className="h1">
            Welcome to the Show: <p>Your Gateway to Unforgettable Experiences!</p> 
          </h1>
          <p className="elementor-size-default1">
            <b>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sunt
              ipsum sapiente ipsam obcaecati, suscipit laboriosam.
            </b>
          </p>
          
        </div>
        <div className="div01">
          <img className="img01" src={img1} alt="" />
        </div>
      </div>
      <div
        style={{ marginTop: "7%" }}
        className="text-center dark:text-white"
      >
        <p className="h1 dark:text-white">
          WHAT YOU’LL GET AS A RESULT
          {/* <FaArrowRight className="dark:text-white text-dark"/> */}
        </p>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Id, eius?
        </p>
      </div>
      <div className="about06" style={{ marginBottom: "8%", padding: "5%" }}>
        <div className="  ">
          <Slider01 />
        </div>
      </div>
      <Card>

      <div>
        <div class="wrapper dark:text-white">
          <div style={{position:"relative"}} class="container-2 w-container ">
            <div class="row-2 timeline-title">
              <div class="col-2 sm">
                <h2 className="h1">
                  <span  class="headline-stroke">JUST ONE WEEK FROM <br /> START → TO FINISH</span>
                  
                </h2><br />
              </div>
              <div class="col-2 btntimeline">
                <a href="#contact" class="round w-inline-block">
                  <div
                    data-w-id="7f977c3d-3265-a89d-d163-3436595ccc11"
                    class="round-button tc-gold"
                  >
                    <div class="text-block-2">
                      → Get ←<br />
                      Started
                    </div>
                  </div>
                </a>
              </div>
              <div className="open1">

            <div className="line1" >
              {/* ----------------------------------------------------------------------------------------------------------------------------- */}
            </div>
            <div class="row-2 timeline">
              <div class="timeline-stroke"></div>
              <div class="col-2 lg-9 tc-beige">
                <div class="day">Day 1</div>
                <h3 class="heading">The Baseline</h3>
                <div class="diamond"></div>
                <div class="description">
                  Within 1-2 business days, we’ll&nbsp;reach out to&nbsp;you and
                  schedule a&nbsp;call to&nbsp;discuss the details.
                </div>
              </div>
              
              <div class="col-2 lg-9 tc-beige">
                <div class="day">Day 2</div>
                <h3 class="heading">The Kick-Off</h3>
                <div class="  diamond"></div>
                <div class="description">
                  At&nbsp;this stage, we’ll&nbsp;discuss with you all the
                  details and issues needed to&nbsp;start the concept creating
                  process.
                </div>
              </div>
              
              <div class="col-2 lg-6 tc-beige">
                <div class="day">Day 3 → 6</div>
                <h3 class="heading">The Design</h3>
                <div class="diamond"></div>
                <div class="description-dedign">
                  Considering the required scope of work and your business
                  needs, we&nbsp;develop a&nbsp;quick and scalable design
                  solution.
                </div>
              </div>
              <div class="col-2 lg-9 tc-beige">
                <div class="day">Day 7</div>
                <h3 class="heading">The Rollout</h3>
                <div class="diamond"></div>
                <div class="diamond2"></div>
                <div class="description">
                  We&nbsp;deliver finalized design assets according to&nbsp;the
                  required scope 1 week after the project start.
                </div>
              </div>
            </div>
            </div>
              </div>
            
          </div>
        </div>
      </div>
      <div className=" my-[5%] div02 ">
        <div>
          <img
            className="img02"
            src="https://upload.wikimedia.org/wikipedia/commons/7/7a/Logonetflix.png"
            alt=""
          />
        </div>
        <div>
          <img
            className="img02"
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/1e/Disney%2B_Hotstar_logo.svg/2560px-Disney%2B_Hotstar_logo.svg.png"
            alt=""
          />
        </div>
        <div>
          <img
            className="img02"
            src="https://images.crunchbase.com/image/upload/c_lpad,f_auto,q_auto:eco,dpr_1/zsowgefrcbij3vqhwubp"
            alt=""
          />
        </div>
        <div>
          <img
            className="img02"
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/11/Amazon_Prime_Video_logo.svg/2560px-Amazon_Prime_Video_logo.svg.png"
            alt=""
          />
        </div>
        <div>
          <img
            className="img02"
            src="https://upload.wikimedia.org/wikipedia/commons/0/0f/MX_Player_Logo.png"
            alt=""
          />
        </div>
      </div>
      </Card>
      <div className="my-[2%]">
        <div className="about04 dark:text-white">
          <div className="div01">
            <img
              className="img01"
              src="https://fuse.madrasthemes.com/wp-content/uploads/2022/05/about-1.png"
              alt=""
            />
          </div>
          <div style={{ marginLeft: "6%" }} className="div01">
            <h1 className="h1">
              Lorem, ipsum dolor sit amet consectetur adipisicing{" "}
            </h1>
            <p className="elementor-size-default1">
              <b>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sunt
                ipsum sapiente ipsam obcaecati, suscipit laboriosam.
              </b>
            </p>
            
            <div className="elementor-widget-container">
              <div className="elementor-button-wrapper">
                <span className="elementor-button-content-wrapper">
                  <span className="elementor-button-text">Learn More</span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <Card >

        <Slider03/>
        </Card>
        <div
         
          className=" about04 dark:text-white "
        >
          <div className="div01">
            <h1 className="h1">
              Lorem, ipsum dolor sit amet consectetur adipisicing{" "}
            </h1>
            <p className="elementor-size-default1">
              <b>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sunt
                ipsum sapiente ipsam obcaecati, suscipit laboriosam.
              </b>
            </p>
           
            <div className="elementor-widget-container">
              <div className="elementor-button-wrapper">
                <span className="elementor-button-content-wrapper">
                  <span className="elementor-button-text">Learn More</span>
                </span>
              </div>
            </div>
          </div>
          <div className="div01 my-[10%]">
            <img
              className="img01 "
              src="https://fuse.madrasthemes.com/wp-content/uploads/2022/05/about-2.png"
              alt=""
            />
          </div>
        </div>
      </div>
      {/* <div>
        <div>
          <div className="about05 ">
            <button
              className={`question-section ${active}`}
              onClick={toggleAccordion}
            >
              <div>
                <div className="question-align">
                  <h4 className="question-style dark:text-white">
                    What are the services do you offer
                  </h4>
                  <FiPlus
                    className={
                      active ? `question-icon rotate` : `question-icon`
                    }
                  />
                </div>
                <div
                  ref={contentRef}
                  className={active ? `answer answer-divider` : `answer`}
                >
                  <p className="dark:text-white">
                    Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                    Assumenda deserunt aut dignissimos neque laboriosam nam.
                  </p>
                </div>
              </div>
            </button>
          </div>
        </div>
      </div> */}
      <Card >
        <div className="flex items-center justify-center my-5">
        <Faq/>
        </div>
      </Card>
    </div>
  );
};
export default Aboutus;
