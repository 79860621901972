import nft1 from "assets/img/nfts/NftBanner1.png";
import { Link } from "react-router-dom";

const MarketplaceBanner = (props) => {
  const { backgroundImage, title, description, button1, button2, link1Url, link2Url } =
    props.data;

  console.log(props.data, backgroundImage);
  return (
    <>
          <div
          className="flex w-full flex-col rounded-[20px] bg-cover px-[30px] py-[30px] md:px-[64px] md:py-[56px]"
            style={{ backgroundImage: `url(${backgroundImage})` }}
          >
            <div className="w-full">
              <h4 className="mb-[14px] max-w-full text-xl font-bold text-white md:w-[64%] md:text-3xl md:leading-[42px] lg:w-[46%] xl:w-[85%] 2xl:w-[75%] 3xl:w-[52%]">
               {title} 
               {/* Discover, collect, and sell extraordinary NFTs */}
              </h4>
              <p className="mb-[40px] max-w-full text-base font-medium text-[#E3DAFF] md:w-[64%] lg:w-[40%] xl:w-[72%] 2xl:w-[60%] 3xl:w-[45%]">
               {description}
                {/* Vdvdvdvdv  you guys can go in a bit or do it still not fit and I’m just getting out and I am on a plane 🛩️ 🛫 ✈️ 😂😍😍😒😒😒😒😂😂😂❤️❤️❤️❤️❤️❤️❤️😂😍😍😂☺️💕😏😭😭😏😭😏☺️☺️☺️😳😳😳😭😭😏😭😭😭😭😭💕😭😁😁😁😭😁😁😏😩😏😉💕😏😏😉😁😉😉💕😉😁😉😁👍🏻😁👍👍👍💕👍💕🙈😌🙈😩💕😳👍😳🎶😀😄😆😀☺️😊😙🙃😙🙃😛😘☺️😛😘😝😟😝😞🧐😟😟😤😭🤬😭 */}
              </p>

              <div className="mt-[36px] flex items-center justify-between gap-4 sm:justify-start 2xl:gap-10">
              <Link
                to={link1Url}>
                <button className="text-black linear rounded-xl bg-white px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white/80 active:!bg-white/70">
                 {button1}
                </button>
                </Link>
                <Link
                to={link2Url}>
                <button
                  className="text-base font-medium text-lightPrimary hover:text-lightPrimary 2xl:ml-2"
                >
                  {button2}
                </button>
                </Link>
              </div>
            </div>
          </div>
    </>
  );
};

export default MarketplaceBanner;
