import { Event2 } from 'assets'
import { Event1 } from 'assets'
import LandingNavbar from 'components/navbar/LandingNavbar'
import React from 'react'
import TicketCard from 'views/Landing/SingleEvent/TicketCard'
import ticket1 from "../../assets/ticket/ticket1.png"

export const Ticket = () => {
  return (
    <div className='dark:bg-navy-700 dark:text-white'>
    <LandingNavbar />
      <br /><br /><br /><br /> <br />
     <div style={{}} className=' grid grid-cols-2 w-[15vw] mx-[9%] '>
        <button className='elementor-button-text  mx-2'>Active</button>
        <button className='elementor-button-text mx-2' >Expire</button>
     </div><br />
      <div style={{}} className='grid grid-cols-2 '>
          <div style={{width:"45vw",borderRadius:15,height:"fit-content"}} className='border-2 p-[4%]  mx-[9%] my-[5%]'>
              <img className='' src={Event1} alt="" />
          </div>
          <div style={{display:"flex",flexDirection:"column"}} >
            <div className='mx-[25%] my-[3%]' style={{width:"30vw",position:"relative",cursor:"pointer"}}>  
            <img style={{borderRadius:"20px"}} src={ticket1} alt="" />
            <div style={{position:"absolute",top:"25%",width:"23vw",textAlign:"center",fontFamily:"serif,italic"}}>
              {/* <p style={{color:"black",fontSize:"27px"}}>- - - - - - - - - - - - - - - - - - -  - - -</p> */}
                <p style={{color:"#5760fd",fontSize:"50px"}}>$56</p>
                
                <p style={{color:"black",fontSize:"30px"}}>Dance Event</p>  
                <p style={{color:"black"}}>at rambagh circle rosado club Jaipur</p>
                
                <p className='my-1' style={{color:"black",fontSize:"25px"}}>25 Aug 2023</p>
                <p  style={{color:"black"}}>Time: 9am to 6pm</p>
                <p className='my-2'  style={{color:"black",fontSize:"25px"}}>SEAT: 156</p>
                <p style={{marginLeft:"38%"}} className="barcode"></p>
              
                  
            </div>
              {/* <TicketCard/> */}
            </div>
            <br /><br />
            {/* <div style={{fontSize:"20px"}} className='mx-4 my-4'>

            <div className='grid grid-cols-2 w-[20vw] '>
          <p>Status :</p>
          <p style={{color:"green"}} className=''> Active</p>
            </div>
            <div className='grid grid-cols-2 w-[20vw] '>
          <p>Date of Booking  :</p>
          <p style={{}} className=''>10 Sep. 2023</p>
            </div>
            <div className='grid grid-cols-2 w-[20vw] '>
          <p>Date of Expire :</p>
          <p style={{}} className=''> 20 Oct. 2023</p>
            </div>
            </div> */}
          </div>
      </div>
      <div style={{}} className='grid grid-cols-2 '>
      <div style={{width:"45vw",borderRadius:15,height:"fit-content"}} className='border-2 p-[4%]  mx-[9%] my-[5%]'>
              <img style={{height:"57vh"}} className='' src={Event1} alt="" />
          </div>
          <div className='mx-[17%]' style={{display:"flex",flexDirection:"column"}} >
            <div style={{width:"30vw"}}>  
              <TicketCard/>
            </div>
            <br /><br />
            <div style={{fontSize:"20px"}} className='mx-4 my-4'>

            <div className='grid grid-cols-2 w-[20vw] '>
          <p>Status :</p>
          <p style={{color:"red"}} className=''> Expire</p>
            </div>
            <div className='grid grid-cols-2 w-[20vw] '>
          <p>Date of Booking  :</p>
          <p style={{}} className=''>10 Sep. 2023</p>
            </div>
            <div className='grid grid-cols-2 w-[20vw] '>
          <p>Date of Expire :</p>
          <p style={{}} className=''> 20 Oct. 2023</p>
            </div>
            </div>
          </div>
      </div>
  
  </div>
  )
}
