import axios from "axios";
import { API } from "servicerepo/BackedUrl";

export const Login = async (form) => {
    const config = {
        method: "post",
        url: `${API}/user/loginUser`,
        headers: {
            "Content-Type": "application/json",
        },
        data: JSON.stringify(form),
    };

    try {
        const res = await axios(config)
        if (res.status === 200) {
            return res.data;
        }
    } catch (error) {
       return error  
    }
}

export const Signup = async (form) => {
    
    const config = {
        method: "post",
        url: `${API}/user/createUser`,
        headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${typeof window !== "undefined" &&
            //     JSON.parse(localStorage.getItem("crm_token"))
            //     }`,
        },
        data: JSON.stringify(form),
    };

    try {
        const res = await axios(config);
            return res.data;
    } catch (error) {
            return error
    }
}
// /* Save Product */
// export const addProduct = async (form) => {
//     // console.log(form);

//     const requestOptions = {
//         url: `${API}/product/add-product`,
//         method: "POST",
//         headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${typeof window !== "undefined" &&
//                 JSON.parse(localStorage.getItem("crm_token"))
//                 }`,
//         },
//         data: JSON.stringify(form),
//     };

//     try {
//         const res = await axios(requestOptions);
//         // console.log("response in product", res)
//         if (res.status === 200) {
//             return res.data;
//         }
//     } catch (error) {
//         if(error.response.status === 400){
//             return error
//         }
//     }
// }

// /* Fetch Product by id */
// export const ProductInfobyId = async (id) => {
//     const config = {
//         method: "get",
//         url: `${API}/product/get-product/${id}`,
//         headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${typeof window !== "undefined" &&
//                 JSON.parse(localStorage.getItem("crm_token"))
//                 }`,
//         },
//     };

//     try {
//         const res = await axios(config);
//         // console.log("response in product", res)
//         if (res.status === 200) {
//             return res.data;
//         }
//     } catch (error) {
//         if(error.response.status === 400){
//             toast.error("Something went wrong. Please try again !")
//         }
//     }
// }

// /* Product Updating */
// export const productUpdate = async (id, form) => {
//     const config = {
//         method: "put",
//         url: `${API}/product/update-product/${id}`,
//         headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${typeof window !== "undefined" &&
//                 JSON.parse(localStorage.getItem("crm_token"))
//                 }`,
//         },
//         data: JSON.stringify(form),
//     };

//     try {
//         const res = await axios(config);
//         // console.log("response", res)
//         if (res.status === 200) {
//             return res.data;
//         }
//     } catch (error) {
//         if(error.response.status === 400){
//             return error
//         }
//     }
// }

