import React, { useEffect, useState } from 'react';
import { BsCheckLg } from 'react-icons/bs';
// import { RxCross2 } from 'react-icons/rx';
import { Link } from 'react-router-dom';
import Card from "react-animated-3d-card";
import { AiFillCheckCircle } from 'react-icons/ai';
import { MdCancel } from 'react-icons/md';
import { background } from '@chakra-ui/system';


const Membership = () => {
    const [showFront, setShowFront] = useState("Max Verstappen");
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const toggle = true;

    const arr = [
        {
            id: 1,
            price: "$20.00",
            title: "Basic Pack",
            status: <AiFillCheckCircle/> ,
            status2: <MdCancel/> ,
        },
        {
            id: 2,
            price: "$30.00",
            title: "Standard Pack",
            status: <AiFillCheckCircle/>,
            status2: <MdCancel/> 
        },
        {
            id: 3,
            price: "$40.00",
            title: "Ultimate Pack",
            status: <AiFillCheckCircle/>,
            status2: <MdCancel/> 
        },
    ];

    const dummyFeatures = [" Users profile", "Task Timeline", "Corps", "Daily reporting", "HRMS", "CRM", "Kanban Board"];

    return (
        <>
                <div className="container my-5 pb-5">
                    <div className=""></div>
                    <div className=" text-center">
                        <span className="block-title__bubbles"></span>
                        <p className='paragraph'></p>
                        <h2 className='dark:text-white' style={{fontSize:"40px"}}>Choose Membership Plans</h2>
                    </div>
                    <div style={{display:"flex",flexDirection:"row",justifyContent:"center"}} className='my-[5%]'>

                   {arr.map((data)=>(
                    <div style={{cursor:"pointer"}} className='m-5 text-center dark:text-white'>

                    <Card
        cursorPointer={false}
        shineStrength={0.75}
        style={{
            background: "rgba(72, 111, 241, 0.1)",
            borderRadius: "16px",
            boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
            backdropFilter: "blur(5px)",
            webkitBackdropFilter: "blur(5px)",
            border: "1px solid rgba(72, 111, 241, 0.3)",
            width:"22vw",
            height:"500px"
          
        }}
        onClick={() => setShowFront("Lewis Hamilton")}
      >
        <div>
         <p style={{marginTop:"15%",fontSize:"40px"}} >{data.price}</p>
         <p className='dark:text-' style={{marginTop:"1%",fontSize:"20px",color:"#897216"}} >{data.title}</p><br />
         <p style={{display: "flex", justifyContent: "start", alignItems: "center", margin:"3% 8%", fontSize:"20px",marginLeft:"20%"}} ><span style={{marginRight: "25px"}}>{data.status}</span> Free Event Cloths</p>
         <p style={{display: "flex", justifyContent: "start", alignItems: "center", margin:"3% 8%", fontSize:"20px",marginLeft:"20%"}} ><span style={{marginRight: "25px"}}>{data.status}</span> Discount on Tickets</p>
         <p style={{display: "flex", justifyContent: "start", alignItems: "center", margin:"3% 8%", fontSize:"20px",marginLeft:"20%"}} ><span style={{marginRight: "25px"}}>{data.status}</span>VIP tickets</p>
         <p style={{display: "flex", justifyContent: "start", alignItems: "center", margin:"3% 8%", fontSize:"20px",marginLeft:"20%"}} ><span style={{marginRight: "25px"}}>{data.status}</span>Discount On Food</p>
         <p style={{display: "flex", justifyContent: "start", alignItems: "center", margin:"3% 8%", fontSize:"20px",marginLeft:"20%"}} ><span style={{marginRight: "25px"}}>{data.status}</span> Discount on Tickets</p>
       {/* <br /> <button className='bt5'>Start Know </button> */}
        </div>
       
       
        

       
      </Card>
                    </div>

                   ))}
                    </div>
                </div><br /><br /><br /><br /><br />
                <br /><br /><br /><br /><br />
                <br /><br /><br /><br /><br />
        </>
    )
}

export default Membership;