import React, { useState } from "react";
import "./modal.css";
import { Signup } from "Hooks/Auth";
import Checkbox from "components/checkbox";
import { Login } from "Hooks/Auth";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";

const SignupModal = (props) => {
  const { open, setOpen } = props;
  const [form, setForm] = useState({});
  const [activeTab, setActiveTab] = useState("signin");

  const handleChange = (e) => {
    if (e.target.name === "phone") {
      e.target.value = e.target.value.slice(0, 10);
      setForm({
        ...form,
        [e.target.name]: e.target.value,
      });
    }
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };


  const handleModal = () => {
    setOpen(false);
  };

  const Navigate = useNavigate();

  console.log(open);

  const Submit = () => {
    console.log(form);
    Signup(form).then((res) => {
      console.log(res);
    });
  };

  const LoginSubmit = () => {
    Login(form).then((res) => {
      if (res.success === true) {
        localStorage.setItem("superShow_token", JSON.stringify(res.token))
        var decoded = jwt_decode(res.token);
        handleModal();
        if (decoded.role === "user") {
          window.location.href = "/user/dashboard";
        }
        if (decoded.role === "organiser") {
          window.location.href = "/organiser/dashboard";
        }
        if (decoded.role === "master") {
          window.location.href = "/admin/dashboard";
        }
      }
    });
  };
  return (
    <div className={`${open ? "block" : "none"} custom-modal`}>
      <div className="custom-modal-content dark:!bg-navy-900">
        <div>
          <span
            className="custom-modal-content-close hover:text-red dark:text-white"
            onClick={() => handleModal()}
          >
            &times;
          </span>
        </div>
        <div className="custom-modal-content-children rounded dark:!bg-navy-700">
          <div className="w-100 align-center flex justify-center">

            <div
              onClick={() => setActiveTab("signin")}
              className={`${activeTab === "signin"
                ? "rounded bg-navy-200 dark:bg-[#0011ffb0]"
                : ""
                } py-2 px-[5rem] uppercase font-medium dark:text-white`}
              style={{ cursor: "pointer" }}
            >
              Sign in
            </div>
            <div
              onClick={() => setActiveTab("signup")}
              className={`${activeTab === "signup"
                ? "rounded bg-navy-200 dark:bg-[#0011ffb0]"
                : ""
                } py-2  px-[5rem] uppercase font-medium dark:text-white`}
              style={{ cursor: "pointer" }}
            >
              Sign up
            </div>
          </div>
          <div className="mt-3 flex items-center  gap-3">
            <div className="h-px w-full bg-gray-200   dark:bg-white-700" />
            <p className="text-base text-gray-600 dark:text-white"> or </p>
            <div className="h-px w-full bg-gray-200 dark:bg-white-700" />
          </div>
          {activeTab === "signin" && (
            <div className="">
              <div className="mb-3">
                <label
                  htmlFor="email"
                  className={`"ml-1.5 font-medium" : 
           "ml-3 font-bold" text-sm text-navy-700 dark:text-white
        `}
                >
                  Email*
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  onChange={handleChange}
                  autoComplete="nope"
                  placeholder="mail@simmmple.com"
                  className={`mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none `}
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="password"
                  className={`"ml-1.5 font-medium" : 
           "ml-3 font-bold" text-sm text-navy-700 dark:text-white
        `}
                >
                  Password*
                </label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  onChange={handleChange}
                  autoComplete="nope"
                  placeholder="Password"
                  className={`mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none `}
                />
              </div>
              <div className="mb-4 flex items-center justify-between px-2">
                <div className="flex items-center">
                  <Checkbox />
                  <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
                    Keep me logged In
                  </p>
                </div>
                <a
                  className="text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
                  href=" "
                >
                  Forgot Password?
                </a>
              </div>
              <button
                onClick={LoginSubmit}
                className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
              >
                Sign in
              </button>
              {/* <div className="mt-4">
                <span className=" text-sm font-medium text-navy-700 dark:text-gray-600">
                  already have an account?
                </span>
                <span
                  onClick={() => setActiveTab("signup")}
                  style={{ cursor: "pointer" }}
                  className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
                >
                  Sign up
                </span>
              </div> */}
            </div>
          )}
          {activeTab === "signup" && (
            <div className="">
              <div className="item-center justify-between sm:block xl:flex lg:flex w-100">
                <div className="my-3 mr-3 w-[100%]">
                  <label
                    htmlFor="fname"
                    className={`"ml-1.5 font-medium" : 
           "ml-3 font-bold" text-sm text-navy-700 dark:text-white
        `}
                  >
                    First Name
                  </label>
                  <input
                    type="text"
                    id="fname"
                    name="fname"
                    onChange={handleChange}
                    autoComplete="nope"
                    placeholder="First Name"
                    className={`mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none `}
                  />
                </div>
                <div className="my-3 w-[100%]">
                  <label
                    htmlFor="lname"
                    className={`"ml-1.5 font-medium" : 
           "ml-3 font-bold" text-sm text-navy-700 dark:text-white
        `}
                  >
                    Last Name
                  </label>
                  <input
                    type="text"
                    id="lname"
                    name="lname"
                    onChange={handleChange}
                    autoComplete="nope"
                    placeholder="Last Name"
                    className={`mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none `}
                  />
                </div>
              </div>
              <div className="item-center justify-between sm:block xl:flex lg:flex w-100">
                <div className="my-3 mr-3 w-[100%]">
                  <label
                    htmlFor="email"
                    className={`"ml-1.5 font-medium" : 
           "ml-3 font-bold" text-sm text-navy-700 dark:text-white
        `}
                  >
                    Email*
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    onChange={handleChange}
                    autoComplete="nope"
                    placeholder="mail@simmmple.com"
                    className={`mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none `}
                  />
                </div>
                <div className="my-3 w-[100%]">
                  <label
                    htmlFor="password"
                    className={`"ml-1.5 font-medium" : 
           "ml-3 font-bold" text-sm text-navy-700 dark:text-white
        `}
                  >
                    Password*
                  </label>
                  <input
                    type="password"
                    id="password"
                    name="password"
                    onChange={handleChange}
                    autoComplete="nope"
                    placeholder="Password"
                    className={`mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none `}
                  />
                </div>
              </div>
              <div className="mb-3">
                <label
                  htmlFor="phone"
                  className={`"ml-1.5 font-medium" : 
           "ml-3 font-bold" text-sm text-navy-700 dark:text-white
        `}
                >
                  Phone  Number
                </label>
                <input
                  type="number"
                  id="phone"
                  name="phone"
                  onChange={handleChange}
                  autoComplete="nope"
                  placeholder="Phone Number"
                  className={`mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none `}
                />
              </div>

              {/* <di */}
              <button
                onClick={Submit}
                className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
              >
                Sign up
              </button>
              {/* <div className="mt-4">
                <span className=" text-sm font-medium text-navy-700 dark:text-gray-600">
                  already have an account?
                </span>
                <span
                  onClick={() => setActiveTab("signin")}
                  style={{ cursor: "pointer" }}
                  className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
                >
                  Sign in
                </span>
              </div> */}
            </div>
          )}


        </div>
      </div>
    </div>
  );
};

export default SignupModal;
