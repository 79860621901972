import React, { useState } from 'react';

const CommunityEngagement = () => {
  const [polls, setPolls] = useState([
    {
      id: 1,
      question: 'Which type of events do you prefer?',
      options: ['Conferences', 'Workshops', 'Webinars', 'Networking Events'],
      votes: [12, 8, 5, 11],
    },
    // Add more polls as needed
  ]);

  const handleVote = (pollId, optionIndex) => {
    setPolls((prevPolls) =>
      prevPolls.map((poll) =>
        poll.id === pollId
          ? {
              ...poll,
              votes: poll.votes.map((count, index) =>
                index === optionIndex ? count + 1 : count
              ),
            }
          : poll
      )
    );
  };

  return (
    <div className="bg-white rounded-lg shadow-md p-6 mt-5">
      <h2 className="text-xl font-semibold mb-4">Community Engagement</h2>

      {polls.map((poll) => (
        <div key={poll.id} className="mb-4">
          <p className="font-semibold mb-2">{poll.question}</p>
          <div className="flex flex-col space-y-2">
            {poll.options.map((option, index) => (
              <div
                key={index}
                className="flex items-center justify-between border rounded-lg p-2 cursor-pointer transition-colors duration-200 hover:bg-blue-100"
                onClick={() => handleVote(poll.id, index)}
              >
                <span>{option}</span>
                <span className="font-bold text-blue-500">
                  {poll.votes[index]}
                </span>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default CommunityEngagement;
