import { Signup } from 'Hooks/Auth'
import { Eventcreate } from 'Hooks/EventHook';
import Card from 'components/card'
import React, { useState } from 'react'

const CreateEvent = () => {
    const [form, setForm] = useState({ role: "organiser" });
    const [address, setAddress] = useState({  });



    const handleChange = (e) => {
        if (e.target.name === "phone") {
            e.target.value = e.target.value.slice(0, 10);
            setForm({
                ...form,
                [e.target.name]: e.target.value,
            });
        }
        setForm({
            ...form,
            [e.target.name]: e.target.value,
        });
    };
    const handleAddress = (e) => {
        setAddress({
            ...address,
            [e.target.name]: e.target.value,
        });
        setForm({
            ...form,
            eventVenue : address,
        });
    };
    console.log("form  : ", form);
    const Submit = () => {
        console.log(form);
        Eventcreate(form).then((res) => {
            console.log(res);
        });
    };
    return (
        <div>
            <div className="mt-5 grid h-full">
                <Card extra={"w-full h-full p-4"}>
                    <div className="relative flex items-center justify-between">
                        <div className="text-xl font-bold text-navy-700 dark:text-white">
                            Create Event
                        </div>
                    </div>

                    <div className="flex flex-col items-center	 ">
                        <div className="mb-3 lg:w-[60%]  items-center">
                            <label
                                htmlFor="eventName"
                                className={`"ml-1.5 font-medium" : 
 "ml-3 font-bold" text-sm text-navy-700 dark:text-white
`}
                            >
                                Event Name
                            </label>
                            <input
                                type="text"
                                id="eventName"
                                name="eventName"
                                onChange={handleChange}
                                autoComplete="nope"
                                placeholder="Event Name"
                                className={`mt-2 flex h-12 w-full  items-center dark:text-white justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none `}
                            />
                        </div>

                        <div className="mb-3 lg:w-[60%]  items-center">
                            <label
                                htmlFor="eventDate"
                                className={`"ml-1.5 font-medium" : 
 "ml-3 font-bold" text-sm text-navy-700 dark:text-white
`}
                            >
                                Event Date
                            </label>
                            <input
                                type="date"
                                id="eventDate"
                                name="eventDate"
                                onChange={handleChange}
                                autoComplete="nope"
                                placeholder="Event Date"
                                className={`mt-2 flex h-12 w-full  dark:text-white items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none `}
                            />
                        </div>
                        <div className="mb-3 lg:w-[60%]  items-center">
                            <label
                                htmlFor="noOfTicket"
                                className={`"ml-1.5 font-medium" : 
 "ml-3 font-bold" text-sm text-navy-700 dark:text-white
`}
                            >
                                Number of Tickets
                            </label>
                            <input
                                type="number"
                                id="noOfTicket"
                                name="noOfTicket"
                                onChange={handleChange}
                                autoComplete="nope"
                                placeholder="Number Of Ticket"
                                className={`mt-2 flex h-12 w-full  dark:text-white items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none `}
                            />
                        </div>
                        <div className="mb-3 lg:w-[60%]  items-center">
                            <label
                                htmlFor="addline"
                                className={`"ml-1.5 font-medium" : 
 "ml-3 font-bold" text-sm text-navy-700 dark:text-white
`}
                            >
                                Add Line
                            </label>
                            <input
                                type="text"
                                id="addline"
                                name="addline"
                                onChange={handleAddress}
                                autoComplete="nope"
                                placeholder="Add Line"
                                className={`mt-2 flex h-12 w-full  dark:text-white items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none `}
                            />
                        </div>
                        <div className="mb-3 lg:w-[60%]  items-center">
                            <label
                                htmlFor="country"
                                className={`"ml-1.5 font-medium" : 
 "ml-3 font-bold" text-sm text-navy-700 dark:text-white
`}
                            >
                                Country
                            </label>
                            <input
                                type="text"
                                id="country"
                                name="country"
                                onChange={handleAddress}
                                autoComplete="nope"
                                placeholder="Country"
                                className={`mt-2 flex h-12 w-full  dark:text-white items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none `}
                            />
                        </div>
                       
                        
                        <div className="mb-3 lg:w-[60%]  items-center">
                            <label
                                htmlFor="state"
                                className={`"ml-1.5 font-medium" : 
 "ml-3 font-bold" text-sm text-navy-700 dark:text-white
`}
                            >
                                State
                            </label>
                            <input
                                type="text"
                                id="state"
                                name="state"
                                onChange={handleAddress}
                                autoComplete="nope"
                                placeholder="State"
                                className={`mt-2 flex h-12 w-full  dark:text-white items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none `}
                            />
                        </div>
                        <div className="mb-3 lg:w-[60%]  items-center">
                            <label
                                htmlFor="city"
                                className={`"ml-1.5 font-medium" : 
 "ml-3 font-bold" text-sm text-navy-700 dark:text-white
`}
                            >
                                City
                            </label>
                            <input
                                type="text"
                                id="city"
                                name="city"
                                onChange={handleAddress}
                                autoComplete="nope"
                                placeholder="City"
                                className={`mt-2 flex h-12 w-full  dark:text-white items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none `}
                            />
                        </div>
                        <div className="mb-3 lg:w-[60%]  items-center">
                            <label
                                htmlFor="pincode"
                                className={`"ml-1.5 font-medium" : 
 "ml-3 font-bold" text-sm text-navy-700 dark:text-white
`}
                            >
                                Pin Code
                            </label>
                            <input
                                type="number"
                                id="pincode"
                                name="pincode"
                                onChange={handleAddress}
                                autoComplete="nope"
                                placeholder="Pin Code"
                                className={`mt-2 flex h-12 w-full  dark:text-white items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none `}
                            />
                        </div>
                        <div className="mb-3 lg:w-[60%]  items-center">
                            <label
                                htmlFor="eventOnHold"
                                className={`"ml-1.5 font-medium" : 
 "ml-3 font-bold" text-sm text-navy-700 dark:text-white
`}
                            >
                                Event on Hold
                            </label>
                            <input
                                type="text"
                                id="eventOnHold"
                                name="eventOnHold"
                                onChange={handleChange}
                                autoComplete="nope"
                                placeholder="eventOnHold"
                                className={`mt-2 flex h-12 w-full  dark:text-white items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none `}
                            />
                        </div>
                        <button
                            onClick={Submit}
                            className="linear mt-2 w-full lg:w-[30%] rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                        >
                            Create Event
                        </button>
                    </div>
                </Card>
            </div>
        </div>
    )
}

export default CreateEvent
