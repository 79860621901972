import React, { useState } from 'react';
import { eventsData } from 'servicerepo/LandingPage/EventData';
import EventListings from './EventListings';
import UserContributions from './UserContribution';
import EventDiscussions from './EventDiscuss';
import CommunityEngagement from './CommunityEngagement';
import SocialMediaIntegration from './SocialMediaIIntegration';
import EventDetails from './EventDetails';
import NewsletterSignup from './NewLetterSignup';

const CommunityPage = () => {
  const [events, setEvents] = useState(eventsData);
  const [selectedEvent, setSelectedEvent] = useState(null);

  const handleEventClick = (eventId) => {
    const selected = events.find((event) => event.id === eventId);
    setSelectedEvent(selected);
  };

  return (
    <div className="container mx-auto py-8">
      <h1 className="text-4xl font-bold mb-8 text-center dark:text-white">Community Events</h1>
      {!selectedEvent ? (
        <>
          <EventListings events={events} onItemClick={handleEventClick} />
          <UserContributions />
          <EventDiscussions />
          <CommunityEngagement />
          <SocialMediaIntegration />
        </>
      ) : (
        <EventDetails event={selectedEvent} />
      )}
      <NewsletterSignup />
    </div>
  );
};

export default CommunityPage;
