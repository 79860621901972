import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import AdminLayout from "layouts/admin";
import Landing from "views/Landing/landing";
import OrganizerLayout from "layouts/organizer";
import jwt_decode from "jwt-decode";
import UserLayout from "layouts/user";
import SingleEvent from "views/Landing/SingleEvent";
import Aboutus from "components/Aboutus/aboutus";
import { Organizer } from "components/orginizer/organizer";
import { Transction } from "components/transction/transction";
import { Ticket } from "components/ticket/ticket";
import { Wishlist } from "components/Wishlist/wishlist";

const App = () => {
  const [access, setAccess] = useState(null);
  useEffect(() => {
    TokenAccess();
  }, []);

  const TokenAccess = () => {
    const token = localStorage.getItem("superShow_token");
    if (token !== null) {
      var decoded = jwt_decode(token);
      setAccess(decoded.role);
    }
  };
  
  return (
    <Routes>
      {access === "master" && (
        <>
          <Route path="admin/*" element={<AdminLayout />} />
          <Route
            path="/"
            element={<Navigate to="/admin/dashboard" replace />}
          />
        </>
      )}

      {access === "organiser" && (
        <>
          <Route path="organiser/*" element={<OrganizerLayout />} />
          <Route
            path="/"
            element={<Navigate to="/organiser/dashboard" replace />}
          />
        </>
      )}

      {access === "user" && (
        <>
          <Route path="user/*" element={<UserLayout />} />
          <Route path="/" element={<Navigate to="/user/dashboard" replace />} />
        </>
      )}
      {access === null && (
        <>
          <Route path="*" element={<Navigate to="/" replace />} />
          <Route path="/singleEvent/:id" element={<SingleEvent />} />
          <Route path="/" element={<Landing />} />
          <Route path="/Aboutus" element={<Aboutus />} />
          <Route path="/Organizer" element={<Organizer />} />
          <Route path="/Transction" element={<Transction />} />
          <Route path="/Ticket" element={<Ticket />} />
          <Route path="/Wishlist" element={<Wishlist />} />
        </>
      )}
    </Routes>
  );
};

export default App;
