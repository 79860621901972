export const eventsData = [
    {
      id: 1,
      name: 'Conference on AI and Machine Learning',
      date: '2023-08-15',
      time: '10:00 AM',
      location: 'Virtual Event',
      description: 'Join us for an exciting conference on AI and Machine Learning. Explore the latest advancements in the field and learn from industry experts.',
    },
    {
      id: 2,
      name: 'Workshop on Web Development',
      date: '2023-08-18',
      time: '2:30 PM',
      location: 'Online Workshop',
      description: 'Get hands-on experience in web development during this interactive workshop. Build your own portfolio website and learn valuable skills.',
    },
    {
      id: 3,
      name: 'Networking Mixer for Tech Professionals',
      date: '2023-08-20',
      time: '6:00 PM',
      location: 'City Tech Hub',
      description: 'Connect with fellow tech professionals, entrepreneurs, and industry leaders at our networking mixer. Build new connections and discover collaboration opportunities.',
    },
    {
      id: 4,
      name: 'Hackathon 2023',
      date: '2023-08-25',
      time: '9:00 AM',
      location: 'Innovative Tech Center',
      description: 'Participate in a 24-hour hackathon and showcase your coding skills. Collaborate with teams to build innovative solutions to real-world challenges.',
    },
    {
      id: 5,
      name: 'Panel Discussion: Future of Blockchain',
      date: '2023-08-28',
      time: '3:00 PM',
      location: 'Blockchain Center',
      description: 'Join us for an insightful panel discussion on the future of blockchain technology. Learn from experts and gain valuable insights into the industry trends.',
    },
    {
      id: 6,
      name: 'Design Thinking Workshop',
      date: '2023-09-02',
      time: '11:30 AM',
      location: 'Creative Design Studio',
      description: 'Discover the power of design thinking and its applications in problem-solving. Work on real-world challenges and unleash your creativity.',
    },
    {
      id: 7,
      name: 'Startup Pitch Night',
      date: '2023-09-05',
      time: '7:00 PM',
      location: 'Entrepreneurship Hub',
      description: 'Watch aspiring entrepreneurs pitch their innovative startup ideas to a panel of investors and mentors. Be part of the vibrant startup ecosystem.',
    },
    {
      id: 8,
      name: 'Data Science Bootcamp',
      date: '2023-09-10',
      time: '10:00 AM',
      location: 'Data Analytics Institute',
      description: 'Embark on a comprehensive data science journey. Learn data analysis, machine learning, and data visualization from industry professionals.',
    },
    {
      id: 9,
      name: 'Women in Tech Conference',
      date: '2023-09-15',
      time: '9:30 AM',
      location: 'Women Empowerment Center',
      description: 'Celebrate and empower women in the tech industry. Attend inspiring talks, workshops, and networking sessions focused on women in tech.',
    },
    {
      id: 10,
      name: 'Digital Marketing Masterclass',
      date: '2023-09-20',
      time: '1:00 PM',
      location: 'Digital Marketing Hub',
      description: 'Enhance your digital marketing skills with expert-led masterclasses. Learn the latest strategies and techniques to drive online growth.',
    },
  ];
  