import React, { useState } from 'react';

const UserContributions = () => {
  const [contributions, setContributions] = useState([]);
  const [newContribution, setNewContribution] = useState('');

  const handleInputChange = (event) => {
    setNewContribution(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (newContribution.trim() === '') return;

    const contribution = {
      id: contributions.length + 1,
      content: newContribution,
      user: 'John Doe', // You can replace this with the actual logged-in user's name
    };

    setContributions([...contributions, contribution]);
    setNewContribution('');
  };

  return (
    <div className="bg-white rounded-lg shadow-md p-6 mt-5">
      <h2 className="text-xl font-semibold mb-4">User Contributions</h2>

      <form onSubmit={handleSubmit} className="mb-4">
        <textarea
          className="w-full p-2 rounded border focus:outline-none focus:ring focus:border-blue-300"
          rows="4"
          placeholder="Share your event experience or submit an event..."
          value={newContribution}
          onChange={handleInputChange}
        ></textarea>
        <button
          type="submit"
          className="mt-2 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 focus:outline-none"
        >
          Submit
        </button>
      </form>

      {contributions.length === 0 ? (
        <p className="text-gray-500">No contributions yet.</p>
      ) : (
        <div className="mt-4">
          {contributions.map((contribution) => (
            <div key={contribution.id} className="bg-gray-100 p-2 mb-2 rounded-lg">
              <p className="text-gray-700">{contribution.content}</p>
              <p className="text-sm text-gray-500">By {contribution.user}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default UserContributions;
