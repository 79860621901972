import React from "react";

export const Privacy = () => {
  return <div class="mt-10 divide-y divide-gray-200">
    <div>
                  <div class="space-y-1">
                    <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-white ">Update Password</h3>
                    <p class="max-w-2xl text-sm text-gray-700">This information is important so be careful what you change</p>
                  </div>
                  <div class="mt-6">
                    <dl class="divide-y divide-gray-200">
                      <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt class="text-sm font-medium text-gray-700">Old Password</dt>
                        <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          <input class="dark:bg-navy-800 flex-grow dark:text-blue-600 " type="password" />
                        </dd>
                      </div>
                     
                      <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5">
                        <dt class="text-sm font-medium text-gray-700">New Password</dt>
                        <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <input class="dark:bg-navy-800 flex-grow dark:text-blue-600 " type="password" />
                        </dd>
                      </div>
                      <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-b sm:border-gray-200">
 
                          <span class="flex-shrink-0">
                            <button type="button" class="p-3 bg-blue-600 rounded-md font-medium text-white hover:bg-white hover:text-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">Update</button>
                          </span>
                        
                      </div>
                    </dl>
                  </div>
                </div>
  </div>;
};
