import React, { useState } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LandingCard from "components/card/LandingCard";
import { Event1 } from "assets";
import { Event2 } from "assets";
import { Landing_Video } from "assets";
const EventTabs = () => {
    const [tab, setTab] = useState('All')
    const Tabs = [
        { event: "All" },
        { event: "Featured" },
        { event: "Most Popular" },
        { event: "Recently Added" },
    ]
    const settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 1550,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
                infinite: true,
              },
            },
            {
              breakpoint: 1214,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
              },
            },
            {
              breakpoint: 1007,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
              },
            },
            {
              breakpoint: 516,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
              },
            },
          ],
    };

    const Arr1 = [
        {
            ind: 1,
            asset:
                Event1,
            assetType: "img",
            title1: "Ultimate survival game on the blockchain",
            title2: "Play-to-Earn",
            desc: "Explore the post-apocalyptic metaverse,survive and earn crypto while having fun",
        },
        {
            ind: 2,
            asset:
                Event2,
            assetType: "img",
            title1: "Ultimate survival game on the blockchain",
            title2: "Play-to-Earn",
            desc: "Explore the post-apocalyptic metaverse,survive and earn crypto while having fun",
        },
        {
            ind: 3,
            title1: "Ultimate survival game on the blockchain",
            title2: "Play-to-Earn",
            asset:
                Landing_Video,
            assetType: "video",
            desc: "Explore the post-apocalyptic metaverse,survive and earn crypto while having fun",
        },
        {
            ind: 4,
            asset:
                Event2,
            assetType: "img",
            title1: "Ultimate survival game on the blockchain",
            title2: "Play-to-Earn",
            desc: "Explore the post-apocalyptic metaverse,survive and earn crypto while having fun",
        },
        {
            ind: 1,
            asset:
                Event1,
            assetType: "img",
            title1: "Ultimate survival game on the blockchain",
            title2: "Play-to-Earn",
            desc: "Explore the post-apocalyptic metaverse,survive and earn crypto while having fun",
        },
        {
            ind: 2,
            asset:
                Event2,
            assetType: "img",
            title1: "Ultimate survival game on the blockchain",
            title2: "Play-to-Earn",
            desc: "Explore the post-apocalyptic metaverse,survive and earn crypto while having fun",
        },
        {
            ind: 3,
            title1: "Ultimate survival game on the blockchain",
            title2: "Play-to-Earn",
            asset:
                Landing_Video,
            assetType: "video",
            desc: "Explore the post-apocalyptic metaverse,survive and earn crypto while having fun",
        },
        {
            ind: 4,
            asset:
                Event2,
            assetType: "img",
            title1: "Ultimate survival game on the blockchain",
            title2: "Play-to-Earn",
            desc: "Explore the post-apocalyptic metaverse,survive and earn crypto while having fun",
        },
    ];
    const Arr2 = [
        {
            ind: 1,
            asset:
                Event1,
            assetType: "img",
            title1: "Ultimate survival game on the blockchain",
            title2: "Play-to-Earn",
            desc: "Explore the post-apocalyptic metaverse,survive and earn crypto while having fun",
        },
        {
            ind: 2,
            asset:
                Event2,
            assetType: "img",
            title1: "Ultimate survival game on the blockchain",
            title2: "Play-to-Earn",
            desc: "Explore the post-apocalyptic metaverse,survive and earn crypto while having fun",
        },
        {
            ind: 3,
            title1: "Ultimate survival game on the blockchain",
            title2: "Play-to-Earn",
            asset:
                Landing_Video,
            assetType: "video",
            desc: "Explore the post-apocalyptic metaverse,survive and earn crypto while having fun",
        },
        {
            ind: 4,
            asset:
                Event2,
            assetType: "img",
            title1: "Ultimate survival game on the blockchain",
            title2: "Play-to-Earn",
            desc: "Explore the post-apocalyptic metaverse,survive and earn crypto while having fun",
        },
        {
            ind: 1,
            asset:
                Event1,
            assetType: "img",
            title1: "Ultimate survival game on the blockchain",
            title2: "Play-to-Earn",
            desc: "Explore the post-apocalyptic metaverse,survive and earn crypto while having fun",
        },
        {
            ind: 2,
            asset:
                Event2,
            assetType: "img",
            title1: "Ultimate survival game on the blockchain",
            title2: "Play-to-Earn",
            desc: "Explore the post-apocalyptic metaverse,survive and earn crypto while having fun",
        },
        {
            ind: 3,
            title1: "Ultimate survival game on the blockchain",
            title2: "Play-to-Earn",
            asset:
                Landing_Video,
            assetType: "video",
            desc: "Explore the post-apocalyptic metaverse,survive and earn crypto while having fun",
        },
        {
            ind: 4,
            asset:
                Event2,
            assetType: "img",
            title1: "Ultimate survival game on the blockchain",
            title2: "Play-to-Earn",
            desc: "Explore the post-apocalyptic metaverse,survive and earn crypto while having fun",
        },
    ];
    const Arr3 = [
        {
            ind: 1,
            asset:
                Event1,
            assetType: "img",
            title1: "Ultimate survival game on the blockchain",
            title2: "Play-to-Earn",
            desc: "Explore the post-apocalyptic metaverse,survive and earn crypto while having fun",
        },
        {
            ind: 2,
            asset:
                Event2,
            assetType: "img",
            title1: "Ultimate survival game on the blockchain",
            title2: "Play-to-Earn",
            desc: "Explore the post-apocalyptic metaverse,survive and earn crypto while having fun",
        },
        {
            ind: 3,
            title1: "Ultimate survival game on the blockchain",
            title2: "Play-to-Earn",
            asset:
                Landing_Video,
            assetType: "video",
            desc: "Explore the post-apocalyptic metaverse,survive and earn crypto while having fun",
        },
        {
            ind: 4,
            asset:
                Event2,
            assetType: "img",
            title1: "Ultimate survival game on the blockchain",
            title2: "Play-to-Earn",
            desc: "Explore the post-apocalyptic metaverse,survive and earn crypto while having fun",
        },
        {
            ind: 1,
            asset:
                Event1,
            assetType: "img",
            title1: "Ultimate survival game on the blockchain",
            title2: "Play-to-Earn",
            desc: "Explore the post-apocalyptic metaverse,survive and earn crypto while having fun",
        },
        {
            ind: 2,
            asset:
                Event2,
            assetType: "img",
            title1: "Ultimate survival game on the blockchain",
            title2: "Play-to-Earn",
            desc: "Explore the post-apocalyptic metaverse,survive and earn crypto while having fun",
        },
        {
            ind: 3,
            title1: "Ultimate survival game on the blockchain",
            title2: "Play-to-Earn",
            asset:
                Landing_Video,
            assetType: "video",
            desc: "Explore the post-apocalyptic metaverse,survive and earn crypto while having fun",
        },
        {
            ind: 4,
            asset:
                Event2,
            assetType: "img",
            title1: "Ultimate survival game on the blockchain",
            title2: "Play-to-Earn",
            desc: "Explore the post-apocalyptic metaverse,survive and earn crypto while having fun",
        },
    ];
    const Arr4 = [
        {
            ind: 1,
            asset:
                Event1,
            assetType: "img",
            title1: "Ultimate survival game on the blockchain",
            title2: "Play-to-Earn",
            desc: "Explore the post-apocalyptic metaverse,survive and earn crypto while having fun",
        },
        {
            ind: 2,
            asset:
                Event2,
            assetType: "img",
            title1: "Ultimate survival game on the blockchain",
            title2: "Play-to-Earn",
            desc: "Explore the post-apocalyptic metaverse,survive and earn crypto while having fun",
        },
        {
            ind: 3,
            title1: "Ultimate survival game on the blockchain",
            title2: "Play-to-Earn",
            asset:
                Landing_Video,
            assetType: "video",
            desc: "Explore the post-apocalyptic metaverse,survive and earn crypto while having fun",
        },
        {
            ind: 4,
            asset:
                Event2,
            assetType: "img",
            title1: "Ultimate survival game on the blockchain",
            title2: "Play-to-Earn",
            desc: "Explore the post-apocalyptic metaverse,survive and earn crypto while having fun",
        },
        {
            ind: 1,
            asset:
                Event1,
            assetType: "img",
            title1: "Ultimate survival game on the blockchain",
            title2: "Play-to-Earn",
            desc: "Explore the post-apocalyptic metaverse,survive and earn crypto while having fun",
        },
        {
            ind: 2,
            asset:
                Event2,
            assetType: "img",
            title1: "Ultimate survival game on the blockchain",
            title2: "Play-to-Earn",
            desc: "Explore the post-apocalyptic metaverse,survive and earn crypto while having fun",
        },
        {
            ind: 3,
            title1: "Ultimate survival game on the blockchain",
            title2: "Play-to-Earn",
            asset:
                Landing_Video,
            assetType: "video",
            desc: "Explore the post-apocalyptic metaverse,survive and earn crypto while having fun",
        },
        {
            ind: 4,
            asset:
                Event2,
            assetType: "img",
            title1: "Ultimate survival game on the blockchain",
            title2: "Play-to-Earn",
            desc: "Explore the post-apocalyptic metaverse,survive and earn crypto while having fun",
        },
    ];
    return (
        <>
            <div className='flex items-center justify-center my-[2.4rem]'>
                <div className='flex flex-col md:flex-row items-center justify-between'>
                    {Tabs.map((data, index) => (
                        <h3 className={`${data.event === tab ? 'text-blue-500 dark:text-brand-400' : "dark:text-white"} mx-5 text-[1.5rem] font-semibold cursor-pointer`} key={index} onClick={() => setTab(data.event)}>
                            {data.event}
                        </h3>
                    ))}
                </div>
            </div>
            {
                tab === "All" &&
                <div className={`mb-[40px] flex items-center`}>
                    <div
                        className={`  flex items-center`}
                        style={{ overflow: "hidden" }}
                    >
                        <Slider {...settings} className="mb-[30px]" style={{ overflow: "hidden" }}>
                            {Arr1.map((data, index) => (
                                <div
                                    className={`!z-5 h-[70vh]  relative flex flex-col dark:text-white dark:shadow-none `}
                                    key={index}

                                    style={{ margin: "5px", overflowY: 'hidden' }}
                                >
                                    <div className="h-[50vh]  px-[1.2rem]">
                                        {data.assetType === 'img' ?
                                            <img src={data.asset} className="h-[45vh] rounded-xl" alt="" />
                                            :
                                            <video
                                                className='h-[45vh] bg-navy-700 rounded-xl'
                                                src={data.asset} autoPlay muted loop>
                                            </video>}
                                        <div className="w-[100%]">
                                            <h2 className="w-50 text-[25px]  dark:text-white font-bold text-navy-700 ">
                                                {data.title1}
                                            </h2>

                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>}
                {
                tab === "Featured" &&
                <div className={`mb-[40px] flex items-center`}>
                    <div
                        className={`  flex items-center`}
                        style={{ overflow: "hidden" }}
                    >
                        <Slider {...settings} className="mb-[30px]" style={{ overflow: "hidden" }}>
                            {Arr2.map((data, index) => (
                                <div
                                    className={`!z-5 h-[60vh]  relative flex flex-col dark:text-white dark:shadow-none `}
                                    key={index}

                                    style={{ margin: "5px", overflowY: 'hidden' }}
                                >
                                    <div className="h-[50vh]  px-[1.2rem]">
                                        {data.assetType === 'img' ?
                                            <img src={data.asset} className="h-[45vh] rounded-xl" alt="" />
                                            :
                                            <video
                                                className='h-[45vh] bg-navy-700 rounded-xl'
                                                src={data.asset} autoPlay muted loop>
                                            </video>}
                                        <div className="w-[100%]">
                                            <h2 className="w-50 text-[25px]  dark:text-white font-bold text-navy-700 ">
                                                {data.title1}
                                            </h2>

                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>}
                {
                tab === "Most Popular" &&
                <div className={`mb-[40px] flex items-center`}>
                    <div
                        className={`  flex items-center`}
                        style={{ overflow: "hidden" }}
                    >
                        <Slider {...settings} className="mb-[30px]" style={{ overflow: "hidden" }}>
                            {Arr3.map((data, index) => (
                                <div
                                    className={`!z-5 h-[60vh]  relative flex flex-col dark:text-white dark:shadow-none `}
                                    key={index}

                                    style={{ margin: "5px", overflowY: 'hidden' }}
                                >
                                    <div className="h-[50vh]  px-[1.2rem]">
                                        {data.assetType === 'img' ?
                                            <img src={data.asset} className="h-[45vh] rounded-xl" alt="" />
                                            :
                                            <video
                                                className='h-[45vh] bg-navy-700 rounded-xl'
                                                src={data.asset} autoPlay muted loop>
                                            </video>}
                                        <div className="w-[100%]">
                                            <h2 className="w-50 text-[25px]  dark:text-white font-bold text-navy-700 ">
                                                {data.title1}
                                            </h2>

                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>}
                {
                tab === "Recently Added" &&
                <div className={`mb-[40px] flex items-center`}>
                    <div
                        className={`  flex items-center`}
                        style={{ overflow: "hidden" }}
                    >
                        <Slider {...settings} className="mb-[30px]" style={{ overflow: "hidden" }}>
                            {Arr4.map((data, index) => (
                                <div
                                    className={`!z-5 h-[60vh]  relative flex flex-col dark:text-white dark:shadow-none `}
                                    key={index}

                                    style={{ margin: "5px", overflowY: 'hidden' }}
                                >
                                    <div className="h-[50vh]  px-[1.2rem]">
                                        {data.assetType === 'img' ?
                                            <img src={data.asset} className="h-[45vh] rounded-xl" alt="" />
                                            :
                                            <video
                                                className='h-[45vh] bg-navy-700 rounded-xl'
                                                src={data.asset} autoPlay muted loop>
                                            </video>}
                                        <div className="w-[100%]">
                                            <h2 className="w-50 text-[25px]  dark:text-white font-bold text-navy-700 ">
                                                {data.title1}
                                            </h2>

                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>}
        </>
    )
}

export default EventTabs
