import React from 'react';
import "./TicketCard.css";
const TicketCard = () => {
    return (

        <div class="cardWrap">
            <div class="card cardLeft">
                <h1 className='font-bold text-[1.72rem]'>Startup <span className='font-normal'>Cinema</span></h1>
                <div class="title">
                    <h2 className='text-navy-700 text-[1rem] font-semibold'>How I met your Mother</h2>
                    <span className='text-navy-500 text-[0.8rem] font-semibold'>movie</span>
                </div>
                <div class="name">
                    <h2 className='text-navy-700 text-[1rem] font-semibold'>Vladimir Kudinov</h2>
                    <span  className='text-navy-500 text-[0.8rem] font-semibold'>name</span>
                </div>
                <div class="seat">
                    <h2 className='text-navy-700 text-[1rem] font-semibold'>156</h2>
                    <span  className='text-navy-500 text-[0.8rem] font-semibold'>seat</span>
                </div>
                <div class="time">
                    <h2 className='text-navy-700 text-[1rem] font-semibold'>12:00</h2>
                    <span  className='text-navy-500 text-[0.8rem] font-semibold'>time</span>
                </div>

            </div>
            <div class="card cardRight">
                <div class="eye"></div>
                <div class="number">
                    <h3>156</h3>
                    <span>seat</span>
                </div>
                <div class="barcode"></div>
            </div>

        </div>
    )
}

export default TicketCard
