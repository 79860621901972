import React from 'react'
import { Event1, Event2, dance1, dance2, dance3, dance4,
    comedy1,
    comedy2,
    comedy3,
    comedy4,
    
} from "assets";

export const Allevents = () => {
    const arr = [
        {
          id: 1,
          img: comedy1,
          heading: "Comedy Show",
          Subtitle: "Comedy/Acting",
        },
        {
          id: 1,
          img: comedy2,
          heading: "Comedy Show",
          Subtitle: "Comedy/Acting",
        },
        {
          id: 1,
          img: comedy3,
          heading: "Comedy Show",
          Subtitle: "Comedy/Acting",
        },
        {
          id: 1,
          img: comedy4,
          heading: "Comedy Show",
          Subtitle: "Comedy/Acting",
        },
       
      ];
      const arr1 = [
        {
          id: 1,
          img: dance1,
          heading: "Comedy Show",
          Subtitle: "Comedy/Acting",
        },
        {
          id: 1,
          img: dance2,
          heading: "Comedy Show",
          Subtitle: "Comedy/Acting",
        },
        {
          id: 1,
          img: dance3,
          heading: "Comedy Show",
          Subtitle: "Comedy/Acting",
        },
        {
          id: 1,
          img: dance4,
          heading: "Comedy Show",
          Subtitle: "Comedy/Acting",
        },
      
      ];
  return (
    <div className="dark:text-white">
    <div>
      <p style={{ fontSize: "25px", fontWeight: 600 }}>Comedy Shows</p>
      <br />
      <div className="grid w-[78vw] grid-cols-4 my-5">
        {arr.map((data) => (
          <div className="card0123 w-[17vw]">
            <img
              style={{ objectFit: "cover" }}
              className="h-[50vh] rounded-xl"
              src={data.img}
              alt=""
            />
            <p className="my-2" style={{ fontSize: "22px", fontWeight: 500 }}>
              {data.heading}
            </p>
            <p style={{ color: "gray" }}>{data.Subtitle}</p>
          </div>
        ))}
      </div>
    </div>
    
    <br />
    <br />
    <div>
      <p style={{ fontSize: "25px", fontWeight: 600 }}>Dance Shows</p>
      <br />
      <div className="grid w-[78vw] grid-cols-4 my-5">
        {arr1.map((data) => (
          <div style={{}} className="card0123 w-[17vw]">
            <img
              style={{ objectFit: "cover" }}
              className="h-[50vh] rounded-xl"
              src={data.img}
              alt=""
            />
            <p className="my-2" style={{ fontSize: "22px", fontWeight: 500 }}>
              {data.heading}
            </p>
            <p style={{ color: "gray" }}>{data.Subtitle}</p>
          </div>
        ))}
      </div>
    </div><br /><br />
    <div style={{paddingBottom:"5%"}}>
      <p style={{ fontSize: "25px", fontWeight: 600 }}>Comedy Shows</p>
      <br />
      <div className="grid w-[78vw] grid-cols-4 my-5">
        {arr.map((data) => (
          <div className="card0123 w-[17vw]">
            <img
              style={{ objectFit: "cover" }}
              className="h-[50vh] rounded-xl"
              src={data.img}
              alt=""
            />
            <p className="my-2" style={{ fontSize: "22px", fontWeight: 500 }}>
              {data.heading}
            </p>
            <p style={{ color: "gray" }}>{data.Subtitle}</p>
          </div>
        ))}
      </div >
    </div>
  </div>
  )
}
