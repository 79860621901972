import React, { useState } from 'react';

const EventDiscussions = () => {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');

  const handleInputChange = (event) => {
    setNewComment(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (newComment.trim() === '') return;

    const comment = {
      id: comments.length + 1,
      content: newComment,
      user: 'Jane Smith', // You can replace this with the actual logged-in user's name
    };

    setComments([...comments, comment]);
    setNewComment('');
  };

  return (
    <div className="bg-white  rounded-lg shadow-md p-6 mt-5">
      <h2 className="text-xl font-semibold mb-4">Event Discussions</h2>

      <form onSubmit={handleSubmit} className="mb-4">
        <textarea
          className="w-full p-2 rounded border focus:outline-none focus:ring focus:border-blue-300"
          rows="4"
          placeholder="Start a discussion or add a comment..."
          value={newComment}
          onChange={handleInputChange}
        ></textarea>
        <button
          type="submit"
          className="mt-2 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 focus:outline-none"
        >
          Post
        </button>
      </form>

      {comments.length === 0 ? (
        <p className="text-gray-500">No discussions or comments yet.</p>
      ) : (
        <div className="mt-4">
          {comments.map((comment) => (
            <div key={comment.id} className="bg-gray-100 p-2 mb-2 rounded-lg">
              <p className="text-gray-700">{comment.content}</p>
              <p className="text-sm text-gray-500">By {comment.user}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default EventDiscussions;
