import axios from "axios";
import { API } from "servicerepo/BackedUrl";

// /* Product Updating */
export const Eventcreate = async (form) => {
    const config = {
        method: "post",
        url: `${API}/event/createEvent`,
        headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${typeof window !== "undefined" &&
            //     JSON.parse(localStorage.getItem("crm_token"))
            //     }`,
        },
        data: JSON.stringify(form),
    };
    try {
        const res = await axios(config);
        // console.log("response", res)
        if (res.status === 200) {
            return res.data;
        }
    } catch (error) {
            return error
    }
}