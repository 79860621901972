import "./Card.css";
function Card(props) {
  const { variant, extra, children, ...rest } = props;
  return (
    <div
    style={{cursor:"pointer"}}
      className={`!z-5 relative flex flex-col rounded-[20px] Card-flow-hover bg-white bg-clip-border shadow-2xl shadow-shadow-500 dark:!bg-navy-800 dark:text-white dark:shadow-none ${extra}`}
      {...rest}
    >
      {children}
    </div>
  );
}

export default Card;
