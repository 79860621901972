import React, { useState } from "react";
import Footer from "components/footer/Footer";
import routes from "Router/routes";
import LandingNavbar from "components/navbar/LandingNavbar";
import AllEventScrolle from "./components/allEventScroller";
import SignupModal from "components/modal/SignupModal";
import ContactUs from "components/contact/ContactUs";
import CategoryBar from "components/Category/CategoryBar";
import NewPage from "./components/PopuularStreaming";
import PopularEvent from "./components/PopularEvent";
import EventTabs from "./components/EventTabs";
import PopularEventVV from "./components/PopularEventVV";
import PopularShow from "./components/PopularShow";
import RegisterCompo from "./components/RegisterCompo";

const Landing = (props) => {
  const { ...rest } = props;
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);

  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].secondary;
      }
    }
    return activeNavbar;
  };

  return (  
    <>
      <div className="flex h-full w-full">
        <div className="h-full w-full bg-lightPrimary dark:!bg-navy-900">
          <main className={` h-full flex-none transition-all `}>
            <div className="h-full">
              <LandingNavbar
                open={open1}
                setOpen={setOpen1}
                secondary={getActiveNavbar(routes)}
                {...rest}
                className="xl:mx-[53px]"
              />
              <div className="mx-auto mb-auto h-full ">
                <AllEventScrolle open={open} setOpen={setOpen} />
              </div>
              <div className=" xl:mx-[5.2rem] mb-[2.5rem]">
              <CategoryBar />
              </div>
              <div className="landing_events xl:mx-[5.2rem]">
                <NewPage /><br />
                <PopularEvent />
                <EventTabs />
                <PopularEventVV />
                <PopularShow />
                <RegisterCompo />
                
              </div>
              <div className="mt-5">
                <Footer />
              </div>
            </div>
          </main>
        </div>
      </div>
      <SignupModal open={open} setOpen={setOpen} />
      <ContactUs open={open1} setOpen={setOpen1}  />
    </>
  );
};

export default Landing;
