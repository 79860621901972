import React from "react";
import Slider from "react-slick";
import "./landing.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LandingCard from "components/card/LandingCard";
import { Event1 } from "assets";
import { Event2 } from "assets";
import { Landing_Video } from "assets";
import { Link } from "react-router-dom";

const PopuularStreaming = (props) => {

    const settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 1550,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
                infinite: true,
              },
            },
            {
              breakpoint: 1214,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
              },
            },
            {
              breakpoint: 1007,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
              },
            },
            {
              breakpoint: 516,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
              },
            },
          ],
    };

    const Arr = [
        {
            ind: 1,
            asset:
                Event1,
            assetType: "img",
            title1: "Ultimate survival game on the blockchain",
            title2: "Play-to-Earn",
            desc: "Explore the post-apocalyptic metaverse,survive and earn crypto while having fun",
        },
        {
            ind: 2,
            asset:
                Event2,
            assetType: "img",
            title1: "Ultimate survival game on the blockchain",
            title2: "Play-to-Earn",
            desc: "Explore the post-apocalyptic metaverse,survive and earn crypto while having fun",
        },
        {
            ind: 3,
            title1: "Ultimate survival game on the blockchain",
            title2: "Play-to-Earn",
            asset:
                Landing_Video,
            assetType: "video",
            desc: "Explore the post-apocalyptic metaverse,survive and earn crypto while having fun",
        },
        {
            ind: 1,
            asset:
                Event1,
            assetType: "img",
            title1: "Ultimate survival game on the blockchain",
            title2: "Play-to-Earn",
            desc: "Explore the post-apocalyptic metaverse,survive and earn crypto while having fun",
        },
        {
            ind: 2,
            asset:
                Event2,
            assetType: "img",
            title1: "Ultimate survival game on the blockchain",
            title2: "Play-to-Earn",
            desc: "Explore the post-apocalyptic metaverse,survive and earn crypto while having fun",
        },
        {
            ind: 3,
            title1: "Ultimate survival game on the blockchain",
            title2: "Play-to-Earn",
            asset:
                Landing_Video,
            assetType: "video",
            desc: "Explore the post-apocalyptic metaverse,survive and earn crypto while having fun",
        },
        {
            ind: 1,
            asset:
                Event1,
            assetType: "img",
            title1: "Ultimate survival game on the blockchain",
            title2: "Play-to-Earn",
            desc: "Explore the post-apocalyptic metaverse,survive and earn crypto while having fun",
        },
        {
            ind: 2,
            asset:
                Event2,
            assetType: "img",
            title1: "Ultimate survival game on the blockchain",
            title2: "Play-to-Earn",
            desc: "Explore the post-apocalyptic metaverse,survive and earn crypto while having fun",
        },
        {
            ind: 3,
            title1: "Ultimate survival game on the blockchain",
            title2: "Play-to-Earn",
            asset:
                Landing_Video,
            assetType: "video",
            desc: "Explore the post-apocalyptic metaverse,survive and earn crypto while having fun",
        },
        {
            ind: 1,
            asset:
                Event1,
            assetType: "img",
            title1: "Ultimate survival game on the blockchain",
            title2: "Play-to-Earn",
            desc: "Explore the post-apocalyptic metaverse,survive and earn crypto while having fun",
        },
        {
            ind: 2,
            asset:
                Event2,
            assetType: "img",
            title1: "Ultimate survival game on the blockchain",
            title2: "Play-to-Earn",
            desc: "Explore the post-apocalyptic metaverse,survive and earn crypto while having fun",
        },
        {
            ind: 3,
            title1: "Ultimate survival game on the blockchain",
            title2: "Play-to-Earn",
            asset:
                Landing_Video,
            assetType: "video",
            desc: "Explore the post-apocalyptic metaverse,survive and earn crypto while having fun",
        },
        {
            ind: 1,
            asset:
                Event1,
            assetType: "img",
            title1: "Ultimate survival game on the blockchain",
            title2: "Play-to-Earn",
            desc: "Explore the post-apocalyptic metaverse,survive and earn crypto while having fun",
        },
        {
            ind: 2,
            asset:
                Event2,
            assetType: "img",
            title1: "Ultimate survival game on the blockchain",
            title2: "Play-to-Earn",
            desc: "Explore the post-apocalyptic metaverse,survive and earn crypto while having fun",
        },
        {
            ind: 3,
            title1: "Ultimate survival game on the blockchain",
            title2: "Play-to-Earn",
            asset:
                Landing_Video,
            assetType: "video",
            desc: "Explore the post-apocalyptic metaverse,survive and earn crypto while having fun",
        },

    ];

    return (
        <div>
            <div className="my-[3rem] text-[2.5rem] text-center dark:text-white font-bold">
            Popular streaming movies & series
            </div>
            <div className={`mb-[40px] flex items-center`}>
                <div
                    className={`  flex items-center`}
                    style={{ overflow: "hidden" }}
                >
                    <Slider {...settings} className="mb-[30px]" style={{ overflow: "hidden" }}>
                        {Arr.map((data, index) => (
                <Link to='/singleEvent/0'>

                            <div
                                className={`!z-5 h-[80vh]  relative flex flex-col dark:text-white dark:shadow-none `}
                                key={index}

                                style={{ margin: "5px", overflowY: 'hidden' }}
                            >
                                <div className="h-[50vh]  px-[0.5rem]">
                                    {data.assetType === 'img' ?
                                        <img style={{objectFit:"cover"}} src={data.asset} className="h-[60vh] rounded-xl" alt="" />
                                        :
                                        <video
                                            className='h-[60vh] bg-navy-700 rounded-xl'
                                            src={data.asset} autoPlay muted loop>
                                        </video>}
                                    <div className="w-[100%]">
                                    <h2 className="w-50 text-[20px]  dark:text-white font-bold text-navy-700 ">
                                            {data.title1}
                                        </h2>
                                        
                                    </div>
                                </div>
                            </div>
                        </Link>
                        ))}
                    </Slider>

                </div>
            </div>
        </div>
    );
};

export default PopuularStreaming;
