import React from "react";
import Slider from "react-slick";
import "./landing.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LandingCard from "components/card/LandingCard";
import { Event1 } from "assets";
import { Event2 } from "assets";
import { Landing_Video } from "assets";
import Card from "components/card";

const AllEventScroller = (props) => {
  const { open, setOpen } = props;

  const settings = {
    dots: true,
    // infinite: true,
    // autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const Arr = [
    {
      ind: 1,
      asset:
        Event1,
      assetType: "img",
      title1: "Ultimate survival game on the blockchain",
      title2: "Play-to-Earn",
      desc: "Explore the post-apocalyptic metaverse,survive and earn crypto while having fun",
    },
    {
      ind: 2,
      asset:
        Event2,
      assetType: "img",
      title1: "Ultimate survival game on the blockchain",
      title2: "Play-to-Earn",
      desc: "Explore the post-apocalyptic metaverse,survive and earn crypto while having fun",
    },
    {
      ind: 3,
      title1: "Ultimate survival game on the blockchain",
      title2: "Play-to-Earn",
      asset:
        Landing_Video,
      assetType: "video",
      desc: "Explore the post-apocalyptic metaverse,survive and earn crypto while having fun",
    },
  ];

  return (
    <div>
      <div className={`mb-[40px] flex items-center`}>
        <div
          style={{ overflow: "hidden" }}
        >
          <Slider {...settings} className="mb-[30px]">
            {Arr.map((data, index) => (
              <LandingCard
                key={index} 
                extra={"h-[80vh] w-full"}
                style={{ padding: "5px" }}
              >
                <div className="slider-bg-relative">
                  {data.assetType === 'img' ?
                    <img src={data.asset} className="" alt="" />
                    :
                    <video
                      className=''
                      src={data.asset} autoPlay muted loop>
                    </video>}
                </div>
                <div className="slider-bg-absolute   mt-[4rem]">
                  <div>

                  <div className="w-[100%] lg:w-[75%]">
                    <h3 className=" w-[100%] lg:w-[75%] text-[25px] md:text-[50px] font-bold text-white" >
                      {data.title1}
                    </h3>
                    <h3 className="w-50   text-[25px] md:text-[50px] font-bold text-white ">
                      {data.title2}
                    </h3>
                  </div>
                    <div  className="w-[100%] lg:[50%]">
                    <p className="w-50  h-25  text-[15px] md:text-[25px] my-[2rem] font-bold text-white ">
                      {data.desc}
                    </p>
                    <div className="align-center my-[10px] flex">
                    <div>
                        <button
                          // href=""
                          onClick={() => setOpen(!open)}
                          className="linear rounded-[30px] text-[20px] bg-brand-900 px-4 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90"
                        >
                          Known More
                        </button>
                        <button
                          // href=""
                          onClick={() => setOpen(!open)}
                          className="linear mx-4 rounded-[30px] text-[20px] bg-brand-900 px-4 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90"
                        >
                          Buy Ticket
                        </button>
                     
                      </div>
                    </div>
                    </div>
                  </div>
                  {/* <div className="item-end flex justify-end"> */}
                  {/* <div className="absolute top-[10%] right-[3%]  flex items-center justify-between" style={{flexDirection:"column"}}>
                    <div className="social-icons mt-[10%]">
                      <span>k</span>
                    </div>
                    <div className="social-icons mt-[10%]">
                      <span>k</span>
                    </div><div className="social-icons mt-[10%]">
                      <span>k</span>
                    </div>
                  </div> */}
                </div>
              </LandingCard>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default AllEventScroller;
