import { avatar1 } from 'assets'
import Card from 'components/card'
import React from 'react'

const EventRightSideBar = () => {

    const SupportArr = [
        { title: 'Make a pledge without a reward', desc: "New York Times bestselling author, mom to an amazing boy, horse girl, animal lover, runner and general idea creator (Occasional pain in the ass, but we don't talk about that)" },
        { title: 'Make a pledge without a reward', desc: "New York Times bestselling author, mom to an amazing boy, horse girl, animal lover, runner and general idea creator (Occasional pain in the ass, but we don't talk about that)" },
        { title: 'Make a pledge without a reward', desc: "New York Times bestselling author, mom to an amazing boy, horse girl, animal lover, runner and general idea creator (Occasional pain in the ass, but we don't talk about that)" },
        { title: 'Make a pledge without a reward', desc: "New York Times bestselling author, mom to an amazing boy, horse girl, animal lover, runner and general idea creator (Occasional pain in the ass, but we don't talk about that)" },
        { title: 'Make a pledge without a reward', desc: "New York Times bestselling author, mom to an amazing boy, horse girl, animal lover, runner and general idea creator (Occasional pain in the ass, but we don't talk about that)" },

    ]
    return (
        <div className="flex flex-col" >
            <div>
                <button
                    className="linear float-right my-[2.5rem] rounded-[10px] bg-brand-900 px-2 py-2 text-[20px] text-base font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90"
                >
                    Book Now
                </button>
            </div>
            <div className="ps-3" style={{overflowY:'scroll', height:'150vh'}}>
                <Card>
                    <div className='p-3'>
                        <div className="flex items-center ">
                            <img src={avatar1} className="w-[4rem] h-[4rem]  rounded-full" alt="" />
                            <div className="flex flex-col ms-5">
                                <h5 className="text-[1.2rem] dark:text-white">
                                    Shannon Mayer
                                </h5>
                                <div>
                                    <h6 className=' dark:text-white'>
                                        2 Created . 15 Backed
                                    </h6>
                                </div>
                            </div>

                        </div>
                        <div className="py-3">
                            <p className=' dark:text-white'>
                                New York Times bestselling author, mom to an amazing boy, horse girl, animal lover, runner and general idea creator (Occasional pain in the ass, but we don't talk about that)
                            </p>
                        </div>
                    </div>

                </Card>
                <div className='my-3'>
                    <div>
                        <h4 className='dark:text-white ms-3 my-3 text-[2rem]'>
                            Support
                        </h4>
                    </div>
                    {SupportArr.map((data, key) => (
                        <div className='mb-5'>
                            <Card key={key}>
                                <div className='p-3'>
                                    <div className="flex items-center ">
                                        <div className="flex flex-col mx-3">
                                            <h5 className="text-[1.2rem] dark:text-white">
                                                Make a pledge without a reward
                                            </h5>
                                        </div>

                                    </div>
                                    <div className="py-3">
                                        <p className=' dark:text-white'>
                                            New York Times bestselling author, mom to an amazing boy, horse girl, animal lover, runner and general idea creator (Occasional pain in the ass, but we don't talk about that)
                                        </p>
                                    </div>
                                    <div>
                                        <button
                                            className="linear w-[100%] float-right my-[0.5rem] rounded-[10px] bg-brand-900 px-2 py-2 text-[20px] text-base font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90"
                                        >
                                            Continue
                                        </button>
                                    </div>
                                </div>

                            </Card>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default EventRightSideBar
