import { UserInfo } from 'Hooks/UserHook';
import Card from 'components/card';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const UserDetails = () => {

  const [orgData, setOrgData] = useState({});
  const { id } = useParams();
  console.log("uid", id);

  const OrganiserDetail = () => {
    UserInfo(id).then((res) => {
      console.log(res);
      // setOrgData()
    })
  }

  useEffect(() => {
    OrganiserDetail();
  }, [])

  return (
    <div>
      <div className="mt-5 grid h-full">
        <Card extra={"w-full h-full p-4"}>
          <div className="relative flex items-center justify-between">
            <div className="text-xl font-bold text-navy-700 dark:text-white">
              User Details
            </div>
          </div>

          <div className="flex flex-col items-center	 ">
            <div className="mb-3 lg:w-[60%] flex items-center">
              <label
                htmlFor="name"
                className={`"ml-1.5 font-medium" : 
 "ml-3 font-bold" text-sm text-navy-700 dark:text-white
`}
              >
                First Name :
              </label>
              <div className='ms-3'>
                <h2>
                  {orgData.fname}
                </h2>
              </div>
            </div>

            <div className="mb-3 lg:w-[60%] flex items-center">
              <label
                htmlFor="lname"
                className={`"ml-1.5 font-medium" : 
 "ml-3 font-bold" text-sm text-navy-700 dark:text-white
`}
              >
                Last Name  :
              </label>
              <div className='ms-3'>
                <h2>
                  {orgData.lname}
                </h2>
              </div>
            </div>
            <div className="mb-3 lg:w-[60%] flex items-center">
              <label
                htmlFor="email"
                className={`"ml-1.5 font-medium" : 
 "ml-3 font-bold" text-sm text-navy-700 dark:text-white
`}
              >
                Email :
              </label>
              <div className='ms-3'>
                <h2>
                  {orgData.email}
                </h2>
              </div>
            </div>
            <div className="mb-3 lg:w-[60%] flex items-center">
              <label
                htmlFor="email"
                className={`"ml-1.5 font-medium" : 
 "ml-3 font-bold" text-sm text-navy-700 dark:text-white
`}
              >
                Phone :
              </label>
              <div className='ms-3'>
                <h2>
                  {orgData.phone}
                </h2>
              </div>
            </div>

          </div>
        </Card>
      </div>
    </div>
  )
}

export default UserDetails
