import React from 'react'

export const General = () => {
  return (
    <div>
             <div class="mt-10 divide-y divide-gray-200">
                  <div class="space-y-1">
                    <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-white ">Profile</h3>
                    <p class="max-w-2xl text-sm text-gray-700">This information will be displayed publicly so be careful what you share.</p>
                  </div>
                  <div class="mt-6">
                    <dl class="divide-y divide-gray-200">
                      <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt class="text-sm font-medium text-gray-700">Name</dt>
                        <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          <span class="flex-grow dark:text-blue-600">Chelsea Hagon</span>
                          <span class="ml-4 flex-shrink-0">
                            <button type="button" class="p-1 bg-white rounded-md font-medium text-blue-600 hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">Update</button>
                          </span>
                        </dd>
                      </div>
                     
                      <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5">
                        <dt class="text-sm font-medium text-gray-700">Email</dt>
                        <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          <span class="flex-grow dark:text-blue-600">chelsea.hagon@example.com</span>
                          <span class="ml-4 flex-shrink-0">
                            <button type="button" class="p-1 bg-white rounded-md font-medium text-blue-600 hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">Update</button>
                          </span>
                        </dd>
                      </div>
                      <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-b sm:border-gray-200">
                        <dt class="text-sm font-medium text-gray-700">Job title</dt>
                        <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          <span class="flex-grow dark:text-blue-600">Human Resources Manager</span>
                          <span class="ml-4 flex-shrink-0">
                            <button type="button" class="p-1 bg-white rounded-md font-medium text-blue-600 hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">Update</button>
                          </span>
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div>

                <div class="mt-10 divide-y divide-gray-200">
                  <div class="space-y-1">
                    <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-white">Account</h3>
                    <p class="max-w-2xl text-sm text-gray-700">Manage how information is displayed on your account.</p>
                  </div>
                  <div class="mt-6">
                    <dl class="divide-y divide-gray-200">
                      <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt class="text-sm font-medium text-gray-700">Language</dt>
                        <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          <span class="flex-grow dark:text-blue-600">English</span>
                          <span class="ml-4 flex-shrink-0">
                            <button type="button" class="p-1 bg-white rounded-md font-medium text-blue-600 hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">Update</button>
                          </span>
                        </dd>
                      </div>
                      <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5">
                        <dt class="text-sm font-medium text-gray-700">Date format</dt>
                        <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          <span class="flex-grow dark:text-blue-600">DD-MM-YYYY</span>
                          <span class="ml-4 flex-shrink-0 flex items-start space-x-4">
                            <button type="button" class="p-1 bg-white rounded-md font-medium text-blue-600 hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">Update</button>
                            <span class="text-gray-300" aria-hidden="true">|</span>
                            <button type="button" class="p-1 bg-white rounded-md font-medium text-blue-600 hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">Remove</button>
                          </span>
                        </dd>
                      </div>
                      <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5">
                        <dt class="text-sm font-medium text-gray-700" id="timezone-option-label">Automatic timezone</dt>
                        <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          <button type="button" class="bg-gray-200 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:ml-auto" role="switch" aria-checked="true" aria-labelledby="timezone-option-label">
                            <span aria-hidden="true" class="translate-x-0 inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"></span>
                          </button>
                        </dd>
                      </div>
                      <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-b sm:border-gray-200">
                        <dt class="text-sm font-medium text-gray-700" id="auto-update-option-label">Auto-update applicant data</dt>
                        <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          <button type="button" class="bg-gray-200 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:ml-auto" role="switch" aria-checked="false" aria-labelledby="auto-update-option-label">
                            <span aria-hidden="true" class="translate-x-0 inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"></span>
                          </button>
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div> 
    </div>
  )
}
