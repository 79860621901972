import React from "react";

// Admin Imports
import MainDashboard from "views/admin/default";
import NFTMarketplace from "views/admin/marketplace";
import Profile from "views/admin/profile";
import DataTables from "views/admin/tables";
import RTLDefault from "views/rtl/default";

// Auth Imports
import SignIn from "views/auth/SignIn";

// Icon Imports
import {
  MdHome,
  MdOutlineShoppingCart,
  MdBarChart,
  MdPerson,
  MdLock,
} from "react-icons/md";
import MyEvent from "views/admin/My-Event";
import MyTicket from "views/admin/My-Ticket";
import SingleEvent from "views/admin/My-Event/SingleEvent/singleEvent";

const routes = [
  {
    name: "Main Dashboard",
    layout: "/admin",
    path: "dashboard",
    icon: <MdHome className="h-6 w-6" />,
    component: <MainDashboard />,
  },
  {
    name: "NFT Marketplace",
    layout: "/admin",
    path: "nft-marketplace",
    icon: <MdOutlineShoppingCart className="h-6 w-6" />,
    component: <NFTMarketplace />,
    secondary: true,
  },
  {
    name: "My Events",
    layout: "/admin",
    icon: <MdBarChart className="h-6 w-6" />,
    path: "event",
    component: <MyEvent />,
  },
  {
    name: "My Tickets",
    layout: "/admin",
    path: "ticket",
    icon: <MdPerson className="h-6 w-6" />,
    component: <MyTicket />,
  },
  {
    name: "Sign In /  Sign Out",
    layout: "/auth",
    path: "sign-in",
    icon: <MdLock className="h-6 w-6" />,
    component: <SignIn />,
  },
  {
    name: "event",
    layout: "/admin",
    path: "event/event/:id",
    icon: <MdHome className="h-6 w-6" />,
    component: <SingleEvent />,
  },
];
export default routes;
