import { avatar1 } from "assets";
import LandingNavbar from "components/navbar/LandingNavbar";
import React, { useEffect, useState } from "react";
import { FaFacebook, FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa";
import { Tilt } from "react-tilt";
import AOS from "aos";
import "aos/dist/aos.css";
import Landingcard1 from "./landigcard1";
import { border } from "@chakra-ui/system";

export const Organizer = () => {
  const [scrollTop, setScrollTop] = useState(0);

  console.log(window.scrollY);

  useEffect(() => {
    function handleScroll() {
      setScrollTop(window.scrollY);
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    AOS.init({});
  }, []);
  const defaultOptions = {
    reverse: true, // reverse the tilt direction
    max: 15, // max tilt rotation (degrees)
    perspective: 1000, // Transform perspective, the lower the more extreme the tilt gets.
    scale: 1.01, // 2 = 200%, 1.5 = 150%, etc..
    speed: 1000, // Speed of the enter/exit transition
    transition: false, // Set a transition on enter/exit.
    axis: null, // What axis should be disabled. Can be X or Y.
    reset: true, // If the tilt effect has to be reset on exit.
    easing: "cubic-bezier(.03,.98,.52,.99)", // Easing on enter/exit.
  };
  const styles = {
    top: {
      backgroundColor: scrollTop >= 750 ? "rgb(0,0,0,0.7)" : "",
      position: scrollTop >= 750 ? "fixed" : "",
      top: scrollTop >= 750 ? -70 : "",
      zIndex: 999,
      color: scrollTop >= 750 ? "white" : "",
      backdropFilter: scrollTop >= 750 ? "blur(20px)" : "",
      padding: scrollTop >= 750 ? "1%" : "",
      borderRadius: scrollTop >= 750 ? "17px" : "",
    },

    top2: {
      // backgroundColor: scrollTop >= 890  ? "red": "blue",
      display: scrollTop >= 750 ? "none" : "",
    },
    top3: {
      borderBottom: scrollTop >= 750 ? "" : "3px solid rgb(117 81 255 )",
      paddingBottom: scrollTop >= 750 ? "" : "2%",
      marginLeft: scrollTop >= 750 ? "2.5%" :""
    },
    top4: {
      borderTop:scrollTop >= 750 ? "": "3px solid rgb(117 81 255 )",
      width: scrollTop >= 750 ? "":"100%",
      height:scrollTop >= 750 ? "6vh":"8.7vh",
      borderLeft:scrollTop >= 750 ? "": "4px solid rgb(117 81 255 )",
      transform:scrollTop >= 750 ? "": "skew(140deg)",
    },
  };

  return (
    <div className="dark:bg-navy-700">
      <LandingNavbar top2={styles.top2} />
      <div className="background text-white ">
        <div>
          <Tilt options={defaultOptions}>
            <div className="transbox">
              <div>
                <img className=" w-[90%] rounded-full" src={avatar1} alt="" />
              </div>
              <div className="w-[30vw]">
                <p className="text-[50px]">Organizer :</p>
                <p className="text-[30px]">
                  <i>Rosado Club</i>
                </p>{" "}
                <br />
                <p className={` text-[20px]`}>
                  <p>
                    <p>
                      Location : <i>Jaipur</i>
                    </p>
                  </p>
                  Open : 09-11 pm
                </p>
                <br />
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Cumque ea aliquid quos recusandae mollitia necessitatibus
                  architecto hic eos. Sit, voluptatibus?
                </p>
                <button
                  style={{ border: "1px solid white" }}
                  className="text-dark dark:hover:bg-blue my-7 rounded-[30px] px-4 py-3 text-[1.2rem] text-base font-medium transition duration-200 hover:bg-brand-400 active:bg-brand-100 dark:bg-brand-400 dark:text-white"
                >
                  Follow
                </button>
              </div>
              <div>
                <div className="aligns-center mr-[5rem] flex cursor-pointer flex-col justify-center gap-6">
                  <div>
                    <FaInstagram
                      data-aos="fade-right"
                      data-aos-duration="1900"
                      className="text-[3vw] text-red-300 hover:text-[3.1vw]"
                    />
                  </div>
                  <div>
                    <FaFacebook
                      data-aos="fade-right"
                      data-aos-duration="2100"
                      className=" text-[3vw] text-blue-600 hover:text-[3.1vw]"
                    />
                  </div>

                  <div>
                    <FaYoutube
                      data-aos="fade-right"
                      data-aos-duration="2300"
                      className=" text-[3vw] text-red-500 hover:text-[3.1vw]"
                    />
                  </div>

                  <div>
                    <FaTwitter
                      data-aos="fade-right"
                      data-aos-duration="2500"
                      className="text-[3vw] text-blue-400 hover:text-[3.1vw]"
                    />
                  </div>
                </div>
              </div>
            </div>
                     
          </Tilt>
        </div>
      </div>

      <Landingcard1 styles={styles} />
    </div>
  );
};
