import Card from 'components/card'
import Widget from 'components/widget/Widget'
import React from 'react'
import { MdBarChart } from 'react-icons/md'

const CategoryBar = () => {
    const Cat = [
        {cat:"Games & Sports Events", id:"1"},
        {cat:" Music", id:"2"},
        {cat:" Networking events", id:"3"},
        {cat:"Workshops", id:"4"},
        {cat:"Charity Event", id:"5"},
        {cat:" Exhibitions", id:"6"},
        {cat:" Virtual Event", id:"7"},
      

    ]
    return (
        <>
            <div>
                <h4 className="text-3xl mb-3 mx-5 font-bold capitalize text-navy-700 dark:text-white">
                    Category
                </h4>
            </div>
            <div className='flex algins-center justify-center gap-3 ' style={{overflowX:'scroll', overflowY:'hidden'}}>
                {Cat.map((data, index)=>(
                <Card extra="!flex-row flex-grow hover:bg-white-900 items-center rounded-[0px] popup-onhover" key={index}>
                    <div className="ml-4 flex-row flex-grow  w-auto flex-col justify-center">
                        <h4 className="text-xl p-3 minWidth-[900px] text-center font-bold text-navy-700 dark:text-white">
                            {data.cat}
                        </h4>
                    </div>
                </Card>
                  
                ))}
            </div>
        </>

    )
}

export default CategoryBar