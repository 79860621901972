import { RegisterBg } from 'assets'
import { Event1 } from 'assets'
import React from 'react'

const RegisterCompo = () => {
    return (
        <div className='my-[5rem] flex items-center justify-center'>
            <div className='w-[80%] lg:w-[60%] h-[40vh] flex items-center justify-center z-100 text-red 
            
            rounded-xl'
                style={{ backgroundImage:`url(${RegisterBg})`, backgroundSize: 'cover' }}>
                <div className='mx-[2rem] md:mx-[4rem]'>
                    <h3 className='text-[2rem] md:text-[3rem] lg:[4rem] lg font-bold text-white text-center'>
                        No hidden fees, equipment rentals or installation appointments.
                    </h3>
                    <div className='flex flex-col justify-center md:flex-row my-[2.5rem]'>
                        <div id='SearchBoox' className='bg-white rounded-full  w-[100%] lg:w-[60%] dark:bg-navy-500 h-[3.5rem]'>
                            <input type="text" placeholder='Enter Your Email' className='rounded-full w-[100%] ' style={{ backgroundColor: 'inherit' }} />
                        </div>
                        <button className='rounded-full w-[100%] md:w-[40%] lg:w-[23.67%] lg:[mt-0] bg-navy-400 font-semibold  text-white hover:bg-brand-400 mx-0 my-4 md:mx-4 md:my-0 h-[3.5rem]' style={{ whiteSpace: 'nowrap' }}>
                            Register Now
                        </button>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default RegisterCompo
