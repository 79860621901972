import React from "react";

// Admin Imports
import MainDashboard from "views/User/default";
import NFTMarketplace from "views/User/marketplace";

// Icon Imports
import {
  MdHome,
  MdOutlineShoppingCart,
  MdPerson,
} from "react-icons/md";
import MyTicket from "views/organiser/My-Ticket";
import { Settings } from "views/admin/Setting/settings";
import { FiSettings } from "react-icons/fi";
import { Wishlist } from "components/Wishlist/wishlist";
import { AiOutlineEye } from "react-icons/ai";

const UserRoutes = [
  {
    name: "User Dashboard",
    layout: "/user",
    path: "dashboard",
    icon: <MdHome className="h-6 w-6" />,
    component: <MainDashboard />,
  },
  {
    name: "Marketplace",
    layout: "/user",
    path: "marketplace",
    icon: <MdOutlineShoppingCart className="h-6 w-6" />,
    component: <NFTMarketplace />,
    secondary: true,
  },

  {
    name: "My Tickets",
    layout: "/user",
    path: "ticket",
    icon: <MdPerson className="h-6 w-6" />,
    component: <MyTicket />,
  },
  {
    name: "Watch List",
    layout: "/user",
    path: "wishlist",
    icon: <AiOutlineEye  className="h-6 w-6" />,
    component: <Wishlist />,
  },
  {
    name: "Setting",
    layout: "/user",
    path: "settings",
    icon: <FiSettings  className="h-6 w-6" />,
    component: <Settings/>,
  },
 
//   {
//     name: "event",
//     layout: "/user",
//     path: "event/event/:id",
//     icon: <MdHome className="h-6 w-6" />,
//     component: <SingleEvent />,
//   },
];
export default UserRoutes;
