import React, { useState } from 'react';

const NewsletterSignup = () => {
  const [email, setEmail] = useState('');

  const handleInputChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Implement logic here to send the email to your newsletter subscription service
    console.log('Subscribed with email:', email);
    setEmail('');
  };

  return (
    <div className="bg-white rounded-lg shadow-md p-6 mt-5">
      <h2 className="text-xl font-semibold mb-4">Subscribe to Our Newsletter</h2>
      <p className="text-gray-600 mb-4">
        Stay updated with the latest events, news, and special offers!
      </p>

      <form onSubmit={handleSubmit}>
        <div className="flex">
          <input
            type="email"
            className="w-full p-2 rounded-l border focus:outline-none focus:ring focus:border-blue-300"
            placeholder="Enter your email address"
            value={email}
            onChange={handleInputChange}
            required
          />
          <button
            type="submit"
            className="bg-blue-500 text-white py-2 px-4 rounded-r hover:bg-blue-600 focus:outline-none"
          >
            Subscribe
          </button>
        </div>
      </form>
    </div>
  );
};

export default NewsletterSignup;
