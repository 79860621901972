import { Event2 } from 'assets';
import { Landing_Video } from 'assets';
import { Event1 } from 'assets';
import React from 'react'
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LandingCard from "components/card/LandingCard";

const PopularEventVV = () => {

    const settings = {
        dots: true,
        // infinite: true,
        // autoplay: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    const Arr = [
        {
            ind: 1,
            asset:
                Event1,
            assetType: "img",
            title1: "Ted Lasso",
            title2: "Play-to-Earn",
            desc: "Explore the post-apocalyptic metaverse,survive and earn crypto while having fun",
        },
        {
            ind: 2,
            asset:
                Event2,
            assetType: "img",
            title1: "Ted Lasso",
            title2: "Play-to-Earn",
            desc: "Explore the post-apocalyptic metaverse,survive and earn crypto while having fun",
        },
        {
            ind: 3,
            title1: "Ted Lasso",
            title2: "Play-to-Earn",
            asset:
                Landing_Video,
            assetType: "video",
            desc: "Explore the post-apocalyptic metaverse,survive and earn crypto while having fun",
        },
        {
            ind: 1,
            asset:
                Event1,
            assetType: "img",
            title1: "Ted Lasso",
            title2: "Play-to-Earn",
            desc: "Explore the post-apocalyptic metaverse,survive and earn crypto while having fun",
        },
        {
            ind: 2,
            asset:
                Event2,
            assetType: "img",
            title1: "Ted Lasso",
            title2: "Play-to-Earn",
            desc: "Explore the post-apocalyptic metaverse,survive and earn crypto while having fun",
        },
        {
            ind: 3,
            title1: "Ted Lasso",
            title2: "Play-to-Earn",
            asset:
                Landing_Video,
            assetType: "video",
            desc: "Explore the post-apocalyptic metaverse,survive and earn crypto while having fun",
        },
    ];

    return (
        <div className='my-[4rem] grid grid-cols-4 gap-5  mx-5 lg:mx-0 lg:grid-cols-4 2xl:grid-cols-4 3xl:grid-cols-4'>

            <div className='col-span-4 lg:col-span-1 border rounded-xl p-5'>
                <div >
                    <h5 className='font-semibold text-[1.2rem] dark:text-white my-4'>
                        Popular movies right now
                    </h5>
                    <hr />
                </div>
                <div  className='flex flex-col items-center justify-start my-4'>
                    {Arr.slice(0, 5).map((data, index) => (
                        <>
                            <div
                                className={`!z-5  relative flex justify-start dark:text-white dark:shadow-none `}
                                key={index}

                                style={{ margin: "5px", overflowY: 'hidden' }}
                            >
                                <div className="">
                                    {data.assetType === 'img' ?
                                        <img src={data.asset} className="h-[110px] w-[150px] rounded-xl" alt="" />
                                        :
                                        <video
                                            className='h-[110px] w-[150px] bg-navy-700 rounded-xl'
                                            src={data.asset} autoPlay muted loop>
                                        </video>}
                                    <div className="w-[100%]">
                                        {/* <h2 className="w-50 text-[25px]  dark:text-white font-bold text-navy-700 ">
                                            {data.title1}
                                        </h2> */}

                                    </div>
                                </div>
                                <div className='px-[1.2rem]'>
                                    <h5 className='font-semibold capitalize text-[1.3rem] my-2'>
                                        {data.title1}
                                    </h5>
                                </div>
                            </div>
                        </>
                    ))}

                </div>
                <div className='flex flex-nowrap items-center justify-end'>
                    <button
                        className="linear mx-4 rounded-[30px] dark:text-white text-[1.2rem] px-4 py-3 border text-base font-medium text-dark transition duration-200 hover:bg-brand-100 active:bg-brand-100 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90"
                    >
                        View More
                    </button>
                </div>
            </div>
            <div class="col-span-4 lg:col-span-3">
                <h3 className='dark:text-white text-[2rem] font-bold '>
                    Popular TV series right now
                </h3>
                <div  className={`mt-[40px] flex items-center`}>
                    <div
                        style={{ overflow: "hidden" }}
                    >
                        <Slider  {...settings} className="mb-[30px]">
                            {Arr.map((data, index) => (
                                <LandingCard
                                    key={index}
                                    extra={"h-[80vh] w-full rounded-xl"}
                                    style={{ padding: "5px" }}
                                >
                                    <div  className="slider-bg-relative">
                                        {data.assetType === 'img' ?
                                            <img src={data.asset} className="rounded-xl" alt="" />
                                            :
                                            <video
                                                className='rounded-xl'
                                                src={data.asset} autoPlay muted loop>
                                            </video>}
                                    </div>
                                    <div    className="slider-bg-absolute">
                                        <div >

                                            <div  className="w-[75%] lg:block hidden">
                                                <h3 className="w-[75%] text-[50px] font-bold text-white" >
                                                    {data.title1}
                                                </h3>
                                                <h3 className="w-50  text-[50px] font-bold text-white ">
                                                    {data.title2}
                                                </h3>
                                            </div>
                                            <div  className="w-[50%]">
                                                <p className="w-50  h-25 text-[25px] my-[2rem] font-bold text-white ">
                                                    {data.desc}
                                                </p>
                                                <div  className="align-center my-[10px] flex">
                                                        <button
                                                            className="linear rounded-[30px] text-[20px] bg-brand-900 px-4 my-3 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90"
                                                       style={{whiteSpace:'nowrap'}}
                                                       >
                                                            Known More
                                                        </button>
                                                        <button
                                                            className="linear mx-4 rounded-[30px] text-[20px] bg-brand-900 my-3 px-4 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90"
                                                            style={{whiteSpace:'nowrap'}}
                                                      >
                                                            Buy Ticket
                                                        </button>

                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="item-end flex justify-end"> */}
                                        {/* <div className="absolute top-[10%] right-[3%]  flex items-center justify-between" style={{flexDirection:"column"}}>
                    <div className="social-icons mt-[10%]">
                      <span>k</span>
                    </div>
                    <div className="social-icons mt-[10%]">
                      <span>k</span>
                    </div><div className="social-icons mt-[10%]">
                      <span>k</span>
                    </div>
                  </div> */}
                                    </div>
                                </LandingCard>
                            ))}
                        </Slider>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PopularEventVV
