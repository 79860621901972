import React, { useEffect, useState } from 'react'
import { AllUser } from 'Hooks/UserHook';
import Card from "components/card";
import { BsEyeFill } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import { Link } from 'react-router-dom';
import { IoAddOutline } from 'react-icons/io5';
import { deleteUser } from 'Hooks/UserHook';

const UserList = () => {
  const [userList, setUserList] = useState([]);

  useEffect(() => {
    UserLists()
  }, [])

  const UserLists = () => {
    AllUser().then((res) => {
      const Userdata = res.data.filter((data => data.role === "user"))
      setUserList(Userdata)
    });
  };

  const OrgDelete = (id) => {
    deleteUser(id).then((data) => {
      console.log(data);
    })
  }
  return (
    <div> <div className="mt-5 grid h-full">
      <Card extra={"w-full h-full p-4"}>
        <div className="relative flex items-center justify-between">
          <div className="text-xl font-bold text-navy-700 dark:text-white">
            User List
          </div>
          {/* <CardMenu />
         */}
          <Link to='/admin/user/createUser'>
            <IoAddOutline size={25} />
          </Link>
        </div>

        <div className="h-full overflow-x-scroll xl:overflow-x-hidden">
          <table
            className="mt-8 h-max w-full"
            variant="simple"
            color="gray-500"
            mb="24px"
          >
            <thead>
              <tr>
                <th>
                  S No.
                </th>
                <th>
                  Name
                </th>
                <th>
                  Email
                </th>
                <th>
                  phone
                </th>
                <th>
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {userList.map((row, index) => (
                <tr key={index} className="bg-navy border hover:bg-brand-100 cursor-pointer hover:text-navy-700">
                  <td className="text-center  p-[0.5rem]">{index + 1}</td>
                  <td className="text-center">
                    <span>
                      {row.fname ? row.fname : null}
                    </span>
                    <span className="mx-2">
                      {row.lname ? row.lname : null}
                    </span>
                  </td>
                  <td className="text-center">{row.email ? row.email : null}</td>
                  <td className="text-center">{row.phone ? row.phone : null}</td>
                  <td className="text-center flex align-center justify-center pt-3">
                    <Link to={`/admin/user/userUpdate/${row._id}`}>
                      <div className="text-orange-500 hover:text-blue-700" >
                        <FiEdit size={15} />
                      </div>
                    </Link>
                    <Link to={`/admin/user/userdetails/${row._id}`}>
                      <div className="text-blue-500 mx-2 hover:text-blue-700" >
                        <BsEyeFill size={15} />
                      </div>
                    </Link>
                    <div className="text-red-500  hover:text-blue-700" onClick={() => OrgDelete(row._id)}>
                      <MdDelete size={15} />
                    </div>
                  </td>
                </tr>

              ))}
            </tbody>
          </table>
        </div>
      </Card>
    </div>
    </div>
  )
}

export default UserList