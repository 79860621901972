import React, { useRef, useState } from "react";
import "./slider03.css";

// import required modules
import { EffectCreative } from "swiper/modules";
import { FaLongArrowAltRight } from "react-icons/fa";
import Slider01 from "./slider01";
const arr=[
  {
    "id":1
  },
  {
    "id":2
  },
  {
    "id":3
  },
  {
    "id":4
  },
 
]
export const Slider03 = () => {
  return (
    <div className="flex flex-col p-[5%] md:flex-row items-center justify-between" >
      <div className="w-[70vw] sm:w-[100vw]">
        <h1 className="h1"> <span> What We Do</span> <span> <FaLongArrowAltRight/></span> </h1>
        <p className="elementor-size-default1">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Voluptatum illo excepturi nostrum cumque culpa, perspiciatis aliquid labore.</p>
      </div>
      <div className="w-[80vw] md:w-[30vw] sm:w-[100vw]" style={{}} >
      <Slider01 />
        {/* {arr.map((data)=>(

        <div className=" card01">
          <h1 className="p-[5%] h012">Lorem ipsum dolor sit amet.</h1>
          <p className="px-[5%]">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis blanditiis repellat cum, doloribus maiores molestias! <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quisquam, autem!</p>
         <br /><br /> <button className=" elementor-button-text w-[40%]">Learn More</button>
          </p>
        </div>
        ))} */}
      </div>
    </div>
  );
};
