import React from "react";
import avatar from "assets/img/avatars/avatar11.png";
import banner from "assets/img/profile/banner.png";
import Card from "components/card";
import  "./banner.css" 
const ProfileBanner = () => {
  return (
    <Card extra={"items-center w-full h-full p-[16px] bg-cover"}>
      {/* Background and profile */}
      <div
        className="relatve mt-1 flex  h-[432px] w-full justify-center rounded-xl bg-cover"
        style={{ backgroundImage: `url(${banner})` }}
      >
        <div className="flex flex-col items-center m-[52px] px-[200px] justify-center glassEffect">
          <div className="absolue -bottom-12 flex h-[87px] w-[87px] items-center justify-center rounded-full border-[4px] border-white bg-pink-400 dark:!border-navy-700">
            <img className="h-full w-full rounded-full" src={avatar} alt="" />
          </div>
          <div className="mt-16 flex flex-col items-center ">
            <h4 className="text-xl font-bold text-navy-700 dark:text-white">
              Adela Parkson
            </h4>
            <p className="text-base font-normal dark:text-navy-600 text-white">
              Product Manager
            </p>
          </div>
          <div className="mt-6 mb-3 flex gap-4 md:!gap-14">
            <div className="flex flex-col items-center justify-center">
              <p className="text-2xl font-bold text-navy-700 dark:text-white">
                17
              </p>
              <p className="text-sm font-normal dark:text-navy-600 text-white">Posts</p>
            </div>
            <div className="flex flex-col items-center justify-center">
              <p className="text-2xl font-bold text-navy-700 dark:text-white">
                9.7K
              </p>
              <p className="text-sm font-normal dark:text-navy-600 text-white">Followers</p>
            </div>
            <div className="flex flex-col items-center justify-center">
              <p className="text-2xl font-bold text-navy-700 dark:text-white">
                434
              </p>
              <p className="text-sm font-normal dark:text-navy-600 text-white">Following</p>
            </div>
          </div>
        </div>
      </div>

      {/* Name and position */}

      {/* Post followers */}
    </Card>
  );
};

export default ProfileBanner;
