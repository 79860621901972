import { Event2 } from 'assets';
import { Landing_Video } from 'assets';
import { Event1 } from 'assets';
import React from 'react'

const PopularEvent = () => {

    const Arr = [
        {
            ind: 1,
            asset:
                Event1,
            assetType: "img",
            title1: "Ted Lasso",
            title2: "Play-to-Earn",
            desc: "Explore the post-apocalyptic metaverse,survive and earn crypto while having fun",
        },
        {
            ind: 2,
            asset:
                Event2,
            assetType: "img",
            title1: "Ted Lasso",
            title2: "Play-to-Earn",
            desc: "Explore the post-apocalyptic metaverse,survive and earn crypto while having fun",
        },
        {
            ind: 3,
            title1: "Ted Lasso",
            title2: "Play-to-Earn",
            asset:
                Landing_Video,
            assetType: "video",
            desc: "Explore the post-apocalyptic metaverse,survive and earn crypto while having fun",
        },
        {
            ind: 1,
            asset:
                Event1,
            assetType: "img",
            title1: "Ted Lasso",
            title2: "Play-to-Earn",
            desc: "Explore the post-apocalyptic metaverse,survive and earn crypto while having fun",
        },
        {
            ind: 2,
            asset:
                Event2,
            assetType: "img",
            title1: "Ted Lasso",
            title2: "Play-to-Earn",
            desc: "Explore the post-apocalyptic metaverse,survive and earn crypto while having fun",
        },
        {
            ind: 3,
            title1: "Ted Lasso",
            title2: "Play-to-Earn",
            asset:
                Landing_Video,
            assetType: "video",
            desc: "Explore the post-apocalyptic metaverse,survive and earn crypto while having fun",
        },
    ];

    return (
        <div className='my-3 lg:grid xs:flex sm:flex md:flex gap-5  xs:flex-col sm:flex-col md:flex-col  mx-5 lg:mx-0 justify-between lg:grid-cols-4 2xl:grid-cols-4 3xl:grid-cols-4'>

            <div class="lg:col-span-3 ">
                <h3 className='dark:text-white text-[2rem] mx-2 font-bold'>
                    Popular TV series right now
                </h3><br />
                <div className='flex  flex-col lg:flex-row flex-nowap items-center my-4'>
                    {Arr.slice(0, 3).map((data, index) => (
                        <>
                            <div
                                className={`!z-5 w-[100vw]  md:w-[50vw] lg:w-[30vw]  relative flex flex-col dark:text-white dark:shadow-none `}
                                key={index}

                                style={{ margin: "1px", overflowY: 'hidden' }}
                            >
                                <div className="h-[30vh]  px-[0.4rem]">
                                    {data.assetType === 'img' ?
                                        <img src={data.asset} className="h-[30vh] w-[100vw] rounded-xl" alt="" />
                                        :
                                        <video
                                            className='h-[30vh] bg-navy-700 rounded-xl'
                                            src={data.asset} autoPlay muted loop>
                                        </video>}
                                </div>
                                <div className='px-[0.4rem]'>
                                    <h5 className='font-semibold capitalize text-[1.3rem] my-2'>
                                        {data.title1}
                                    </h5>
                                </div>
                            </div>
                        </>
                    ))}

                </div>
                <div className='flex flex-col lg:flex-row flex-nowap items-center my-4'>
                    {Arr.slice(3, 6).map((data, index) => (
                        <>
                            <div
                                className={`!z-5 w-[100vw] md:w-[50vw] lg:w-[30vw]  relative flex flex-col dark:text-white dark:shadow-none `}
                                key={index}

                                style={{ margin: "5px", overflowY: 'hidden' }}
                            >
                                <div className="h-[30vh]  px-[0.4rem]">
                                    {data.assetType === 'img' ?
                                        <img src={data.asset} className="h-[30vh] w-[100vw] rounded-xl" alt="" />
                                        :
                                        <video
                                            className='h-[30vh] bg-navy-700 rounded-xl'
                                            src={data.asset} autoPlay muted loop>
                                        </video>}
                                </div>
                                <div className='px-[0.4rem]'>
                                    <h5 className='font-semibold capitalize text-[1.3rem] my-2'>
                                        {data.title1}
                                    </h5>
                                </div>
                            </div>
                        </>
                    ))}

                </div>
                <div className='flex flex-nowrap items-center justify-center'>
                    <button style={{border:"1px solid black"}}
                        className="linear mx-4 rounded-[30px] dark:text-white text-[1.2rem] px-4 py-3 text-base font-medium text-dark transition duration-200 hover:bg-brand-100 active:bg-brand-100 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90"
                    >
                        View More
                    </button>
                </div>
            </div>
            <div className='border rounded-xl p-5  hidden lg:block'>
                <div className=''>
                    <h5 className='font-semibold text-[1.2rem] dark:text-white my-4'>
                        This week's top 5 Shows
                    </h5>
                    <hr />
                </div>
                <div className='flex flex-col items-center justify-start my-4'>
                    {Arr.slice(0, 5).map((data, index) => (
                        <>
                            <div
                                className={`!z-5  relative flex justify-start dark:text-white dark:shadow-none `}
                                key={index}

                                style={{ margin: "5px", overflowY: 'hidden' }}
                            >
                                <div className="">
                                    {data.assetType === 'img' ?
                                        <img src={data.asset} className="h-[110px] w-[150px] rounded-xl" alt="" />
                                        :
                                        <video
                                            className='h-[110px] w-[150px] bg-navy-700 rounded-xl'
                                            src={data.asset} autoPlay muted loop>
                                        </video>}
                                    <div className="w-[100%]">
                                        {/* <h2 className="w-50 text-[25px]  dark:text-white font-bold text-navy-700 ">
                                            {data.title1}
                                        </h2> */}

                                    </div>
                                </div>
                                <div className='px-[1.2rem]'>
                                    <h5 className='font-semibold capitalize text-[1.3rem] my-2'>
                                        {data.title1}
                                    </h5>
                                </div>
                            </div>
                        </>
                    ))}

                </div>
                <div className='flex flex-nowrap items-center justify-end'>
                    <button style={{border:"1px solid black"}}
                        className="linear mx-4 rounded-[30px] dark:text-white text-[1.2rem] px-4 py-3  text-base font-medium text-dark transition duration-200 hover:bg-brand-100 active:bg-brand-100 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90"
                    >
                        View More
                    </button>
                </div>
            </div>
        </div>
    )
}

export default PopularEvent
