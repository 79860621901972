import React from "react";

// Admin Imports
import MainDashboard from "views/admin/default";
import NFTMarketplace from "views/admin/marketplace";

// Icon Imports
import {
  MdHome,
  MdOutlineShoppingCart,
  MdBarChart,
  MdPerson,
  MdLock,
} from "react-icons/md";
import MyEvent from "views/admin/My-Event";
import MyTicket from "views/admin/My-Ticket";
import UserList from "views/admin/User/UserList";
import OrganizerList from "views/admin/Organizer/OrganizerList";
import CreateOrganiser from "views/admin/Organizer/CreateOrganiser";
import OrganiserUpdate from "views/admin/Organizer/OrganiserUpdate";
import OrganiserDetails from "views/admin/Organizer/OrganiserDetails";
import CreateUser from "views/admin/User/CreateUser";
import UserDetails from "views/admin/User/UserDetails";
import UserUpdateForm from "views/admin/User/UserUpdate";

const adminRoutes = [
  {
    name: "Main Dashboard",
    layout: "/admin",
    path: "dashboard",
    icon: <MdHome className="h-6 w-6" />,
    component: <MainDashboard />,
  },
  {
    name: "NFT Marketplace",
    layout: "/admin",
    path: "nft-marketplace",
    icon: <MdOutlineShoppingCart className="h-6 w-6" />,
    component: <NFTMarketplace />,
    secondary: true,
  },

  {
    name: "User",
    layout: "/admin",
    path: "/user/createUser",
    icon: <MdPerson className="h-6 w-6" />,
    component: <CreateUser />,
  },
  
  {
    name: "User",
    layout: "/admin",
    path: "/user/userList",
    icon: <MdPerson className="h-6 w-6" />,
    component: <UserList />,
  },
  {
    name: "User",
    layout: "/admin",
    path: "/user/userUpdate/:id",
    icon: <MdPerson className="h-6 w-6" />,
    component: <UserUpdateForm />,
  },
  {
    name: "User",
    layout: "/admin",
    path: "/user/userdetails/:id",
    icon: <MdPerson className="h-6 w-6" />,
    component: <UserDetails />,
  },
  {
    name: "Organizer",
    layout: "/admin",
    path: "/organiser/createorganizer",
    icon: <MdPerson className="h-6 w-6" />,
    component: <CreateOrganiser />,
  },

  {
    name: "Organizer",
    layout: "/admin",
    path: "/organiser/organizerUpdate/:id",
    icon: <MdPerson className="h-6 w-6" />,
    component: <OrganiserUpdate />,
  }, 
  {
    name: "Organizer",
    layout: "/admin",
    path: "/organiser/organizerlist",
    icon: <MdPerson className="h-6 w-6" sty />,
    component: <OrganizerList />,
  }, {
    name: "Organizer",
    layout: "/admin",
    path: "/organiser/organizerdetails/:id",
    icon: <MdPerson className="h-6 w-6" />,
    component: <OrganiserDetails />,
  },


  {
    name: "My Events",
    layout: "/admin",
    icon: <MdBarChart className="h-6 w-6" />,
    path: "event",
    component: <MyEvent />,
  },
  {
    name: "My Tickets",
    layout: "/admin",
    path: "ticket",
    icon: <MdPerson className="h-6 w-6" />,
    component: <MyTicket />,
  },
];
export default adminRoutes;
