import nft1 from "assets/img/nfts/NftBanner1.png";
const UserBanner = [      
            {backgroundImage: nft1, title:"Discover, collect, and sell extraordinary NFTs", description:" Enter in this creative world. Discover now the latest NFTs or start creating your own!", button1:"Discover now", button2:"Watch Video", link1Url:"", link2Url:""},
            {backgroundImage: nft1, title:"Discover, collect, and sell extraordinary NFTs", description:" Enter in this creative world. Discover now the latest NFTs or start creating your own!", button1:"Discover now", button2:"Watch Video", link1Url:"", link2Url:""},
            {backgroundImage: nft1, title:"Discover, collect, and sell extraordinary NFTs", description:" Enter in this creative world. Discover now the latest NFTs or start creating your own!", button1:"Discover now", button2:"Watch Video", link1Url:"", link2Url:""},
            {backgroundImage: nft1, title:"Discover, collect, and sell extraordinary NFTs", description:" Enter in this creative world. Discover now the latest NFTs or start creating your own!", button1:"Discover now", button2:"Watch Video", link1Url:"", link2Url:""},
            {backgroundImage: nft1, title:"Discover, collect, and sell extraordinary NFTs", description:" Enter in this creative world. Discover now the latest NFTs or start creating your own!", button1:"Discover now", button2:"Watch Video", link1Url:"", link2Url:""},


        ]
export default UserBanner;